import { addLineNumbers } from "potree/utils/string.js";
import * as Shaders from "../rendering/shaders.js";

export class SQCache {
    #shaderCache = new Map();
    #bufferCache = new Map();

    getProgram(gl, vert, frag) {
      const key = `${vert} ${frag}`;

      const existingProgram = this.#shaderCache.get(key);
      if (existingProgram) {
        return existingProgram;
      }

      const program = gl.createProgram();

      const vertexShaderString = Shaders.get(vert);
      const fragmentShaderString = Shaders.get(frag);

      const vertexShader = this.#createShader(gl, gl.VERTEX_SHADER, vertexShaderString);
      const fragmentShader = this.#createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderString);

      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);

      gl.linkProgram(program);

      this.#checkDiagnostics(gl, program, vertexShader, fragmentShader);

      // Clean up
      gl.deleteShader(vertexShader);
      gl.deleteShader(fragmentShader);

      this.#shaderCache.set(key, program);

      return program;
    }

    #createShader(gl, type, string) {
      const shader = gl.createShader(type);

      gl.shaderSource(shader, string);
      gl.compileShader(shader);

      return shader;
    }

    #checkDiagnostics(gl, program, vertexShader, fragmentShader) {
      const programLog = gl.getProgramInfoLog(program).trim();

      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        const vertexErrors = getShaderErrors(gl, vertexShader, "vertex");
        const fragmentErrors = getShaderErrors(gl, fragmentShader, "fragment");

        console.error(
          "Shader error: ",
          gl.getError(),
          "\n[35715]:\n",
          gl.getProgramParameter(program, 35715),
          "\n[Log]:\n",
          programLog,
          "\n[Vertex Errors]:\n",
          vertexErrors,
          "\n[Fragment Errors]:\n",
          fragmentErrors
        );
      } else if (programLog !== "") {
        console.warn("gl.getProgramInfoLog()", programLog);
      }
    }

    getBuffer(gl, geometry) {
      const existingBuffer = this.#bufferCache.get(geometry.uuid);

      if (existingBuffer) {
        return existingBuffer;
      }

      const buffer = geometry.createRenderBuffer(gl);
      this.#bufferCache.set(geometry.uuid, buffer);

      return buffer;
    }
}

function getShaderErrors(gl, shader, type) {
  const status = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  const log = gl.getShaderInfoLog(shader)?.trim() || "";

  if (status && !log) return "";

  // --enable-privileged-webgl-extension
  const source = gl.getShaderSource(shader);

  return `
      THREE.WebGLShader: gl.getShaderInfoLog() ${type}
      ${log}
      ${addLineNumbers(source)}
  `;
}