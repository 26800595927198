import { authorizedGet } from "utils/request";
import { PointCloudOctreeGeometry } from "./geometry";
import { PointAttribute, PointAttributeTypes } from "./pointattributes";
import { Box3, Sphere, Vector3 } from "potree/mathtypes";
import { Version } from "potree/version";
import { scriptPath } from "potree/paths";
import { workerPool } from "potree/workerpool";
import { BufferGeometry } from "potree/rendering/buffers";
import { BufferAttribute } from "potree/rendering/bufferattribute";
import { PointCloudOctree, PointCloudOctreeGeometryNode } from "./octree";
import { LeastRecentlyUsed } from "potree/utils/leastRecentlyUsed";

export const lru = new LeastRecentlyUsed();

let pointBudget = 2_000_000;
let pointLoadLimit = pointBudget * 2;

export function setPointBudget(budget) {
  pointBudget = budget;
  pointLoadLimit = pointBudget * 2;
}
export function getPointBudget() {
  return pointBudget;
}
export function getPointLoadLimit() {
  return pointLoadLimit;
}

let numNodesLoading = 0;
export const maxNodesLoading = 4;

export function startedLoading() {
  numNodesLoading++;
}
export function stoppedLoading() {
  numNodesLoading--;
}

export function canLoadMore() {
  return numNodesLoading < maxNodesLoading;
}

class BinaryLoader {
  constructor(version, boundingBox, scale) {
    if (typeof version === "string") {
      this.version = new Version(version);
    } else {
      this.version = version;
    }

    this.boundingBox = boundingBox;
    this.scale = scale;
  }

  load(node) {
    if (node.loaded) {
      return;
    }

    const url = node.getURL() + ".bin";

    fetch(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        this.parse(node, buffer);
      })
      .catch((error) => {
        console.log(
          "Failed to load file! HTTP status: " + error + ", file: " + url
        );
      });
  }

  parse(node, buffer) {
    let pointAttributes = node.pcoGeometry.pointAttributes;

    let workerPath = scriptPath + "workers/BinaryDecoderWorker.js";
    let worker = workerPool.getWorker(workerPath);

    worker.onmessage = function (e) {
      let data = e.data;
      let buffers = data.attributeBuffers;
      let tightBoundingBox = new Box3(
        new Vector3().fromArray(data.tightBoundingBox.min),
        new Vector3().fromArray(data.tightBoundingBox.max)
      );
      workerPool.returnWorker(workerPath, worker);

      let geometry = new BufferGeometry();
      for (const property in buffers) {
        let buffer = buffers[property].buffer;
        let batchAttribute = buffers[property].attribute;
        if (property === "POSITION_CARTESIAN") {
          geometry.setAttribute(
            "position",
            new BufferAttribute(new Float32Array(buffer), 3)
          );
        } else if (property === "rgba") {
          geometry.setAttribute(
            "rgba",
            new BufferAttribute(new Uint8Array(buffer), 4, true)
          );
        } else if (property === "NORMAL_SPHEREMAPPED") {
          geometry.setAttribute(
            "normal",
            new BufferAttribute(new Float32Array(buffer), 3)
          );
        } else if (property === "NORMAL_OCT16") {
          geometry.setAttribute(
            "normal",
            new BufferAttribute(new Float32Array(buffer), 3)
          );
        } else if (property === "NORMAL") {
          geometry.setAttribute(
            "normal",
            new BufferAttribute(new Float32Array(buffer), 3)
          );
        } else if (property === "INDICES") {
          let bufferAttribute = new BufferAttribute(new Uint8Array(buffer), 4);
          bufferAttribute.normalized = true;
          geometry.setAttribute("indices", bufferAttribute);
        } else if (property === "SPACING") {
          let bufferAttribute = new BufferAttribute(
            new Float32Array(buffer),
            1
          );
          geometry.setAttribute("spacing", bufferAttribute);
        } else if (property === "user-data") {
          let bufferAttribute = new BufferAttribute(
            new Float32Array(buffer),
            1
          );
          geometry.setAttribute("userData", bufferAttribute);
        } else {
          const bufferAttribute = new BufferAttribute(
            new Float32Array(buffer),
            1
          );

          bufferAttribute.potree = {
            offset: buffers[property].offset,
            scale: buffers[property].scale,
            preciseBuffer: buffers[property].preciseBuffer,
            range: batchAttribute.range,
          };

          geometry.setAttribute(property, bufferAttribute);

          const attribute = pointAttributes.attributes.find(
            (a) => a.name === batchAttribute.name
          );
          attribute.range[0] = Math.min(
            attribute.range[0],
            batchAttribute.range[0]
          );
          attribute.range[1] = Math.max(
            attribute.range[1],
            batchAttribute.range[1]
          );

          if (node.getLevel() === 0) {
            attribute.initialRange = batchAttribute.range;
          }
        }
      }

      tightBoundingBox.max.sub(tightBoundingBox.min);
      tightBoundingBox.min.set(0, 0, 0);

      const numPoints = e.data.buffer.byteLength / pointAttributes.byteSize;

      node.numPoints = numPoints;
      node.geometry = geometry;
      node.mean = new Vector3(...data.mean);
      node.tightBoundingBox = tightBoundingBox;
      node.loaded = true;
      node.loading = false;
      node.estimatedSpacing = data.estimatedSpacing;
      numNodesLoading--;
    };

    const message = {
      buffer: buffer,
      pointAttributes: pointAttributes,
      version: this.version.version,
      min: [
        node.boundingBox.min.x,
        node.boundingBox.min.y,
        node.boundingBox.min.z,
      ],
      offset: [
        node.pcoGeometry.offset.x,
        node.pcoGeometry.offset.y,
        node.pcoGeometry.offset.z,
      ],
      scale: this.scale,
      spacing: node.spacing,
      hasChildren: node.hasChildren,
      name: node.name,
    };
    worker.postMessage(message);
  }
}

class PointAttributes {
  constructor(pointAttributes) {
    this.attributes = [];
    this.byteSize = 0;
    this.size = 0;
    this.vectors = [];

    if (pointAttributes != null) {
      for (let i = 0; i < pointAttributes.length; i++) {
        let pointAttributeName = pointAttributes[i];
        let pointAttribute = PointAttribute[pointAttributeName];
        this.attributes.push(pointAttribute);
        this.byteSize += pointAttribute.byteSize;
        this.size++;
      }
    }
  }

  add(pointAttribute) {
    this.attributes.push(pointAttribute);
    this.byteSize += pointAttribute.byteSize;
    this.size++;
  }

  addVector(vector) {
    this.vectors.push(vector);
  }
}

function parseAttributes(cloudjs) {
  const replacements = {
    COLOR_PACKED: "rgba",
    RGBA: "rgba",
    INTENSITY: "intensity",
    CLASSIFICATION: "classification",
    GPS_TIME: "gps-time",
    USER_DATA: "user-data",
  };

  const replaceOldNames = (old) => {
    return replacements[old] ?? old;
  };

  const pointAttributes = [];
  pointAttributes.push(...cloudjs.pointAttributes);

  {
    const attributes = new PointAttributes();

    const typeConversion = {
      int8: PointAttributeTypes.DATA_TYPE_INT8,
      int16: PointAttributeTypes.DATA_TYPE_INT16,
      int32: PointAttributeTypes.DATA_TYPE_INT32,
      int64: PointAttributeTypes.DATA_TYPE_INT64,
      uint8: PointAttributeTypes.DATA_TYPE_UINT8,
      uint16: PointAttributeTypes.DATA_TYPE_UINT16,
      uint32: PointAttributeTypes.DATA_TYPE_UINT32,
      uint64: PointAttributeTypes.DATA_TYPE_UINT64,
      double: PointAttributeTypes.DATA_TYPE_DOUBLE,
      float: PointAttributeTypes.DATA_TYPE_FLOAT,
    };

    for (const jsAttribute of pointAttributes) {
      const name = replaceOldNames(jsAttribute.name);
      const type = typeConversion[jsAttribute.type];
      const numElements = jsAttribute.elements;

      const attribute = new PointAttribute(name, type, numElements);

      attributes.add(attribute);
    }

    {
      // check if it has normals
      const hasNormals =
        pointAttributes.find((a) => a.name === "NormalX") !== undefined &&
        pointAttributes.find((a) => a.name === "NormalY") !== undefined &&
        pointAttributes.find((a) => a.name === "NormalZ") !== undefined;

      if (hasNormals) {
        const vector = {
          name: "NORMAL",
          attributes: ["NormalX", "NormalY", "NormalZ"],
        };
        attributes.addVector(vector);
      }
    }

    return attributes;
  }
}

class POCLoader {
  static load(definition_path, files_path, project_id, callback) {
    let pco = new PointCloudOctreeGeometry();
    pco.url = files_path;

    authorizedGet(definition_path, {}, {}, project_id)
      .catch((error) => {
        window.viewer.postError(
          `Failed to load pointcloud geometry: ${error}`,
          {}
        );
      })
      .then(async (fMno) => {
        if (fMno.octreeDir.indexOf("http") === 0) {
          pco.octreeDir = fMno.octreeDir;
        } else {
          pco.octreeDir = files_path + "/../" + fMno.octreeDir;
        }

        pco.spacing = fMno.spacing;
        pco.hierarchyStepSize = fMno.hierarchyStepSize;

        pco.pointAttributes = fMno.pointAttributes;

        const min = new Vector3(
          fMno.boundingBox.lx,
          fMno.boundingBox.ly,
          fMno.boundingBox.lz
        );
        const max = new Vector3(
          fMno.boundingBox.ux,
          fMno.boundingBox.uy,
          fMno.boundingBox.uz
        );
        let boundingBox = new Box3(min, max);
        let tightBoundingBox = boundingBox.clone();

        if (fMno.tightBoundingBox) {
          tightBoundingBox.min.copy(
            new Vector3(
              fMno.tightBoundingBox.lx,
              fMno.tightBoundingBox.ly,
              fMno.tightBoundingBox.lz
            )
          );
          tightBoundingBox.max.copy(
            new Vector3(
              fMno.tightBoundingBox.ux,
              fMno.tightBoundingBox.uy,
              fMno.tightBoundingBox.uz
            )
          );
        }

        const offset = min.clone();

        boundingBox.min.sub(offset);
        boundingBox.max.sub(offset);

        tightBoundingBox.min.sub(offset);
        tightBoundingBox.max.sub(offset);

        pco.projection = fMno.projection;
        pco.boundingBox = boundingBox;
        pco.tightBoundingBox = tightBoundingBox;
        pco.boundingSphere = boundingBox.getBoundingSphere(new Sphere());
        pco.tightBoundingSphere = tightBoundingBox.getBoundingSphere(
          new Sphere()
        );
        pco.offset = offset;
        pco.loader = new BinaryLoader(fMno.version, boundingBox, fMno.scale);
        pco.pointAttributes = parseAttributes(fMno);

        let nodes = {};

        {
          // load root
          const name = "r";

          let root = new PointCloudOctreeGeometryNode(name, pco, boundingBox);
          root.level = 0;
          root.hasChildren = true;
          root.spacing = pco.spacing;
          root.numPoints = 0;
          pco.root = root;
          let promise = pco.root.load();
          if (!!promise) {
            await promise;
          }
          nodes[name] = root;
        }

        pco.nodes = nodes;
        callback(pco);
      })
      .catch((error) => {
        console.log("Loading failed: '" + definition_path + "'");
        console.log(error);

        callback();
      });
  }
}

export function loadPointCloud(definition_path, files_path, project_id, name) {
  const promise = new Promise((resolve) => {
    function geoLoaded(geometry) {
      if (!geometry) {
        console.error(
          new Error(`Failed to load point cloud from URL: ${definition_path}`)
        );
        window.viewer.postError(`Error loading scene.`, {});
      } else {
        let pointcloud = new PointCloudOctree(geometry);
        pointcloud.name = name;

        resolve(pointcloud);
      }
    }

    // load pointcloud
    POCLoader.load(definition_path, files_path, project_id, geoLoaded);
  });

  return promise;
}
