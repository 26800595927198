import { Color } from "./rendering/types";

export const CameraMode = {
    ORTHOGRAPHIC: 0,
    PERSPECTIVE: 1
};

export const ClipTask = {
    NONE: 0,
    HIGHLIGHT: 1,
    SHOW_INSIDE: 2,
    SHOW_OUTSIDE: 3,
};

export const ClipMethod = {
    INSIDE_ANY: 0,
    INSIDE_ALL: 1,
};

export const ElevationGradientRepeat = {
    CLAMP: 0,
    REPEAT: 1,
    MIRRORED_REPEAT: 2,
};

export const PointSizeType = {
    FIXED: 0,
    ATTENUATED: 1,
    ADAPTIVE: 2,
};

export const PointShape = {
    SQUARE: 0,
    CIRCLE: 1,
    PARABOLOID: 2,
};

export const TreeType = {
    OCTREE: 0,
    KDTREE: 1,
};

export const LengthUnits = {
    METER: { code: "m", unitspermeter: 1.0 },
    FEET: { code: "ft", unitspermeter: 3.28084 },
    INCH: { code: "\u2033", unitspermeter: 39.3701 },
};

  // -------------------------------------------
  // to get a ready to use gradient array from a chroma.js gradient:
  // http://gka.github.io/chroma.js/
  // -------------------------------------------
  //
  // let stops = [];
  // for(let i = 0; i <= 10; i++){
  //  let range = chroma.scale(['yellow', 'navy']).mode('lch').domain([10,0])(i)._rgb
  //    .slice(0, 3)
  //    .map(v => (v / 255).toFixed(4))
  //    .join(", ");
  //
  //  let line = `[${i / 10}, new THREE.Color(${range})],`;
  //
  //  stops.push(line);
  // }
  // stops.join("\n");
  //
  //
  //
  // -------------------------------------------
  // to get a ready to use gradient array from matplotlib:
  // -------------------------------------------
  // import matplotlib.pyplot as plt
  // import matplotlib.colors as colors
  //
  // norm = colors.Normalize(vmin=0,vmax=1)
  // cmap = plt.cm.viridis
  //
  // for i in range(0,11):
  //  u = i / 10
  //  rgb = cmap(norm(u))[0:3]
  //  rgb = ["{0:.3f}".format(v) for v in rgb]
  //  rgb = "[" + str(u) + ", new THREE.Color(" +  ", ".join(rgb) + ")],"
  //  print(rgb)
export const Gradients = {
    // From chroma spectral http://gka.github.io/chroma.js/
    SPECTRAL: [
      [0, new Color(0.3686, 0.3098, 0.6353)],
      [0.1, new Color(0.1961, 0.5333, 0.7412)],
      [0.2, new Color(0.4, 0.7608, 0.6471)],
      [0.3, new Color(0.6706, 0.8667, 0.6431)],
      [0.4, new Color(0.902, 0.9608, 0.5961)],
      [0.5, new Color(1.0, 1.0, 0.749)],
      [0.6, new Color(0.9961, 0.8784, 0.5451)],
      [0.7, new Color(0.9922, 0.6824, 0.3804)],
      [0.8, new Color(0.9569, 0.4275, 0.2627)],
      [0.9, new Color(0.8353, 0.2431, 0.3098)],
      [1, new Color(0.6196, 0.0039, 0.2588)],
    ],
    GRAYSCALE: [
      [0, new Color(0, 0, 0)],
      [1, new Color(1, 1, 1)],
    ]
};

export const MOUSE = { LEFT: 1, RIGHT: 2, ROTATE: 0, DOLLY: 1, PAN: 2 };