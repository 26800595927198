import { CONFIG_CONSTANTS } from "config";
import { Mesh } from "potree/geometry";
import { Vector3 } from "potree/mathtypes";
import { SQBoxBuffer, SQLineBuffer } from "potree/nova_renderer/buffer";
import { Color } from "potree/rendering/types";
import { ToolBase } from "./base";

//ARKION CUSTOM
export class ImageObjectTool extends ToolBase {
	#imageGeometry = new SQBoxBuffer(1, 1, 0.05);
	#imageColor = new Color(1.0, 1.0, 1.0);
	#imageHoverColor = new Color(0.8, 0.8, 0.8);

	#imageDefectColor = new Color(1.0, 0.5, 0.5);
	#imageDefectHoverColor = new Color(0.8, 0.3, 0.3);

	#viewConeGeometry;
	// Don't like this.
	#viewConeGeometrySideOne;
	#viewConeGeometrySideTwo;
	#viewConeGeometrySideThree;
	#viewConeGeometrySideFour;

	#hoveredImage = -1;

	images = {};

	#imageProgram = null;

	#projectionMatrixLocation = null;
	#modelViewMatrixLocation = null;
	#diffuseLocation = null;

	#lineProgram = null;

	#imageBuffer = null;

	constructor(viewer) {
		super(viewer, "scene_image_object", true);

		viewer.addEventListener("scene_context_changed", () => {
			this.images = {};
		});

		const width = 0.5, depth = 5, t = 3;

		this.#viewConeGeometry = new SQLineBuffer([
			// bottom
			new Vector3(width * t, -width * t, -depth),
			new Vector3(-width * t, -width * t, -depth),
			new Vector3(-width * t, -width * t, -depth),

			// top
			new Vector3(-width * t, width * t, -depth),
			new Vector3(-width * t, width * t, -depth),
			// sides
			new Vector3(width * t, width * t, -depth),
			new Vector3(width * t, width * t, -depth),
			new Vector3(width * t, -width * t, -depth),
		]);

		this.#viewConeGeometrySideOne = new SQLineBuffer([
			new Vector3(-width * t, width * t, -depth),
			new Vector3(-width + 0.01, width - 0.01, 0),
		]);
		this.#viewConeGeometrySideTwo = new SQLineBuffer([
			new Vector3(-width + 0.01, -width + 0.01, 0),
			new Vector3(-width * t, -width * t, -depth),
		]);
		this.#viewConeGeometrySideThree = new SQLineBuffer([
			new Vector3(width * t, -width * t, -depth),
			new Vector3(width - 0.01, -width + 0.01, 0),
		]);
		this.#viewConeGeometrySideFour = new SQLineBuffer([
			new Vector3(width - 0.01, width - 0.01, 0),
			new Vector3(width * t, width * t, -depth),
		]);

		viewer.onLoadedRawSceneData.push((raw_data) => {
			const images = raw_data["images"];
			viewer.sceneData["images"] = images;

			viewer.sceneData["highest_image_alt"] = images.reduce(
				(accumulator, value) => value["altitude"] > accumulator ? value["altitude"] : accumulator,
				0
			);
		});

		viewer.addEventListener("scene_loaded", async () => {
			await viewer.epsgCallback;
			Object.values(viewer.sceneData["images"]).forEach((image) => {
				this.#addImageObject(
					image["project_id"],
					image["lng"],
					image["lat"],
					image["altitude"],
					image["pitch"] ?? CONFIG_CONSTANTS.FALLBACK_PITCH,
					image["compass_dir"],
					image["id"],
					image["defect_count"]
				);
			});

			if (viewer.targetLocation.type === "IMAGE") {
				let targetImage = viewer.targetLocation.image_id;
				this.goToImage(targetImage);
			}
		});

		viewer.initializedPromise.then(() => {
			const gl = this.viewer.gl;
			this.#imageProgram = this.viewer.shaderCache.getProgram(
				gl,
				"flat_mesh.vert",
				"flat_mesh.frag"
			);
			this.#lineProgram = this.viewer.shaderCache.getProgram(
				gl,
				"new_line.vert",
				"new_line.frag"
			);
			this.#imageBuffer = this.viewer.shaderCache.getBuffer(
				gl,
				this.#imageGeometry
			);

			this.#projectionMatrixLocation = gl.getUniformLocation(
				this.#imageProgram,
				"projectionMatrix"
			);

			this.#modelViewMatrixLocation = gl.getUniformLocation(
				this.#imageProgram,
				"modelViewMatrix"
			);
			this.#diffuseLocation = gl.getUniformLocation(
				this.#imageProgram,
				"diffuse"
			);
		});
	}

	renderImages() {
		if (this.active === false) {
			return;
		}

		const gl = this.viewer.gl;
		const camera = this.viewer.sceneContext.getActiveCamera();
		{
			// Set up render context.
			gl.useProgram(this.#imageProgram);
			gl.uniformMatrix4fv(
				this.#projectionMatrixLocation,
				false,
				camera.projectionMatrix.elements
			);

			const buffer = this.#imageBuffer;

			gl.bindVertexArray(buffer.vertexArray);

			gl.bindBuffer(gl.ARRAY_BUFFER, buffer.positionsBuffer);
			gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer.indexBuf);

			const indexCount = this.#imageGeometry.indices.length;

			// In an ideal world this would be on the GPU.
			for (let image_object of Object.values(this.images)) {
				image_object.modelViewMatrix.multiplyMatrices(
					camera.matrixWorldInverse,
					image_object.matrixWorld
				);
			}

			for (const image_object of Object.values(this.images)) {
				gl.uniformMatrix4fv(
					this.#modelViewMatrixLocation,
					false,
					image_object.modelViewMatrix.elements
				);

				const color = image_object.color;

				gl.uniform3f(this.#diffuseLocation, color.r, color.g, color.b);

				// Draw an instance using bound buffers.
				gl.drawElements(gl.TRIANGLES, indexCount, gl.UNSIGNED_BYTE, 0);
			}
		}

		// Render view cone.
		if (this.#hoveredImage !== -1) {
			gl.useProgram(this.#lineProgram);
			gl.disable(gl.CULL_FACE);

			const projectionMatrixLocation = gl.getUniformLocation(
				this.#lineProgram,
				"projectionMatrix"
			);
			gl.uniformMatrix4fv(
				projectionMatrixLocation,
				false,
				camera.projectionMatrix.elements
			);
			const aspectLocation = gl.getUniformLocation(this.#lineProgram, "aspect");
			gl.uniform1f(aspectLocation, camera.aspect);

			const modelViewMatrixLocation = gl.getUniformLocation(
				this.#lineProgram,
				"modelViewMatrix"
			);
			const thicknessLocation = gl.getUniformLocation(
				this.#lineProgram,
				"thickness"
			);
			const diffuseLocation = gl.getUniformLocation(
				this.#lineProgram,
				"diffuse"
			);

			const hovered_image = this.images[this.#hoveredImage];
			if (!!hovered_image) {
				const thickness = 0.06;

				gl.uniformMatrix4fv(
					modelViewMatrixLocation,
					false,
					hovered_image.modelViewMatrix.elements
				);
				gl.uniform1f(thicknessLocation, thickness);

				const color = hovered_image.color;
				gl.uniform4f(diffuseLocation, color.r, color.g, color.b, 1.0);

				this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometry);
				this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideOne);
				this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideTwo);
				this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideThree);
				this.viewer.lineRenderer.drawLines(gl, this.#viewConeGeometrySideFour);
			}
			gl.enable(gl.CULL_FACE);
		}
	}

	goToImage(id) {
		const targetImage = this.images[id];
		if (!!targetImage) {
			const backPull = new Vector3(
				0,
				0,
				CONFIG_CONSTANTS.BACKPULL_DISTANCE
			).applyQuaternion(targetImage.quaternion);

			this.viewer.setCameraLocation(
				targetImage.position.x - backPull.x,
				targetImage.position.y - backPull.y,
				targetImage.position.z - backPull.z,
				-targetImage.rotation.x / (Math.PI / 180) +
				CONFIG_CONSTANTS.IMAGE_PITCH_CHANGE,
				-targetImage.rotation.z / (Math.PI / 180)
			);
		}
	}

	#addImageObject(projectId, lng, lat, alt, pitch, yaw, id, defect_count) {
		const has_defects = defect_count > 0;
		const hoverColor = has_defects
			? this.#imageDefectHoverColor
			: this.#imageHoverColor;
		const regularColor = has_defects
			? this.#imageDefectColor
			: this.#imageColor;

		const imageObject = new Mesh(this.#imageGeometry, null);
		imageObject.color = regularColor;

		imageObject.addEventListener("mouseover", () => {
			this.#hoveredImage = id;
			imageObject.color = hoverColor;
		});

		imageObject.addEventListener("mouseleave", () => {
			this.#hoveredImage = -1;
			imageObject.color = regularColor;
		});

		imageObject.addEventListener("dblclick", () => this.viewer.openImageInMap(projectId, id)
		);

		const transformedPos = this.viewer.convertWGS84toLidar(lng, lat, alt);
		// Update view position & rotation with transformed positions & recieved rotations.
		imageObject.position.set(
			transformedPos[0],
			transformedPos[1],
			transformedPos[2]
		);
		imageObject.rotation.order = "ZXY";
		imageObject.rotation.set(
			-pitch * (Math.PI / 180),
			0,
			-yaw * (Math.PI / 180)
		);

		imageObject.updateMatrixWorld();
		this.scene.add(imageObject);

		this.images[id] = imageObject;
	}
}
