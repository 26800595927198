import { Frustum, Mesh, Plane } from "potree/geometry";
import {
  CanvasTexture,
  Color,
  CubeTexture,
  Texture,
} from "../potree/rendering/types";
import { Matrix3, Matrix4, Vector2, Vector3, Vector4 } from "potree/mathtypes";
import { arrayMax, generateUUID } from "potree/utils/math";
import {
  BufferAttribute,
  Uint16BufferAttribute,
  Uint32BufferAttribute,
} from "../potree/rendering/bufferattribute";
import {
  DataTexture,
  DataTexture2DArray,
  DataTexture3D,
} from "../potree/rendering/datatexture";
import {
  StaticDrawUsage,
  ClampToEdgeWrapping,
  RGBFormat,
  RGBAFormat,
  DepthFormat,
  DepthStencilFormat,
  LinearMipMapLinearFilter,
  LinearEncoding,
  RepeatWrapping,
  MirroredRepeatWrapping,
  NormalBlending,
  NoBlending,
  CustomBlending,
  AdditiveBlending,
  SubtractiveBlending,
  MultiplyBlending,
  SrcAlphaFactor,
  ZeroFactor,
  OneFactor,
  SrcColorFactor,
  SrcAlphaSaturateFactor,
  OneMinusSrcAlphaFactor,
  OneMinusSrcColorFactor,
  DstAlphaFactor,
  DstColorFactor,
  OneMinusDstAlphaFactor,
  OneMinusDstColorFactor,
  AddEquation,
  LessEqualDepth,
  NeverDepth,
  GreaterDepth,
  GreaterEqualDepth,
  LessDepth,
  AlwaysDepth,
  EqualDepth,
  NotEqualDepth,
  RGBADepthPacking,
  MultiplyOperation,
  MixOperation,
  AddOperation,
  SubtractEquation,
  ReverseSubtractEquation,
  FrontSide,
  BackSide,
  DoubleSide,
  CullFaceBack,
  CullFaceFront,
  CullFaceNone,
  CubeReflectionMapping,
  CubeRefractionMapping,
  CubeUVReflectionMapping,
  CubeUVRefractionMapping,
  NearestFilter,
  LinearFilter,
  NearestMipMapLinearFilter,
  NearestMipMapNearestFilter,
  LinearMipMapNearestFilter,
  sRGBEncoding,
  RGBEEncoding,
  RGBM7Encoding,
  RGBM16Encoding,
  RGBDEncoding,
  GammaEncoding,
  LogLuvEncoding,
  MinEquation,
  MaxEquation,
  BasicShadowMap,
  PCFShadowMap,
  PCFSoftShadowMap,
  VSMShadowMap,
  ObjectSpaceNormalMap,
  TangentSpaceNormalMap,
  FloatType,
  UnsignedInt248Type,
  UnsignedIntType,
  UnsignedByteType,
  UnsignedShortType,
  ByteType,
  ShortType,
  IntType,
  HalfFloatType,
  UnsignedShort4444Type,
  UnsignedShort5551Type,
  UnsignedShort565Type,
  RGBA_ASTC_10x10_Format,
  RGBA_ASTC_10x5_Format,
  RGBA_ASTC_10x6_Format,
  RGBA_ASTC_10x8_Format,
  RGBA_ASTC_12x10_Format,
  RGBA_ASTC_12x12_Format,
  RGBA_ASTC_4x4_Format,
  RGBA_ASTC_5x4_Format,
  RGBA_ASTC_5x5_Format,
  RGBA_ASTC_6x5_Format,
  RGBA_ASTC_6x6_Format,
  RGBA_ASTC_8x5_Format,
  RGBA_ASTC_8x6_Format,
  RGBA_ASTC_8x8_Format,
  RGBA_BPTC_Format,
  RGBA_ETC2_EAC_Format,
  RGB_S3TC_DXT1_Format,
  RGBA_S3TC_DXT1_Format,
  RGBA_S3TC_DXT3_Format,
  RGBA_S3TC_DXT5_Format,
  RGB_ETC1_Format,
  RGB_ETC2_Format,
  RGB_PVRTC_2BPPV1_Format,
  RGBA_PVRTC_2BPPV1_Format,
  RGB_PVRTC_4BPPV1_Format,
  RGBA_PVRTC_4BPPV1_Format,
  SRGB8_ALPHA8_ASTC_10x10_Format,
  SRGB8_ALPHA8_ASTC_10x5_Format,
  SRGB8_ALPHA8_ASTC_10x6_Format,
  SRGB8_ALPHA8_ASTC_10x8_Format,
  SRGB8_ALPHA8_ASTC_12x10_Format,
  SRGB8_ALPHA8_ASTC_12x12_Format,
  SRGB8_ALPHA8_ASTC_4x4_Format,
  SRGB8_ALPHA8_ASTC_5x4_Format,
  SRGB8_ALPHA8_ASTC_5x5_Format,
  SRGB8_ALPHA8_ASTC_6x5_Format,
  SRGB8_ALPHA8_ASTC_6x6_Format,
  SRGB8_ALPHA8_ASTC_8x5_Format,
  SRGB8_ALPHA8_ASTC_8x6_Format,
  SRGB8_ALPHA8_ASTC_8x8_Format,
  RedFormat,
  RedIntegerFormat,
  RGIntegerFormat,
  RGBIntegerFormat,
  RGBAIntegerFormat,
  AlphaFormat,
  LuminanceAlphaFormat,
  LuminanceFormat,
  RGFormat,
} from "../potree/rendering/constants.js";
import { addLineNumbers, filterEmptyLine } from "potree/utils/string";
import {
  ShaderChunk,
  ShaderLib,
  cloneUniforms,
} from "../potree/rendering/shaders";
import {
  MeshDepthMaterial,
  MeshDistanceMaterial,
  ShaderMaterial,
} from "../potree/rendering/material";
import { WebGLRenderTarget } from "webgl/renderTarget";
import { BufferGeometry } from "potree/rendering/buffers";

function WebGLAnimation() {
  let context = null;
  let isAnimating = false;
  let animationLoop = null;
  let requestId = null;

  function onAnimationFrame(time, frame) {
    animationLoop(time, frame);

    requestId = context.requestAnimationFrame(onAnimationFrame);
  }

  return {
    start: function () {
      if (isAnimating === true) return;
      if (animationLoop === null) return;

      requestId = context.requestAnimationFrame(onAnimationFrame);

      isAnimating = true;
    },

    stop: function () {
      context.cancelAnimationFrame(requestId);

      isAnimating = false;
    },

    setAnimationLoop: function (callback) {
      animationLoop = callback;
    },

    setContext: function (value) {
      context = value;
    },
  };
}

function WebGLAttributes(gl) {
  const buffers = new WeakMap();

  function createBuffer(attribute, bufferType) {
    const array = attribute.array;
    const usage = attribute.usage;

    const buffer = gl.createBuffer();

    gl.bindBuffer(bufferType, buffer);
    gl.bufferData(bufferType, array, usage);

    attribute.onUploadCallback();

    let type = 5126;

    if (array instanceof Float32Array) {
      type = 5126;
    } else if (array instanceof Float64Array) {
      console.warn(
        "THREE.WebGLAttributes: Unsupported data buffer format: Float64Array."
      );
    } else if (array instanceof Uint16Array) {
      if (attribute.isFloat16BufferAttribute) {
        type = 5131;
      } else {
        type = 5123;
      }
    } else if (array instanceof Int16Array) {
      type = 5122;
    } else if (array instanceof Uint32Array) {
      type = 5125;
    } else if (array instanceof Int32Array) {
      type = 5124;
    } else if (array instanceof Int8Array) {
      type = 5120;
    } else if (array instanceof Uint8Array) {
      type = 5121;
    }

    return {
      buffer: buffer,
      type: type,
      bytesPerElement: array.BYTES_PER_ELEMENT,
      version: attribute.version,
    };
  }

  function updateBuffer(buffer, attribute, bufferType) {
    const array = attribute.array;
    const updateRange = attribute.updateRange;

    gl.bindBuffer(bufferType, buffer);

    if (updateRange.count === -1) {
      // Not using update ranges

      gl.bufferSubData(bufferType, 0, array);
    } else {
      gl.bufferSubData(
        bufferType,
        updateRange.offset * array.BYTES_PER_ELEMENT,
        array,
        updateRange.offset,
        updateRange.count
      );

      updateRange.count = -1; // reset range
    }
  }

  //

  function get(attribute) {
    if (attribute.isInterleavedBufferAttribute) attribute = attribute.data;

    return buffers.get(attribute);
  }

  function remove(attribute) {
    if (attribute.isInterleavedBufferAttribute) attribute = attribute.data;

    const data = buffers.get(attribute);

    if (data) {
      gl.deleteBuffer(data.buffer);

      buffers.delete(attribute);
    }
  }

  function update(attribute, bufferType) {
    if (attribute.isInterleavedBufferAttribute) attribute = attribute.data;

    const data = buffers.get(attribute);

    if (data === undefined) {
      buffers.set(attribute, createBuffer(attribute, bufferType));
    } else if (data.version < attribute.version) {
      updateBuffer(data.buffer, attribute, bufferType);

      data.version = attribute.version;
    }
  }

  return {
    get: get,
    remove: remove,
    update: update,
  };
}

function WebGLBackground(renderer, state, premultipliedAlpha) {
  const clearColor = new Color(0x000000);
  let clearAlpha = 0;

  function render(forceClear) {
    setClear(clearColor, clearAlpha);

    if (renderer.autoClear || forceClear) {
      renderer.clear(
        renderer.autoClearColor,
        renderer.autoClearDepth,
        renderer.autoClearStencil
      );
    }
  }

  function setClear(color, alpha) {
    state.buffers.color.setClear(
      color.r,
      color.g,
      color.b,
      alpha,
      premultipliedAlpha
    );
  }

  return {
    getClearColor: function () {
      return clearColor;
    },
    setClearColor: function (color, alpha = 1) {
      clearColor.set(color);
      clearAlpha = alpha;
      setClear(clearColor, clearAlpha);
    },
    getClearAlpha: function () {
      return clearAlpha;
    },
    setClearAlpha: function (alpha) {
      clearAlpha = alpha;
      setClear(clearColor, clearAlpha);
    },
    render: render,
  };
}

function WebGLBindingStates(gl, attributes) {
  const maxVertexAttributes = gl.getParameter(34921);

  const bindingStates = {};

  const defaultState = createBindingState(null);
  let currentState = defaultState;

  function setup(object, material, program, geometry, index) {
    let updateBuffers = false;

    const state = getBindingState(geometry, program, material);

    if (currentState !== state) {
      currentState = state;
      bindVertexArrayObject(currentState.object);
    }

    updateBuffers = needsUpdate(geometry, index);

    if (updateBuffers) saveCache(geometry, index);

    if (object.isInstancedMesh === true) {
      updateBuffers = true;
    }

    if (index !== null) {
      attributes.update(index, 34963);
    }

    if (updateBuffers) {
      setupVertexAttributes(object, material, program, geometry);

      if (index !== null) {
        gl.bindBuffer(34963, attributes.get(index).buffer);
      }
    }
  }

  function createVertexArrayObject() {
    return gl.createVertexArray();
  }

  function bindVertexArrayObject(vao) {
    gl.bindVertexArray(vao);
  }

  function deleteVertexArrayObject(vao) {
    return gl.deleteVertexArray(vao);
  }

  function getBindingState(geometry, program, material) {
    const wireframe = material.wireframe === true;

    let programMap = bindingStates[geometry.id];

    if (programMap === undefined) {
      programMap = {};
      bindingStates[geometry.id] = programMap;
    }

    let stateMap = programMap[program.id];

    if (stateMap === undefined) {
      stateMap = {};
      programMap[program.id] = stateMap;
    }

    let state = stateMap[wireframe];

    if (state === undefined) {
      state = createBindingState(createVertexArrayObject());
      stateMap[wireframe] = state;
    }

    return state;
  }

  function createBindingState(vao) {
    const newAttributes = [];
    const enabledAttributes = [];
    const attributeDivisors = [];

    for (let i = 0; i < maxVertexAttributes; i++) {
      newAttributes[i] = 0;
      enabledAttributes[i] = 0;
      attributeDivisors[i] = 0;
    }

    return {
      // for backward compatibility on non-VAO support browser
      geometry: null,
      program: null,
      wireframe: false,

      newAttributes: newAttributes,
      enabledAttributes: enabledAttributes,
      attributeDivisors: attributeDivisors,
      object: vao,
      attributes: {},
      index: null,
    };
  }

  function needsUpdate(geometry, index) {
    const cachedAttributes = currentState.attributes;
    const geometryAttributes = geometry.attributes;

    let attributesNum = 0;

    for (const key in geometryAttributes) {
      const geometryAttribute = geometryAttributes[key];
      const cachedAttribute = cachedAttributes[key];

      if (cachedAttribute === undefined) return true;

      if (cachedAttribute.attribute !== geometryAttribute) return true;

      if (cachedAttribute.data !== geometryAttribute.data) return true;

      attributesNum++;
    }

    if (currentState.attributesNum !== attributesNum) return true;

    if (currentState.index !== index) return true;

    return false;
  }

  function saveCache(geometry, index) {
    const cache = {};
    const attributes = geometry.attributes;
    let attributesNum = 0;

    for (const key in attributes) {
      const attribute = attributes[key];

      const data = {};
      data.attribute = attribute;

      if (attribute.data) {
        data.data = attribute.data;
      }

      cache[key] = data;

      attributesNum++;
    }

    currentState.attributes = cache;
    currentState.attributesNum = attributesNum;

    currentState.index = index;
  }

  function initAttributes() {
    const newAttributes = currentState.newAttributes;
    newAttributes.fill(0);
  }

  function enableAttribute(attribute) {
    enableAttributeAndDivisor(attribute, 0);
  }

  function enableAttributeAndDivisor(attribute, meshPerAttribute) {
    const newAttributes = currentState.newAttributes;
    const enabledAttributes = currentState.enabledAttributes;
    const attributeDivisors = currentState.attributeDivisors;

    newAttributes[attribute] = 1;

    if (enabledAttributes[attribute] === 0) {
      gl.enableVertexAttribArray(attribute);
      enabledAttributes[attribute] = 1;
    }

    if (attributeDivisors[attribute] !== meshPerAttribute) {
      gl["vertexAttribDivisor"](attribute, meshPerAttribute);
      attributeDivisors[attribute] = meshPerAttribute;
    }
  }

  function disableUnusedAttributes() {
    const newAttributes = currentState.newAttributes;
    const enabledAttributes = currentState.enabledAttributes;

    for (let i = 0, il = enabledAttributes.length; i < il; i++) {
      if (enabledAttributes[i] !== newAttributes[i]) {
        gl.disableVertexAttribArray(i);
        enabledAttributes[i] = 0;
      }
    }
  }

  function vertexAttribPointer(index, size, type, normalized, stride, offset) {
    if (type === 5124 || type === 5125) {
      gl.vertexAttribIPointer(index, size, type, stride, offset);
    } else {
      gl.vertexAttribPointer(index, size, type, normalized, stride, offset);
    }
  }

  function setupVertexAttributes(object, material, program, geometry) {
    initAttributes();

    const geometryAttributes = geometry.attributes;

    const programAttributes = program.getAttributes();

    const materialDefaultAttributeValues = material.defaultAttributeValues;

    for (const name in programAttributes) {
      const programAttribute = programAttributes[name];

      if (programAttribute < 0) {
        continue;
      }
      const geometryAttribute = geometryAttributes[name];

      if (geometryAttribute !== undefined) {
        const normalized = geometryAttribute.normalized;
        const size = geometryAttribute.itemSize;

        const attribute = attributes.get(geometryAttribute);

        // TODO Attribute may not be available on context restore

        if (attribute === undefined) continue;

        const buffer = attribute.buffer;
        const type = attribute.type;
        const bytesPerElement = attribute.bytesPerElement;

        if (geometryAttribute.isInterleavedBufferAttribute) {
          const data = geometryAttribute.data;
          const stride = data.stride;
          const offset = geometryAttribute.offset;

          if (data && data.isInstancedInterleavedBuffer) {
            enableAttributeAndDivisor(programAttribute, data.meshPerAttribute);

            if (geometry._maxInstanceCount === undefined) {
              geometry._maxInstanceCount = data.meshPerAttribute * data.count;
            }
          } else {
            enableAttribute(programAttribute);
          }

          gl.bindBuffer(34962, buffer);
          vertexAttribPointer(
            programAttribute,
            size,
            type,
            normalized,
            stride * bytesPerElement,
            offset * bytesPerElement
          );
        } else {
          if (geometryAttribute.isInstancedBufferAttribute) {
            enableAttributeAndDivisor(
              programAttribute,
              geometryAttribute.meshPerAttribute
            );

            if (geometry._maxInstanceCount === undefined) {
              geometry._maxInstanceCount =
                geometryAttribute.meshPerAttribute * geometryAttribute.count;
            }
          } else {
            enableAttribute(programAttribute);
          }

          gl.bindBuffer(34962, buffer);
          vertexAttribPointer(programAttribute, size, type, normalized, 0, 0);
        }
      } else if (name === "instanceMatrix") {
        const attribute = attributes.get(object.instanceMatrix);

        // TODO Attribute may not be available on context restore
        if (attribute === undefined) continue;

        const buffer = attribute.buffer;
        const type = attribute.type;

        enableAttributeAndDivisor(programAttribute + 0, 1);
        enableAttributeAndDivisor(programAttribute + 1, 1);
        enableAttributeAndDivisor(programAttribute + 2, 1);
        enableAttributeAndDivisor(programAttribute + 3, 1);

        gl.bindBuffer(34962, buffer);

        gl.vertexAttribPointer(programAttribute + 0, 4, type, false, 64, 0);
        gl.vertexAttribPointer(programAttribute + 1, 4, type, false, 64, 16);
        gl.vertexAttribPointer(programAttribute + 2, 4, type, false, 64, 32);
        gl.vertexAttribPointer(programAttribute + 3, 4, type, false, 64, 48);
      } else if (name === "instanceColor") {
        const attribute = attributes.get(object.instanceColor);

        // TODO Attribute may not be available on context restore
        if (attribute === undefined) continue;

        const buffer = attribute.buffer;
        const type = attribute.type;

        enableAttributeAndDivisor(programAttribute, 1);

        gl.bindBuffer(34962, buffer);

        gl.vertexAttribPointer(programAttribute, 3, type, false, 12, 0);
      } else if (materialDefaultAttributeValues !== undefined) {
        const value = materialDefaultAttributeValues[name];

        if (value !== undefined) {
          switch (value.length) {
            case 2:
              gl.vertexAttrib2fv(programAttribute, value);
              break;

            case 3:
              gl.vertexAttrib3fv(programAttribute, value);
              break;

            case 4:
              gl.vertexAttrib4fv(programAttribute, value);
              break;

            default:
              gl.vertexAttrib1fv(programAttribute, value);
          }
        }
      }
    }

    disableUnusedAttributes();
  }

  function dispose() {
    reset();

    for (const geometryId in bindingStates) {
      const programMap = bindingStates[geometryId];

      for (const programId in programMap) {
        const stateMap = programMap[programId];

        for (const wireframe in stateMap) {
          deleteVertexArrayObject(stateMap[wireframe].object);

          delete stateMap[wireframe];
        }

        delete programMap[programId];
      }

      delete bindingStates[geometryId];
    }
  }

  function releaseStatesOfGeometry(geometry) {
    if (bindingStates[geometry.id] === undefined) return;

    const programMap = bindingStates[geometry.id];

    for (const programId in programMap) {
      const stateMap = programMap[programId];

      for (const wireframe in stateMap) {
        deleteVertexArrayObject(stateMap[wireframe].object);

        delete stateMap[wireframe];
      }

      delete programMap[programId];
    }

    delete bindingStates[geometry.id];
  }

  function releaseStatesOfProgram(program) {
    for (const geometryId in bindingStates) {
      const programMap = bindingStates[geometryId];

      if (programMap[program.id] === undefined) continue;

      const stateMap = programMap[program.id];

      for (const wireframe in stateMap) {
        deleteVertexArrayObject(stateMap[wireframe].object);

        delete stateMap[wireframe];
      }

      delete programMap[program.id];
    }
  }

  function reset() {
    resetDefaultState();

    if (currentState === defaultState) return;

    currentState = defaultState;
    bindVertexArrayObject(currentState.object);
  }

  // for backward-compatilibity

  function resetDefaultState() {
    defaultState.geometry = null;
    defaultState.program = null;
    defaultState.wireframe = false;
  }

  return {
    setup: setup,
    reset: reset,
    resetDefaultState: resetDefaultState,
    dispose: dispose,
    releaseStatesOfGeometry: releaseStatesOfGeometry,
    releaseStatesOfProgram: releaseStatesOfProgram,

    initAttributes: initAttributes,
    enableAttribute: enableAttribute,
    disableUnusedAttributes: disableUnusedAttributes,
  };
}

class WebGLBufferRenderer {
  #mode;
  #gl;
  #info;

  constructor(gl, info) {
    this.#gl = gl;
    this.#info = info;
  }

  setMode(value) {
    this.#mode = value;
  }
  render(start, count) {
    this.#gl.drawArrays(this.#mode, start, count);

    this.#info.update(count, this.#mode, 1);
  }
  renderInstances(start, count, primcount) {
    if (primcount === 0) return;

    let extension, methodName;

    extension = this.#gl;
    methodName = "drawArraysInstanced";

    extension[methodName](this.#mode, start, count, primcount);

    this.#info.update(count, this.#mode, primcount);
  }
}

function WebGLCapabilities(gl, extensions, parameters) {
  let maxAnisotropy;

  function getMaxAnisotropy() {
    if (maxAnisotropy !== undefined) return maxAnisotropy;

    const extension = extensions.get("EXT_texture_filter_anisotropic");

    if (extension !== null) {
      maxAnisotropy = gl.getParameter(extension.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
    } else {
      maxAnisotropy = 0;
    }

    return maxAnisotropy;
  }

  function getMaxPrecision(precision) {
    if (precision === "highp") {
      if (
        gl.getShaderPrecisionFormat(35633, 36338).precision > 0 &&
        gl.getShaderPrecisionFormat(35632, 36338).precision > 0
      ) {
        return "highp";
      }

      precision = "mediump";
    }

    if (precision === "mediump") {
      if (
        gl.getShaderPrecisionFormat(35633, 36337).precision > 0 &&
        gl.getShaderPrecisionFormat(35632, 36337).precision > 0
      ) {
        return "mediump";
      }
    }

    return "lowp";
  }

  /* eslint-disable no-undef */
  const isWebGL2 =
    (typeof WebGL2RenderingContext !== "undefined" &&
      gl instanceof WebGL2RenderingContext) ||
    (typeof WebGL2ComputeRenderingContext !== "undefined" &&
      gl instanceof WebGL2ComputeRenderingContext);
  /* eslint-enable no-undef */

  let precision = parameters.precision ?? "highp";
  const maxPrecision = getMaxPrecision(precision);

  if (maxPrecision !== precision) {
    console.warn(
      "THREE.WebGLRenderer:",
      precision,
      "not supported, using",
      maxPrecision,
      "instead."
    );
    precision = maxPrecision;
  }

  const logarithmicDepthBuffer = parameters.logarithmicDepthBuffer === true;

  const maxTextures = gl.getParameter(34930);
  const maxVertexTextures = gl.getParameter(35660);
  const maxTextureSize = gl.getParameter(3379);
  const maxCubemapSize = gl.getParameter(34076);

  const maxAttributes = gl.getParameter(34921);
  const maxVertexUniforms = gl.getParameter(36347);
  const maxVaryings = gl.getParameter(36348);
  const maxFragmentUniforms = gl.getParameter(36349);

  const vertexTextures = maxVertexTextures > 0;
  const floatFragmentTextures = true;
  const floatVertexTextures = vertexTextures && floatFragmentTextures;

  const maxSamples = gl.getParameter(36183);

  return {
    isWebGL2: isWebGL2,

    getMaxAnisotropy: getMaxAnisotropy,
    getMaxPrecision: getMaxPrecision,

    precision: precision,
    logarithmicDepthBuffer: logarithmicDepthBuffer,

    maxTextures: maxTextures,
    maxVertexTextures: maxVertexTextures,
    maxTextureSize: maxTextureSize,
    maxCubemapSize: maxCubemapSize,

    maxAttributes: maxAttributes,
    maxVertexUniforms: maxVertexUniforms,
    maxVaryings: maxVaryings,
    maxFragmentUniforms: maxFragmentUniforms,

    vertexTextures: vertexTextures,
    floatFragmentTextures: floatFragmentTextures,
    floatVertexTextures: floatVertexTextures,

    maxSamples: maxSamples,
  };
}

function WebGLClipping(properties) {
  const scope = this;

  let globalState = null,
    numGlobalPlanes = 0,
    localClippingEnabled = false,
    renderingShadows = false;

  const plane = new Plane(),
    viewNormalMatrix = new Matrix3(),
    uniform = { value: null, needsUpdate: false };

  this.uniform = uniform;
  this.numPlanes = 0;
  this.numIntersection = 0;

  this.init = function (planes, enableLocalClipping, camera) {
    const enabled =
      planes.length !== 0 ||
      enableLocalClipping ||
      // enable state of previous frame - the clipping code has to
      // run another frame in order to reset the state:
      numGlobalPlanes !== 0 ||
      localClippingEnabled;

    localClippingEnabled = enableLocalClipping;

    globalState = projectPlanes(planes, camera, 0);
    numGlobalPlanes = planes.length;

    return enabled;
  };

  this.beginShadows = function () {
    renderingShadows = true;
    projectPlanes(null);
  };

  this.endShadows = function () {
    renderingShadows = false;
    resetGlobalState();
  };

  this.setState = function (material, camera, useCache) {
    const planes = material.clippingPlanes,
      clipIntersection = material.clipIntersection,
      clipShadows = material.clipShadows;

    const materialProperties = properties.get(material);

    if (
      !localClippingEnabled ||
      planes === null ||
      planes.length === 0 ||
      (renderingShadows && !clipShadows)
    ) {
      // there's no local clipping

      if (renderingShadows) {
        // there's no global clipping

        projectPlanes(null);
      } else {
        resetGlobalState();
      }
    } else {
      const nGlobal = renderingShadows ? 0 : numGlobalPlanes,
        lGlobal = nGlobal * 4;

      let dstArray = materialProperties.clippingState || null;

      uniform.value = dstArray; // ensure unique state

      dstArray = projectPlanes(planes, camera, lGlobal, useCache);

      for (let i = 0; i !== lGlobal; ++i) {
        dstArray[i] = globalState[i];
      }

      materialProperties.clippingState = dstArray;
      this.numIntersection = clipIntersection ? this.numPlanes : 0;
      this.numPlanes += nGlobal;
    }
  };

  function resetGlobalState() {
    if (uniform.value !== globalState) {
      uniform.value = globalState;
      uniform.needsUpdate = numGlobalPlanes > 0;
    }

    scope.numPlanes = numGlobalPlanes;
    scope.numIntersection = 0;
  }

  function projectPlanes(planes, camera, dstOffset, skipTransform) {
    const nPlanes = planes !== null ? planes.length : 0;
    let dstArray = null;

    if (nPlanes !== 0) {
      dstArray = uniform.value;

      if (skipTransform !== true || dstArray === null) {
        const flatSize = dstOffset + nPlanes * 4,
          viewMatrix = camera.matrixWorldInverse;

        viewNormalMatrix.getNormalMatrix(viewMatrix);

        if (dstArray === null || dstArray.length < flatSize) {
          dstArray = new Float32Array(flatSize);
        }

        for (let i = 0, i4 = dstOffset; i !== nPlanes; ++i, i4 += 4) {
          plane.copy(planes[i]).applyMatrix4(viewMatrix, viewNormalMatrix);

          plane.normal.toArray(dstArray, i4);
          dstArray[i4 + 3] = plane.constant;
        }
      }

      uniform.value = dstArray;
      uniform.needsUpdate = true;
    }

    scope.numPlanes = nPlanes;
    scope.numIntersection = 0;

    return dstArray;
  }
}

function WebGLExtensions(gl) {
  const extensions = {};

  return {
    has: function (name) {
      if (extensions[name] !== undefined) {
        return extensions[name] !== null;
      }

      let extension;

      switch (name) {
        case "WEBGL_depth_texture":
          extension =
            gl.getExtension("WEBGL_depth_texture") ||
            gl.getExtension("MOZ_WEBGL_depth_texture") ||
            gl.getExtension("WEBKIT_WEBGL_depth_texture");
          break;

        case "EXT_texture_filter_anisotropic":
          extension =
            gl.getExtension("EXT_texture_filter_anisotropic") ||
            gl.getExtension("MOZ_EXT_texture_filter_anisotropic") ||
            gl.getExtension("WEBKIT_EXT_texture_filter_anisotropic");
          break;

        case "WEBGL_compressed_texture_s3tc":
          extension =
            gl.getExtension("WEBGL_compressed_texture_s3tc") ||
            gl.getExtension("MOZ_WEBGL_compressed_texture_s3tc") ||
            gl.getExtension("WEBKIT_WEBGL_compressed_texture_s3tc");
          break;

        case "WEBGL_compressed_texture_pvrtc":
          extension =
            gl.getExtension("WEBGL_compressed_texture_pvrtc") ||
            gl.getExtension("WEBKIT_WEBGL_compressed_texture_pvrtc");
          break;

        default:
          extension = gl.getExtension(name);
      }

      extensions[name] = extension;

      return extension !== null;
    },

    get: function (name) {
      if (!this.has(name)) {
        console.warn(
          "THREE.WebGLRenderer: " + name + " extension not supported."
        );
      }

      return extensions[name];
    },
  };
}

function WebGLGeometries(gl, attributes, info, bindingStates) {
  const geometries = new WeakMap();
  const wireframeAttributes = new WeakMap();

  function onGeometryDispose(event) {
    const geometry = event.target;
    const buffergeometry = geometries.get(geometry);

    if (buffergeometry.index !== null) {
      attributes.remove(buffergeometry.index);
    }

    for (const name in buffergeometry.attributes) {
      attributes.remove(buffergeometry.attributes[name]);
    }

    geometry.removeEventListener("dispose", onGeometryDispose);

    geometries.delete(geometry);

    const attribute = wireframeAttributes.get(buffergeometry);

    if (attribute) {
      attributes.remove(attribute);
      wireframeAttributes.delete(buffergeometry);
    }

    bindingStates.releaseStatesOfGeometry(buffergeometry);

    if (geometry.isInstancedBufferGeometry === true) {
      delete geometry._maxInstanceCount;
    }

    //

    info.memory.geometries--;
  }

  function get(object, geometry) {
    let buffergeometry = geometries.get(geometry);

    if (buffergeometry) return buffergeometry;

    geometry.addEventListener("dispose", onGeometryDispose);

    if (geometry.isBufferGeometry) {
      buffergeometry = geometry;
    } else if (geometry.isGeometry) {
      if (geometry._bufferGeometry === undefined) {
        geometry._bufferGeometry = new BufferGeometry().setFromObject(object);
      }

      buffergeometry = geometry._bufferGeometry;
    }

    geometries.set(geometry, buffergeometry);

    info.memory.geometries++;

    return buffergeometry;
  }

  function update(geometry) {
    const geometryAttributes = geometry.attributes;

    // Updating index buffer in VAO now. See WebGLBindingStates.

    for (const name in geometryAttributes) {
      attributes.update(geometryAttributes[name], 34962);
    }
  }

  function updateWireframeAttribute(geometry) {
    const indices = [];

    const geometryIndex = geometry.index;
    const geometryPosition = geometry.attributes.position;
    let version = 0;

    if (geometryIndex !== null) {
      const array = geometryIndex.array;
      version = geometryIndex.version;

      for (let i = 0, l = array.length; i < l; i += 3) {
        const a = array[i + 0];
        const b = array[i + 1];
        const c = array[i + 2];

        indices.push(a, b, b, c, c, a);
      }
    } else {
      const array = geometryPosition.array;
      version = geometryPosition.version;

      for (let i = 0, l = array.length / 3 - 1; i < l; i += 3) {
        const a = i + 0;
        const b = i + 1;
        const c = i + 2;

        indices.push(a, b, b, c, c, a);
      }
    }

    const attribute = new (
      arrayMax(indices) > 65535 ? Uint32BufferAttribute : Uint16BufferAttribute
    )(indices, 1);
    attribute.version = version;

    // Updating index buffer in VAO now. See WebGLBindingStates

    //

    const previousAttribute = wireframeAttributes.get(geometry);

    if (previousAttribute) attributes.remove(previousAttribute);

    //

    wireframeAttributes.set(geometry, attribute);
  }

  function getWireframeAttribute(geometry) {
    const currentAttribute = wireframeAttributes.get(geometry);

    if (currentAttribute) {
      const geometryIndex = geometry.index;

      if (geometryIndex !== null) {
        // if the attribute is obsolete, create a new one

        if (currentAttribute.version < geometryIndex.version) {
          updateWireframeAttribute(geometry);
        }
      }
    } else {
      updateWireframeAttribute(geometry);
    }

    return wireframeAttributes.get(geometry);
  }

  return {
    get: get,
    update: update,

    getWireframeAttribute: getWireframeAttribute,
  };
}

function WebGLIndexedBufferRenderer(gl, info) {
  let mode;

  function setMode(value) {
    mode = value;
  }

  let type, bytesPerElement;

  function setIndex(value) {
    type = value.type;
    bytesPerElement = value.bytesPerElement;
  }

  function render(start, count) {
    gl.drawElements(mode, count, type, start * bytesPerElement);

    info.update(count, mode, 1);
  }

  function renderInstances(start, count, primcount) {
    if (primcount === 0) return;

    gl["drawElementsInstanced"](
      mode,
      count,
      type,
      start * bytesPerElement,
      primcount
    );

    info.update(count, mode, primcount);
  }

  //

  this.setMode = setMode;
  this.setIndex = setIndex;
  this.render = render;
  this.renderInstances = renderInstances;
}

function WebGLInfo(gl) {
  const memory = {
    geometries: 0,
    textures: 0,
  };

  const render = {
    frame: 0,
    calls: 0,
    triangles: 0,
    points: 0,
    lines: 0,
  };

  function update(count, mode, instanceCount) {
    render.calls++;

    switch (mode) {
      case 4:
        render.triangles += instanceCount * (count / 3);
        break;

      case 1:
        render.lines += instanceCount * (count / 2);
        break;

      case 3:
        render.lines += instanceCount * (count - 1);
        break;

      case 2:
        render.lines += instanceCount * count;
        break;

      case 0:
        render.points += instanceCount * count;
        break;

      default:
        console.error("THREE.WebGLInfo: Unknown draw mode:", mode);
        break;
    }
  }

  function reset() {
    render.frame++;
    render.calls = 0;
    render.triangles = 0;
    render.points = 0;
    render.lines = 0;
  }

  return {
    memory: memory,
    render: render,
    programs: null,
    autoReset: true,
    reset: reset,
    update: update,
  };
}

function WebGLObjects(gl, geometries, attributes, info) {
  let updateMap = new WeakMap();

  function update(object) {
    const frame = info.render.frame;

    const geometry = object.geometry;
    const buffergeometry = geometries.get(object, geometry);

    // Update once per frame

    if (updateMap.get(buffergeometry) !== frame) {
      if (geometry.isGeometry) {
        buffergeometry.updateFromObject(object);
      }

      geometries.update(buffergeometry);

      updateMap.set(buffergeometry, frame);
    }

    if (object.isInstancedMesh) {
      if (
        object.hasEventListener("dispose", onInstancedMeshDispose) === false
      ) {
        object.addEventListener("dispose", onInstancedMeshDispose);
      }

      attributes.update(object.instanceMatrix, 34962);

      if (object.instanceColor !== null) {
        attributes.update(object.instanceColor, 34962);
      }
    }

    return buffergeometry;
  }

  function dispose() {
    updateMap = new WeakMap();
  }

  function onInstancedMeshDispose(event) {
    const instancedMesh = event.target;

    instancedMesh.removeEventListener("dispose", onInstancedMeshDispose);

    attributes.remove(instancedMesh.instanceMatrix);

    if (instancedMesh.instanceColor !== null)
      attributes.remove(instancedMesh.instanceColor);
  }

  return {
    update: update,
    dispose: dispose,
  };
}
/**
 * Uniforms of a program.
 * Those form a tree structure with a special top-level container for the root,
 * which you get by calling 'new WebGLUniforms( gl, program )'.
 *
 *
 * Properties of inner nodes including the top-level container:
 *
 * .seq - array of nested uniforms
 * .map - nested uniforms by name
 *
 *
 * Methods of all nodes except the top-level container:
 *
 * .setValue( gl, value, [textures] )
 *
 *     uploads a uniform value(s)
 *    the 'textures' parameter is needed for sampler uniforms
 *
 *
 * Static methods of the top-level container (textures factorizations):
 *
 * .upload( gl, seq, values, textures )
 *
 *     sets uniforms in 'seq' to 'values[id].value'
 *
 * .seqWithValue( seq, values ) : filteredSeq
 *
 *     filters 'seq' entries with corresponding entry in values
 *
 *
 * Methods of the top-level container (textures factorizations):
 *
 * .setValue( gl, name, value, textures )
 *
 *     sets uniform with  name 'name' to 'value'
 *
 * .setOptional( gl, obj, prop )
 *
 *     like .set for an optional property of the object
 *
 */

const emptyTexture = new Texture();
const emptyTexture2dArray = new DataTexture2DArray();
const emptyTexture3d = new DataTexture3D();
const emptyCubeTexture = new CubeTexture();

// --- Utilities ---

// Array Caches (provide typed arrays for temporary by size)

const arrayCacheF32 = [];
const arrayCacheI32 = [];

// Float32Array caches used for uploading Matrix uniforms

const mat4array = new Float32Array(16);
const mat3array = new Float32Array(9);
const mat2array = new Float32Array(4);

// Flattening for arrays of vectors and matrices

function flatten(array, nBlocks, blockSize) {
  const firstElem = array[0];

  if (firstElem <= 0 || firstElem > 0) return array;
  // unoptimized: ! isNaN( firstElem )
  // see http://jacksondunstan.com/articles/983

  const n = nBlocks * blockSize;
  let r = arrayCacheF32[n];

  if (r === undefined) {
    r = new Float32Array(n);
    arrayCacheF32[n] = r;
  }

  if (nBlocks !== 0) {
    firstElem.toArray(r, 0);

    for (let i = 1, offset = 0; i !== nBlocks; ++i) {
      offset += blockSize;
      array[i].toArray(r, offset);
    }
  }

  return r;
}

function arraysEqual(a, b) {
  if (a.length !== b.length) return false;

  for (let i = 0, l = a.length; i < l; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

function copyArray(a, b) {
  for (let i = 0, l = b.length; i < l; i++) {
    a[i] = b[i];
  }
}

// Texture unit allocation

function allocTexUnits(textures, n) {
  let r = arrayCacheI32[n];

  if (r === undefined) {
    r = new Int32Array(n);
    arrayCacheI32[n] = r;
  }

  for (let i = 0; i !== n; ++i) {
    r[i] = textures.allocateTextureUnit();
  }

  return r;
}

// --- Setters ---

// Note: Defining these methods externally, because they come in a bunch
// and this way their names minify.

// Single scalar

function setValueV1f(gl, v) {
  const cache = this.cache;

  if (cache[0] === v) return;

  gl.uniform1f(this.addr, v);

  cache[0] = v;
}

// Single float vector (from flat array or THREE.VectorN)

function setValueV2f(gl, v) {
  const cache = this.cache;

  if (v.x !== undefined) {
    if (cache[0] !== v.x || cache[1] !== v.y) {
      gl.uniform2f(this.addr, v.x, v.y);

      cache[0] = v.x;
      cache[1] = v.y;
    }
  } else {
    if (arraysEqual(cache, v)) return;

    gl.uniform2fv(this.addr, v);

    copyArray(cache, v);
  }
}

function setValueV3f(gl, v) {
  const cache = this.cache;

  if (v.x !== undefined) {
    if (cache[0] !== v.x || cache[1] !== v.y || cache[2] !== v.z) {
      gl.uniform3f(this.addr, v.x, v.y, v.z);

      cache[0] = v.x;
      cache[1] = v.y;
      cache[2] = v.z;
    }
  } else if (v.r !== undefined) {
    if (cache[0] !== v.r || cache[1] !== v.g || cache[2] !== v.b) {
      gl.uniform3f(this.addr, v.r, v.g, v.b);

      cache[0] = v.r;
      cache[1] = v.g;
      cache[2] = v.b;
    }
  } else {
    if (arraysEqual(cache, v)) return;

    gl.uniform3fv(this.addr, v);

    copyArray(cache, v);
  }
}

function setValueV4f(gl, v) {
  const cache = this.cache;

  if (v.x !== undefined) {
    if (
      cache[0] !== v.x ||
      cache[1] !== v.y ||
      cache[2] !== v.z ||
      cache[3] !== v.w
    ) {
      gl.uniform4f(this.addr, v.x, v.y, v.z, v.w);

      cache[0] = v.x;
      cache[1] = v.y;
      cache[2] = v.z;
      cache[3] = v.w;
    }
  } else {
    if (arraysEqual(cache, v)) return;

    gl.uniform4fv(this.addr, v);

    copyArray(cache, v);
  }
}

// Single matrix (from flat array or MatrixN)

function setValueM2(gl, v) {
  const cache = this.cache;
  const elements = v.elements;

  if (elements === undefined) {
    if (arraysEqual(cache, v)) return;

    gl.uniformMatrix2fv(this.addr, false, v);

    copyArray(cache, v);
  } else {
    if (arraysEqual(cache, elements)) return;

    mat2array.set(elements);

    gl.uniformMatrix2fv(this.addr, false, mat2array);

    copyArray(cache, elements);
  }
}

function setValueM3(gl, v) {
  const cache = this.cache;
  const elements = v.elements;

  if (elements === undefined) {
    if (arraysEqual(cache, v)) return;

    gl.uniformMatrix3fv(this.addr, false, v);

    copyArray(cache, v);
  } else {
    if (arraysEqual(cache, elements)) return;

    mat3array.set(elements);

    gl.uniformMatrix3fv(this.addr, false, mat3array);

    copyArray(cache, elements);
  }
}

function setValueM4(gl, v) {
  const cache = this.cache;
  const elements = v.elements;

  if (elements === undefined) {
    if (arraysEqual(cache, v)) return;

    gl.uniformMatrix4fv(this.addr, false, v);

    copyArray(cache, v);
  } else {
    if (arraysEqual(cache, elements)) return;

    mat4array.set(elements);

    gl.uniformMatrix4fv(this.addr, false, mat4array);

    copyArray(cache, elements);
  }
}

// Single texture (2D / Cube)

function setValueT1(gl, v, textures) {
  const cache = this.cache;
  const unit = textures.allocateTextureUnit();

  if (cache[0] !== unit) {
    gl.uniform1i(this.addr, unit);
    cache[0] = unit;
  }

  textures.safeSetTexture2D(v || emptyTexture, unit);
}

function setValueT2DArray1(gl, v, textures) {
  const cache = this.cache;
  const unit = textures.allocateTextureUnit();

  if (cache[0] !== unit) {
    gl.uniform1i(this.addr, unit);
    cache[0] = unit;
  }

  textures.setTexture2DArray(v || emptyTexture2dArray, unit);
}

function setValueT3D1(gl, v, textures) {
  const cache = this.cache;
  const unit = textures.allocateTextureUnit();

  if (cache[0] !== unit) {
    gl.uniform1i(this.addr, unit);
    cache[0] = unit;
  }

  textures.setTexture3D(v || emptyTexture3d, unit);
}

function setValueT6(gl, v, textures) {
  const cache = this.cache;
  const unit = textures.allocateTextureUnit();

  if (cache[0] !== unit) {
    gl.uniform1i(this.addr, unit);
    cache[0] = unit;
  }

  textures.safeSetTextureCube(v || emptyCubeTexture, unit);
}

// Integer / Boolean vectors or arrays thereof (always flat arrays)

function setValueV1i(gl, v) {
  const cache = this.cache;

  if (cache[0] === v) return;

  gl.uniform1i(this.addr, v);

  cache[0] = v;
}

function setValueV2i(gl, v) {
  const cache = this.cache;

  if (arraysEqual(cache, v)) return;

  gl.uniform2iv(this.addr, v);

  copyArray(cache, v);
}

function setValueV3i(gl, v) {
  const cache = this.cache;

  if (arraysEqual(cache, v)) return;

  gl.uniform3iv(this.addr, v);

  copyArray(cache, v);
}

function setValueV4i(gl, v) {
  const cache = this.cache;

  if (arraysEqual(cache, v)) return;

  gl.uniform4iv(this.addr, v);

  copyArray(cache, v);
}

// uint

function setValueV1ui(gl, v) {
  const cache = this.cache;

  if (cache[0] === v) return;

  gl.uniform1ui(this.addr, v);

  cache[0] = v;
}

// Helper to pick the right setter for the singular case

function getSingularSetter(type) {
  switch (type) {
    case 0x1406:
      return setValueV1f; // FLOAT
    case 0x8b50:
      return setValueV2f; // _VEC2
    case 0x8b51:
      return setValueV3f; // _VEC3
    case 0x8b52:
      return setValueV4f; // _VEC4

    case 0x8b5a:
      return setValueM2; // _MAT2
    case 0x8b5b:
      return setValueM3; // _MAT3
    case 0x8b5c:
      return setValueM4; // _MAT4

    case 0x1404:
    case 0x8b56:
      return setValueV1i; // INT, BOOL
    case 0x8b53:
    case 0x8b57:
      return setValueV2i; // _VEC2
    case 0x8b54:
    case 0x8b58:
      return setValueV3i; // _VEC3
    case 0x8b55:
    case 0x8b59:
      return setValueV4i; // _VEC4

    case 0x1405:
      return setValueV1ui; // UINT

    case 0x8b5e: // SAMPLER_2D
    case 0x8d66: // SAMPLER_EXTERNAL_OES
    case 0x8dca: // INT_SAMPLER_2D
    case 0x8dd2: // UNSIGNED_INT_SAMPLER_2D
    case 0x8b62: // SAMPLER_2D_SHADOW
      return setValueT1;

    case 0x8b5f: // SAMPLER_3D
    case 0x8dcb: // INT_SAMPLER_3D
    case 0x8dd3: // UNSIGNED_INT_SAMPLER_3D
      return setValueT3D1;

    case 0x8b60: // SAMPLER_CUBE
    case 0x8dcc: // INT_SAMPLER_CUBE
    case 0x8dd4: // UNSIGNED_INT_SAMPLER_CUBE
    case 0x8dc5: // SAMPLER_CUBE_SHADOW
      return setValueT6;

    case 0x8dc1: // SAMPLER_2D_ARRAY
    case 0x8dcf: // INT_SAMPLER_2D_ARRAY
    case 0x8dd7: // UNSIGNED_INT_SAMPLER_2D_ARRAY
    case 0x8dc4: // SAMPLER_2D_ARRAY_SHADOW
      return setValueT2DArray1;
  }
}

// Array of scalars
function setValueV1fArray(gl, v) {
  gl.uniform1fv(this.addr, v);
}

// Integer / Boolean vectors or arrays thereof (always flat arrays)
function setValueV1iArray(gl, v) {
  gl.uniform1iv(this.addr, v);
}

function setValueV2iArray(gl, v) {
  gl.uniform2iv(this.addr, v);
}

function setValueV3iArray(gl, v) {
  gl.uniform3iv(this.addr, v);
}

function setValueV4iArray(gl, v) {
  gl.uniform4iv(this.addr, v);
}

// Array of vectors (flat or from THREE classes)

function setValueV2fArray(gl, v) {
  const data = flatten(v, this.size, 2);

  gl.uniform2fv(this.addr, data);
}

function setValueV3fArray(gl, v) {
  const data = flatten(v, this.size, 3);

  gl.uniform3fv(this.addr, data);
}

function setValueV4fArray(gl, v) {
  const data = flatten(v, this.size, 4);

  gl.uniform4fv(this.addr, data);
}

// Array of matrices (flat or from THREE clases)

function setValueM2Array(gl, v) {
  const data = flatten(v, this.size, 4);

  gl.uniformMatrix2fv(this.addr, false, data);
}

function setValueM3Array(gl, v) {
  const data = flatten(v, this.size, 9);

  gl.uniformMatrix3fv(this.addr, false, data);
}

function setValueM4Array(gl, v) {
  const data = flatten(v, this.size, 16);

  gl.uniformMatrix4fv(this.addr, false, data);
}

// Array of textures (2D / Cube)

function setValueT1Array(gl, v, textures) {
  const n = v.length;

  const units = allocTexUnits(textures, n);

  gl.uniform1iv(this.addr, units);

  for (let i = 0; i !== n; ++i) {
    textures.safeSetTexture2D(v[i] || emptyTexture, units[i]);
  }
}

function setValueT6Array(gl, v, textures) {
  const n = v.length;

  const units = allocTexUnits(textures, n);

  gl.uniform1iv(this.addr, units);

  for (let i = 0; i !== n; ++i) {
    textures.safeSetTextureCube(v[i] || emptyCubeTexture, units[i]);
  }
}

// Helper to pick the right setter for a pure (bottom-level) array

function getPureArraySetter(type) {
  switch (type) {
    case 0x1406:
      return setValueV1fArray; // FLOAT
    case 0x8b50:
      return setValueV2fArray; // _VEC2
    case 0x8b51:
      return setValueV3fArray; // _VEC3
    case 0x8b52:
      return setValueV4fArray; // _VEC4

    case 0x8b5a:
      return setValueM2Array; // _MAT2
    case 0x8b5b:
      return setValueM3Array; // _MAT3
    case 0x8b5c:
      return setValueM4Array; // _MAT4

    case 0x1404:
    case 0x8b56:
      return setValueV1iArray; // INT, BOOL
    case 0x8b53:
    case 0x8b57:
      return setValueV2iArray; // _VEC2
    case 0x8b54:
    case 0x8b58:
      return setValueV3iArray; // _VEC3
    case 0x8b55:
    case 0x8b59:
      return setValueV4iArray; // _VEC4

    case 0x8b5e: // SAMPLER_2D
    case 0x8d66: // SAMPLER_EXTERNAL_OES
    case 0x8dca: // INT_SAMPLER_2D
    case 0x8dd2: // UNSIGNED_INT_SAMPLER_2D
    case 0x8b62: // SAMPLER_2D_SHADOW
      return setValueT1Array;

    case 0x8b60: // SAMPLER_CUBE
    case 0x8dcc: // INT_SAMPLER_CUBE
    case 0x8dd4: // UNSIGNED_INT_SAMPLER_CUBE
    case 0x8dc5: // SAMPLER_CUBE_SHADOW
      return setValueT6Array;
  }
}

// --- Uniform Classes ---

function SingleUniform(id, activeInfo, addr) {
  this.id = id;
  this.addr = addr;
  this.cache = [];
  this.setValue = getSingularSetter(activeInfo.type);
}

function PureArrayUniform(id, activeInfo, addr) {
  this.id = id;
  this.addr = addr;
  this.cache = [];
  this.size = activeInfo.size;
  this.setValue = getPureArraySetter(activeInfo.type);
}

PureArrayUniform.prototype.updateCache = function (data) {
  const cache = this.cache;

  if (data instanceof Float32Array && cache.length !== data.length) {
    this.cache = new Float32Array(data.length);
  }

  copyArray(cache, data);
};

function StructuredUniform(id) {
  this.id = id;

  this.seq = [];
  this.map = {};
}

StructuredUniform.prototype.setValue = function (gl, value, textures) {
  const seq = this.seq;

  for (let i = 0, n = seq.length; i !== n; ++i) {
    const u = seq[i];
    u.setValue(gl, value[u.id], textures);
  }
};

// --- Top-level ---

// Parser - builds up the property tree from the path strings

const RePathPart = /(\w+)(\])?(\[|\.)?/g;

// extracts
//   - the identifier (member name or array index)
//  - followed by an optional right bracket (found when array index)
//  - followed by an optional left bracket or dot (type of subscript)
//
// Note: These portions can be read in a non-overlapping fashion and
// allow straightforward parsing of the hierarchy that WebGL encodes
// in the uniform names.

function addUniform(container, uniformObject) {
  container.seq.push(uniformObject);
  container.map[uniformObject.id] = uniformObject;
}

function parseUniform(activeInfo, addr, container) {
  const path = activeInfo.name,
    pathLength = path.length;

  // reset RegExp object, because of the early exit of a previous run
  RePathPart.lastIndex = 0;

  while (true) {
    const match = RePathPart.exec(path),
      matchEnd = RePathPart.lastIndex;

    let id = match[1];
    const idIsIndex = match[2] === "]",
      subscript = match[3];

    if (idIsIndex) id = id | 0; // convert to integer

    if (
      subscript === undefined ||
      (subscript === "[" && matchEnd + 2 === pathLength)
    ) {
      // bare name or "pure" bottom-level array "[0]" suffix

      addUniform(
        container,
        subscript === undefined
          ? new SingleUniform(id, activeInfo, addr)
          : new PureArrayUniform(id, activeInfo, addr)
      );

      break;
    } else {
      // step into inner node / create it in case it doesn't exist

      const map = container.map;
      let next = map[id];

      if (next === undefined) {
        next = new StructuredUniform(id);
        addUniform(container, next);
      }

      container = next;
    }
  }
}

// Root Container

function WebGLUniforms(gl, program) {
  this.seq = [];
  this.map = {};

  const n = gl.getProgramParameter(program, 35718);

  for (let i = 0; i < n; ++i) {
    const info = gl.getActiveUniform(program, i),
      addr = gl.getUniformLocation(program, info.name);

    parseUniform(info, addr, this);
  }
}

WebGLUniforms.prototype.setValue = function (gl, name, value, textures) {
  const u = this.map[name];

  if (u !== undefined) u.setValue(gl, value, textures);
};

WebGLUniforms.prototype.setOptional = function (gl, object, name) {
  const v = object[name];

  if (v !== undefined) this.setValue(gl, name, v);
};

// Static interface

WebGLUniforms.upload = function (gl, seq, values, textures) {
  for (let i = 0, n = seq.length; i !== n; ++i) {
    const u = seq[i],
      v = values[u.id];

    if (v.needsUpdate !== false) {
      // note: always updating when .needsUpdate is undefined
      u.setValue(gl, v.value, textures);
    }
  }
};

WebGLUniforms.seqWithValue = function (seq, values) {
  const r = [];

  for (let i = 0, n = seq.length; i !== n; ++i) {
    const u = seq[i];
    if (u.id in values) r.push(u);
  }

  return r;
};

function WebGLShader(gl, type, string) {
  const shader = gl.createShader(type);

  gl.shaderSource(shader, string);
  gl.compileShader(shader);

  return shader;
}

let programIdCount = 0;

function getEncodingComponents(encoding) {
  switch (encoding) {
    case LinearEncoding:
      return ["Linear", "( value )"];
    case sRGBEncoding:
      return ["sRGB", "( value )"];
    case RGBEEncoding:
      return ["RGBE", "( value )"];
    case RGBM7Encoding:
      return ["RGBM", "( value, 7.0 )"];
    case RGBM16Encoding:
      return ["RGBM", "( value, 16.0 )"];
    case RGBDEncoding:
      return ["RGBD", "( value, 256.0 )"];
    case GammaEncoding:
      return ["Gamma", "( value, float( GAMMA_FACTOR ) )"];
    case LogLuvEncoding:
      return ["LogLuv", "( value )"];
    default:
      console.warn("THREE.WebGLProgram: Unsupported encoding:", encoding);
      return ["Linear", "( value )"];
  }
}

function getShaderErrors(gl, shader, type) {
  const status = gl.getShaderParameter(shader, 35713);
  const log = gl.getShaderInfoLog(shader).trim();

  if (status && log === "") return "";

  // --enable-privileged-webgl-extension
  const source = gl.getShaderSource(shader);

  return (
    "THREE.WebGLShader: gl.getShaderInfoLog() " +
    type +
    "\n" +
    log +
    addLineNumbers(source)
  );
}

function getTexelDecodingFunction(functionName, encoding) {
  const components = getEncodingComponents(encoding);
  return (
    "vec4 " +
    functionName +
    "( vec4 value ) { return " +
    components[0] +
    "ToLinear" +
    components[1] +
    "; }"
  );
}

function getTexelEncodingFunction(functionName, encoding) {
  const components = getEncodingComponents(encoding);
  return (
    "vec4 " +
    functionName +
    "( vec4 value ) { return LinearTo" +
    components[0] +
    components[1] +
    "; }"
  );
}

function generateDefines(defines) {
  const chunks = [];

  for (const name in defines) {
    const value = defines[name];

    if (value === false) continue;

    chunks.push("#define " + name + " " + value);
  }

  return chunks.join("\n");
}

function fetchAttributeLocations(gl, program) {
  const attributes = {};

  const n = gl.getProgramParameter(program, 35721);

  for (let i = 0; i < n; i++) {
    const info = gl.getActiveAttrib(program, i);
    const name = info.name;

    attributes[name] = gl.getAttribLocation(program, name);
  }

  return attributes;
}

function replaceLightNums(string, parameters) {
  return string
    .replace(/NUM_DIR_LIGHTS/g, parameters.numDirLights)
    .replace(/NUM_SPOT_LIGHTS/g, parameters.numSpotLights)
    .replace(/NUM_RECT_AREA_LIGHTS/g, parameters.numRectAreaLights)
    .replace(/NUM_POINT_LIGHTS/g, parameters.numPointLights)
    .replace(/NUM_HEMI_LIGHTS/g, parameters.numHemiLights)
    .replace(/NUM_DIR_LIGHT_SHADOWS/g, parameters.numDirLightShadows)
    .replace(/NUM_SPOT_LIGHT_SHADOWS/g, parameters.numSpotLightShadows)
    .replace(/NUM_POINT_LIGHT_SHADOWS/g, parameters.numPointLightShadows);
}

function replaceClippingPlaneNums(string, parameters) {
  return string
    .replace(/NUM_CLIPPING_PLANES/g, parameters.numClippingPlanes)
    .replace(
      /UNION_CLIPPING_PLANES/g,
      parameters.numClippingPlanes - parameters.numClipIntersection
    );
}

// Resolve Includes

const includePattern = /^[ \t]*#include +<([\w\d./]+)>/gm;

function resolveIncludes(string) {
  return string.replace(includePattern, includeReplacer);
}

function includeReplacer(match, include) {
  const string = ShaderChunk[include];

  if (string === undefined) {
    throw new Error("Can not resolve #include <" + include + ">");
  }

  return resolveIncludes(string);
}

// Unroll Loops
const unrollLoopPattern =
  /#pragma unroll_loop_start\s+for\s*\(\s*int\s+i\s*=\s*(\d+)\s*;\s*i\s*<\s*(\d+)\s*;\s*i\s*\+\+\s*\)\s*{([\s\S]+?)}\s+#pragma unroll_loop_end/g;

function unrollLoops(string) {
  return string.replace(unrollLoopPattern, loopReplacer);
}

function loopReplacer(match, start, end, snippet) {
  let string = "";

  for (let i = parseInt(start); i < parseInt(end); i++) {
    string += snippet
      .replace(/\[\s*i\s*\]/g, "[ " + i + " ]")
      .replace(/UNROLLED_LOOP_INDEX/g, i);
  }

  return string;
}

//

function generatePrecision(parameters) {
  let precisionstring =
    "precision " +
    parameters.precision +
    " float;\nprecision " +
    parameters.precision +
    " int;";

  if (parameters.precision === "highp") {
    precisionstring += "\n#define HIGH_PRECISION";
  } else if (parameters.precision === "mediump") {
    precisionstring += "\n#define MEDIUM_PRECISION";
  } else if (parameters.precision === "lowp") {
    precisionstring += "\n#define LOW_PRECISION";
  }

  return precisionstring;
}

function generateShadowMapTypeDefine(parameters) {
  if (parameters.shadowMapType === BasicShadowMap) {
    return "SHADOWMAP_TYPE_BASIC";
  } else if (parameters.shadowMapType === PCFShadowMap) {
    return "SHADOWMAP_TYPE_PCF";
  } else if (parameters.shadowMapType === PCFSoftShadowMap) {
    return "SHADOWMAP_TYPE_PCF_SOFT";
  } else if (parameters.shadowMapType === VSMShadowMap) {
    return "SHADOWMAP_TYPE_VSM";
  }

  return "SHADOWMAP_TYPE_BASIC";
}

function generateEnvMapTypeDefine(parameters) {
  let envMapTypeDefine = "ENVMAP_TYPE_CUBE";

  if (parameters.envMap) {
    switch (parameters.envMapMode) {
      case CubeReflectionMapping:
      case CubeRefractionMapping:
        envMapTypeDefine = "ENVMAP_TYPE_CUBE";
        break;

      case CubeUVReflectionMapping:
      case CubeUVRefractionMapping:
        envMapTypeDefine = "ENVMAP_TYPE_CUBE_UV";
        break;
    }
  }

  return envMapTypeDefine;
}

function generateEnvMapModeDefine(parameters) {
  let envMapModeDefine = "ENVMAP_MODE_REFLECTION";

  if (parameters.envMap) {
    switch (parameters.envMapMode) {
      case CubeRefractionMapping:
      case CubeUVRefractionMapping:
        envMapModeDefine = "ENVMAP_MODE_REFRACTION";
        break;
    }
  }

  return envMapModeDefine;
}

function generateEnvMapBlendingDefine(parameters) {
  let envMapBlendingDefine = "ENVMAP_BLENDING_NONE";

  if (parameters.envMap) {
    switch (parameters.combine) {
      case MultiplyOperation:
        envMapBlendingDefine = "ENVMAP_BLENDING_MULTIPLY";
        break;

      case MixOperation:
        envMapBlendingDefine = "ENVMAP_BLENDING_MIX";
        break;

      case AddOperation:
        envMapBlendingDefine = "ENVMAP_BLENDING_ADD";
        break;
    }
  }

  return envMapBlendingDefine;
}

function WebGLProgram(renderer, cacheKey, parameters, bindingStates) {
  const gl = renderer.getContext();

  const defines = parameters.defines;

  let vertexShader = parameters.vertexShader;
  let fragmentShader = parameters.fragmentShader;

  const shadowMapTypeDefine = generateShadowMapTypeDefine(parameters);
  const envMapTypeDefine = generateEnvMapTypeDefine(parameters);
  const envMapModeDefine = generateEnvMapModeDefine(parameters);
  const envMapBlendingDefine = generateEnvMapBlendingDefine(parameters);

  const gammaFactorDefine =
    renderer.gammaFactor > 0 ? renderer.gammaFactor : 1.0;

  const customExtensions = "";

  const customDefines = generateDefines(defines);

  const program = gl.createProgram();

  let prefixVertex, prefixFragment;
  const versionString = "#version 300 es\n";

  if (parameters.isRawShaderMaterial) {
    prefixVertex = [customDefines].filter(filterEmptyLine).join("\n");

    if (prefixVertex.length > 0) {
      prefixVertex += "\n";
    }

    prefixFragment = [customExtensions, customDefines]
      .filter(filterEmptyLine)
      .join("\n");

    if (prefixFragment.length > 0) {
      prefixFragment += "\n";
    }
  } else {
    prefixVertex = [
      generatePrecision(parameters),

      "#define SHADER_NAME " + parameters.shaderName,

      customDefines,

      parameters.instancing ? "#define USE_INSTANCING" : "",
      parameters.instancingColor ? "#define USE_INSTANCING_COLOR" : "",

      parameters.supportsVertexTextures ? "#define VERTEX_TEXTURES" : "",

      "#define GAMMA_FACTOR " + gammaFactorDefine,

      "#define MAX_BONES " + parameters.maxBones,
      parameters.map ? "#define USE_MAP" : "",
      parameters.envMap ? "#define USE_ENVMAP" : "",
      parameters.envMap ? "#define " + envMapModeDefine : "",
      parameters.lightMap ? "#define USE_LIGHTMAP" : "",
      parameters.aoMap ? "#define USE_AOMAP" : "",
      parameters.emissiveMap ? "#define USE_EMISSIVEMAP" : "",
      parameters.bumpMap ? "#define USE_BUMPMAP" : "",
      parameters.normalMap ? "#define USE_NORMALMAP" : "",
      parameters.normalMap && parameters.objectSpaceNormalMap
        ? "#define OBJECTSPACE_NORMALMAP"
        : "",
      parameters.normalMap && parameters.tangentSpaceNormalMap
        ? "#define TANGENTSPACE_NORMALMAP"
        : "",

      parameters.clearcoatMap ? "#define USE_CLEARCOATMAP" : "",
      parameters.clearcoatRoughnessMap
        ? "#define USE_CLEARCOAT_ROUGHNESSMAP"
        : "",
      parameters.clearcoatNormalMap ? "#define USE_CLEARCOAT_NORMALMAP" : "",
      parameters.displacementMap && parameters.supportsVertexTextures
        ? "#define USE_DISPLACEMENTMAP"
        : "",
      parameters.specularMap ? "#define USE_SPECULARMAP" : "",
      parameters.roughnessMap ? "#define USE_ROUGHNESSMAP" : "",
      parameters.metalnessMap ? "#define USE_METALNESSMAP" : "",
      parameters.alphaMap ? "#define USE_ALPHAMAP" : "",
      parameters.transmissionMap ? "#define USE_TRANSMISSIONMAP" : "",

      parameters.vertexTangents ? "#define USE_TANGENT" : "",
      parameters.vertexColors ? "#define USE_COLOR" : "",
      parameters.vertexUvs ? "#define USE_UV" : "",
      parameters.uvsVertexOnly ? "#define UVS_VERTEX_ONLY" : "",

      parameters.flatShading ? "#define FLAT_SHADED" : "",

      parameters.useVertexTexture ? "#define BONE_TEXTURE" : "",

      parameters.doubleSided ? "#define DOUBLE_SIDED" : "",
      parameters.flipSided ? "#define FLIP_SIDED" : "",

      parameters.shadowMapEnabled ? "#define USE_SHADOWMAP" : "",
      parameters.shadowMapEnabled ? "#define " + shadowMapTypeDefine : "",

      parameters.sizeAttenuation ? "#define USE_SIZEATTENUATION" : "",

      parameters.logarithmicDepthBuffer ? "#define USE_LOGDEPTHBUF" : "",
      parameters.logarithmicDepthBuffer && parameters.rendererExtensionFragDepth
        ? "#define USE_LOGDEPTHBUF_EXT"
        : "",

      "uniform mat4 modelMatrix;",
      "uniform mat4 modelViewMatrix;",
      "uniform mat4 projectionMatrix;",
      "uniform mat4 viewMatrix;",
      "uniform mat3 normalMatrix;",
      "uniform vec3 cameraPosition;",
      "uniform bool isOrthographic;",

      "#ifdef USE_INSTANCING",

      "  attribute mat4 instanceMatrix;",

      "#endif",

      "#ifdef USE_INSTANCING_COLOR",

      "  attribute vec3 instanceColor;",

      "#endif",

      "attribute vec3 position;",
      "attribute vec3 normal;",
      "attribute vec2 uv;",

      "#ifdef USE_TANGENT",

      "  attribute vec4 tangent;",

      "#endif",

      "#ifdef USE_COLOR",

      "  attribute vec3 color;",

      "#endif",
      "\n",
    ]
      .filter(filterEmptyLine)
      .join("\n");

    prefixFragment = [
      customExtensions,

      generatePrecision(parameters),

      "#define SHADER_NAME " + parameters.shaderName,

      customDefines,

      parameters.alphaTest
        ? "#define ALPHATEST " +
          parameters.alphaTest +
          (parameters.alphaTest % 1 ? "" : ".0")
        : "", // add '.0' if integer

      "#define GAMMA_FACTOR " + gammaFactorDefine,

      parameters.map ? "#define USE_MAP" : "",
      parameters.matcap ? "#define USE_MATCAP" : "",
      parameters.envMap ? "#define USE_ENVMAP" : "",
      parameters.envMap ? "#define " + envMapTypeDefine : "",
      parameters.envMap ? "#define " + envMapModeDefine : "",
      parameters.envMap ? "#define " + envMapBlendingDefine : "",
      parameters.lightMap ? "#define USE_LIGHTMAP" : "",
      parameters.aoMap ? "#define USE_AOMAP" : "",
      parameters.emissiveMap ? "#define USE_EMISSIVEMAP" : "",
      parameters.bumpMap ? "#define USE_BUMPMAP" : "",
      parameters.normalMap ? "#define USE_NORMALMAP" : "",
      parameters.normalMap && parameters.objectSpaceNormalMap
        ? "#define OBJECTSPACE_NORMALMAP"
        : "",
      parameters.normalMap && parameters.tangentSpaceNormalMap
        ? "#define TANGENTSPACE_NORMALMAP"
        : "",
      parameters.clearcoatMap ? "#define USE_CLEARCOATMAP" : "",
      parameters.clearcoatRoughnessMap
        ? "#define USE_CLEARCOAT_ROUGHNESSMAP"
        : "",
      parameters.clearcoatNormalMap ? "#define USE_CLEARCOAT_NORMALMAP" : "",
      parameters.specularMap ? "#define USE_SPECULARMAP" : "",
      parameters.roughnessMap ? "#define USE_ROUGHNESSMAP" : "",
      parameters.metalnessMap ? "#define USE_METALNESSMAP" : "",
      parameters.alphaMap ? "#define USE_ALPHAMAP" : "",

      parameters.sheen ? "#define USE_SHEEN" : "",
      parameters.transmissionMap ? "#define USE_TRANSMISSIONMAP" : "",

      parameters.vertexTangents ? "#define USE_TANGENT" : "",
      parameters.vertexColors || parameters.instancingColor
        ? "#define USE_COLOR"
        : "",
      parameters.vertexUvs ? "#define USE_UV" : "",
      parameters.uvsVertexOnly ? "#define UVS_VERTEX_ONLY" : "",

      parameters.gradientMap ? "#define USE_GRADIENTMAP" : "",

      parameters.flatShading ? "#define FLAT_SHADED" : "",

      parameters.doubleSided ? "#define DOUBLE_SIDED" : "",
      parameters.flipSided ? "#define FLIP_SIDED" : "",

      parameters.shadowMapEnabled ? "#define USE_SHADOWMAP" : "",
      parameters.shadowMapEnabled ? "#define " + shadowMapTypeDefine : "",

      parameters.premultipliedAlpha ? "#define PREMULTIPLIED_ALPHA" : "",

      parameters.physicallyCorrectLights
        ? "#define PHYSICALLY_CORRECT_LIGHTS"
        : "",

      parameters.logarithmicDepthBuffer ? "#define USE_LOGDEPTHBUF" : "",
      parameters.logarithmicDepthBuffer && parameters.rendererExtensionFragDepth
        ? "#define USE_LOGDEPTHBUF_EXT"
        : "",

      (parameters.extensionShaderTextureLOD || parameters.envMap) &&
      parameters.rendererExtensionShaderTextureLod
        ? "#define TEXTURE_LOD_EXT"
        : "",

      "uniform mat4 viewMatrix;",
      "uniform vec3 cameraPosition;",
      "uniform bool isOrthographic;",

      parameters.dithering ? "#define DITHERING" : "",

      ShaderChunk["encodings_pars_fragment"], // this code is required here because it is used by the various encoding/decoding function defined below
      parameters.map
        ? getTexelDecodingFunction("mapTexelToLinear", parameters.mapEncoding)
        : "",
      parameters.matcap
        ? getTexelDecodingFunction(
            "matcapTexelToLinear",
            parameters.matcapEncoding
          )
        : "",
      parameters.envMap
        ? getTexelDecodingFunction(
            "envMapTexelToLinear",
            parameters.envMapEncoding
          )
        : "",
      parameters.emissiveMap
        ? getTexelDecodingFunction(
            "emissiveMapTexelToLinear",
            parameters.emissiveMapEncoding
          )
        : "",
      parameters.lightMap
        ? getTexelDecodingFunction(
            "lightMapTexelToLinear",
            parameters.lightMapEncoding
          )
        : "",
      getTexelEncodingFunction(
        "linearToOutputTexel",
        parameters.outputEncoding
      ),

      parameters.depthPacking
        ? "#define DEPTH_PACKING " + parameters.depthPacking
        : "",

      "\n",
    ]
      .filter(filterEmptyLine)
      .join("\n");
  }

  vertexShader = resolveIncludes(vertexShader);
  vertexShader = replaceLightNums(vertexShader, parameters);
  vertexShader = replaceClippingPlaneNums(vertexShader, parameters);

  fragmentShader = resolveIncludes(fragmentShader);
  fragmentShader = replaceLightNums(fragmentShader, parameters);
  fragmentShader = replaceClippingPlaneNums(fragmentShader, parameters);

  vertexShader = unrollLoops(vertexShader);
  fragmentShader = unrollLoops(fragmentShader);

  if (parameters.isRawShaderMaterial !== true) {
    // GLSL 3.0 conversion for built-in materials and ShaderMaterial

    prefixVertex =
      [
        "#define attribute in",
        "#define varying out",
        "#define texture2D texture",
      ].join("\n") +
      "\n" +
      prefixVertex;

    prefixFragment =
      [
        "#define varying in",
        "#define gl_FragDepthEXT gl_FragDepth",
        "layout(location = 0) out highp vec4 pc_fragColor;",
        "#define gl_FragColor pc_fragColor",
        "#define texture2D texture",
        "#define textureCube texture",
        "#define texture2DProj textureProj",
        "#define texture2DLodEXT textureLod",
        "#define texture2DProjLodEXT textureProjLod",
        "#define textureCubeLodEXT textureLod",
        "#define texture2DGradEXT textureGrad",
        "#define texture2DProjGradEXT textureProjGrad",
        "#define textureCubeGradEXT textureGrad",
      ].join("\n") +
      "\n" +
      prefixFragment;
  }

  const vertexGlsl = versionString + prefixVertex + vertexShader;
  const fragmentGlsl = versionString + prefixFragment + fragmentShader;

  const glVertexShader = WebGLShader(gl, 35633, vertexGlsl);
  const glFragmentShader = WebGLShader(gl, 35632, fragmentGlsl);

  gl.attachShader(program, glVertexShader);
  gl.attachShader(program, glFragmentShader);

  // Force a particular attribute to index 0.

  if (parameters.index0AttributeName !== undefined) {
    gl.bindAttribLocation(program, 0, parameters.index0AttributeName);
  }

  gl.linkProgram(program);

  // check for link errors
  if (renderer.debug.checkShaderErrors) {
    const programLog = gl.getProgramInfoLog(program).trim();
    const vertexLog = gl.getShaderInfoLog(glVertexShader).trim();
    const fragmentLog = gl.getShaderInfoLog(glFragmentShader).trim();

    let runnable = true;
    let haveDiagnostics = true;

    if (gl.getProgramParameter(program, 35714) === false) {
      runnable = false;

      const vertexErrors = getShaderErrors(gl, glVertexShader, "vertex");
      const fragmentErrors = getShaderErrors(gl, glFragmentShader, "fragment");

      console.error("Error in shader: " + parameters.shaderName);
      console.error(
        "THREE.WebGLProgram: shader error: ",
        gl.getError(),
        "\n[35715]:\n",
        gl.getProgramParameter(program, 35715),
        "\n[Log]:\n",
        programLog,
        "\n[Vertex Errors]:\n",
        vertexErrors,
        "\n[Fragment Errors]:\n",
        fragmentErrors
      );
    } else if (programLog !== "") {
      console.warn("THREE.WebGLProgram: gl.getProgramInfoLog()", programLog);
    } else if (vertexLog === "" || fragmentLog === "") {
      haveDiagnostics = false;
    }

    if (haveDiagnostics) {
      this.diagnostics = {
        runnable: runnable,

        programLog: programLog,

        vertexShader: {
          log: vertexLog,
          prefix: prefixVertex,
        },

        fragmentShader: {
          log: fragmentLog,
          prefix: prefixFragment,
        },
      };
    }
  }

  console.info("Compiled shader: ", parameters.shaderName);

  // Clean up
  gl.deleteShader(glVertexShader);
  gl.deleteShader(glFragmentShader);

  // set up caching for uniform locations

  let cachedUniforms;

  this.getUniforms = function () {
    if (cachedUniforms === undefined) {
      cachedUniforms = new WebGLUniforms(gl, program);
    }

    return cachedUniforms;
  };

  // set up caching for attribute locations

  let cachedAttributes;

  this.getAttributes = function () {
    if (cachedAttributes === undefined) {
      cachedAttributes = fetchAttributeLocations(gl, program);
    }

    return cachedAttributes;
  };

  // free resource

  this.destroy = function () {
    bindingStates.releaseStatesOfProgram(this);

    gl.deleteProgram(program);
    this.program = undefined;
  };

  //

  this.name = parameters.shaderName;
  this.id = programIdCount++;
  this.cacheKey = cacheKey;
  this.usedTimes = 1;
  this.program = program;
  this.vertexShader = glVertexShader;
  this.fragmentShader = glFragmentShader;

  return this;
}

function WebGLPrograms(renderer, capabilities, bindingStates, clipping) {
  const programs = [];

  const isWebGL2 = capabilities.isWebGL2;
  const logarithmicDepthBuffer = capabilities.logarithmicDepthBuffer;
  const floatVertexTextures = capabilities.floatVertexTextures;
  const vertexTextures = capabilities.vertexTextures;

  let precision = capabilities.precision;

  const shaderIDs = {
    MeshDepthMaterial: "depth",
    MeshDistanceMaterial: "distanceRGBA",
    MeshNormalMaterial: "normal",
    MeshBasicMaterial: "basic",
    MeshLambertMaterial: "lambert",
    MeshStandardMaterial: "physical",
    MeshMatcapMaterial: "matcap",
    LineBasicMaterial: "basic",
    PointsMaterial: "points",
    ShadowMaterial: "shadow",
    SpriteMaterial: "sprite",
  };

  const parameterNames = [
    "precision",
    "isWebGL2",
    "supportsVertexTextures",
    "outputEncoding",
    "instancing",
    "instancingColor",
    "map",
    "mapEncoding",
    "matcap",
    "matcapEncoding",
    "envMap",
    "envMapMode",
    "envMapEncoding",
    "envMapCubeUV",
    "lightMap",
    "lightMapEncoding",
    "aoMap",
    "emissiveMap",
    "emissiveMapEncoding",
    "bumpMap",
    "normalMap",
    "objectSpaceNormalMap",
    "tangentSpaceNormalMap",
    "clearcoatMap",
    "clearcoatRoughnessMap",
    "clearcoatNormalMap",
    "displacementMap",
    "specularMap",
    "roughnessMap",
    "metalnessMap",
    "gradientMap",
    "alphaMap",
    "combine",
    "vertexColors",
    "vertexTangents",
    "vertexUvs",
    "uvsVertexOnly",
    "flatShading",
    "sizeAttenuation",
    "logarithmicDepthBuffer",
    "maxBones",
    "useVertexTexture",
    "premultipliedAlpha",
    "numDirLights",
    "numPointLights",
    "numSpotLights",
    "numHemiLights",
    "numRectAreaLights",
    "numDirLightShadows",
    "numPointLightShadows",
    "numSpotLightShadows",
    "shadowMapEnabled",
    "shadowMapType",
    "physicallyCorrectLights",
    "alphaTest",
    "doubleSided",
    "flipSided",
    "numClippingPlanes",
    "numClipIntersection",
    "depthPacking",
    "dithering",
    "sheen",
    "transmissionMap",
  ];

  function getTextureEncodingFromMap(map) {
    let encoding;

    if (map && map.isTexture) {
      encoding = map.encoding;
    } else if (map && map.isWebGLRenderTarget) {
      console.warn(
        "THREE.WebGLPrograms.getTextureEncodingFromMap: don't use render targets as textures. Use their .texture property instead."
      );
      encoding = map.texture.encoding;
    } else {
      encoding = LinearEncoding;
    }

    return encoding;
  }

  function getParameters(material, lights, shadows, scene, object) {
    const shaderID = shaderIDs[material.type];

    // heuristics to create shader parameters according to lights in the scene
    // (not to blow over maxLights budget)

    const maxBones = 0;

    if (material.precision !== null) {
      precision = capabilities.getMaxPrecision(material.precision);

      if (precision !== material.precision) {
        console.warn(
          "THREE.WebGLProgram.getParameters:",
          material.precision,
          "not supported, using",
          precision,
          "instead."
        );
      }
    }

    let vertexShader, fragmentShader;

    if (shaderID) {
      const shader = ShaderLib[shaderID];

      vertexShader = shader.vertexShader;
      fragmentShader = shader.fragmentShader;
    } else {
      vertexShader = material.vertexShader;
      fragmentShader = material.fragmentShader;
    }

    const currentRenderTarget = renderer.getRenderTarget();

    const parameters = {
      isWebGL2: isWebGL2,

      shaderID: shaderID,
      shaderName: material.type,

      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      defines: material.defines,

      isRawShaderMaterial: material.isRawShaderMaterial === true,
      glslVersion: material.glslVersion,

      precision: precision,

      instancing: object.isInstancedMesh === true,
      instancingColor:
        object.isInstancedMesh === true && object.instanceColor !== null,

      supportsVertexTextures: vertexTextures,
      outputEncoding:
        currentRenderTarget !== null
          ? getTextureEncodingFromMap(currentRenderTarget.texture)
          : renderer.outputEncoding,
      map: !!material.map,
      mapEncoding: getTextureEncodingFromMap(material.map),
      matcap: !!material.matcap,
      matcapEncoding: getTextureEncodingFromMap(material.matcap),
      lightMap: !!material.lightMap,
      lightMapEncoding: getTextureEncodingFromMap(material.lightMap),
      aoMap: !!material.aoMap,
      emissiveMap: !!material.emissiveMap,
      emissiveMapEncoding: getTextureEncodingFromMap(material.emissiveMap),
      bumpMap: !!material.bumpMap,
      normalMap: !!material.normalMap,
      objectSpaceNormalMap: material.normalMapType === ObjectSpaceNormalMap,
      tangentSpaceNormalMap: material.normalMapType === TangentSpaceNormalMap,
      clearcoatMap: !!material.clearcoatMap,
      clearcoatRoughnessMap: !!material.clearcoatRoughnessMap,
      clearcoatNormalMap: !!material.clearcoatNormalMap,
      displacementMap: !!material.displacementMap,
      roughnessMap: !!material.roughnessMap,
      metalnessMap: !!material.metalnessMap,
      specularMap: !!material.specularMap,
      alphaMap: !!material.alphaMap,

      gradientMap: !!material.gradientMap,

      sheen: !!material.sheen,

      transmissionMap: !!material.transmissionMap,

      combine: material.combine,

      vertexTangents: material.normalMap && material.vertexTangents,
      vertexColors: material.vertexColors,
      vertexUvs:
        !!material.map ||
        !!material.bumpMap ||
        !!material.normalMap ||
        !!material.specularMap ||
        !!material.alphaMap ||
        !!material.emissiveMap ||
        !!material.roughnessMap ||
        !!material.metalnessMap ||
        !!material.clearcoatMap ||
        !!material.clearcoatRoughnessMap ||
        !!material.clearcoatNormalMap ||
        !!material.displacementMap ||
        !!material.transmissionMap,
      uvsVertexOnly:
        !(
          !!material.map ||
          !!material.bumpMap ||
          !!material.normalMap ||
          !!material.specularMap ||
          !!material.alphaMap ||
          !!material.emissiveMap ||
          !!material.roughnessMap ||
          !!material.metalnessMap ||
          !!material.clearcoatNormalMap ||
          !!material.transmissionMap
        ) && !!material.displacementMap,
      flatShading: material.flatShading,

      sizeAttenuation: material.sizeAttenuation,
      logarithmicDepthBuffer: logarithmicDepthBuffer,

      maxBones: maxBones,
      useVertexTexture: floatVertexTextures,

      numDirLights: lights.directional.length,
      numPointLights: lights.point.length,
      numSpotLights: lights.spot.length,
      numRectAreaLights: lights.rectArea.length,
      numHemiLights: lights.hemi.length,

      numDirLightShadows: lights.directionalShadowMap.length,
      numPointLightShadows: lights.pointShadowMap.length,
      numSpotLightShadows: lights.spotShadowMap.length,

      numClippingPlanes: clipping.numPlanes,
      numClipIntersection: clipping.numIntersection,

      dithering: material.dithering,

      shadowMapEnabled: renderer.shadowMap.enabled && shadows.length > 0,
      shadowMapType: renderer.shadowMap.type,

      physicallyCorrectLights: renderer.physicallyCorrectLights,

      premultipliedAlpha: material.premultipliedAlpha,

      alphaTest: material.alphaTest,
      doubleSided: material.side === DoubleSide,
      flipSided: material.side === BackSide,

      depthPacking: material.depthPacking ?? false,

      index0AttributeName: material.index0AttributeName,

      extensionDerivatives:
        material.extensions && material.extensions.derivatives,
      extensionFragDepth: material.extensions && material.extensions.fragDepth,
      extensionDrawBuffers:
        material.extensions && material.extensions.drawBuffers,
      extensionShaderTextureLOD:
        material.extensions && material.extensions.shaderTextureLOD,

      rendererExtensionFragDepth: true,
      rendererExtensionDrawBuffers: true,
      rendererExtensionShaderTextureLod: true,

      customProgramCacheKey: material.customProgramCacheKey(),
    };

    return parameters;
  }

  function getProgramCacheKey(parameters) {
    const array = [];

    if (parameters.shaderID) {
      array.push(parameters.shaderID);
    } else {
      array.push(parameters.fragmentShader);
      array.push(parameters.vertexShader);
    }

    if (parameters.defines !== undefined) {
      for (const name in parameters.defines) {
        array.push(name);
        array.push(parameters.defines[name]);
      }
    }

    if (parameters.isRawShaderMaterial === false) {
      for (let i = 0; i < parameterNames.length; i++) {
        array.push(parameters[parameterNames[i]]);
      }

      array.push(renderer.outputEncoding);
      array.push(renderer.gammaFactor);
    }

    array.push(parameters.customProgramCacheKey);

    return array.join();
  }

  function getUniforms(material) {
    const shaderID = shaderIDs[material.type];
    let uniforms;

    if (shaderID) {
      const shader = ShaderLib[shaderID];
      uniforms = cloneUniforms(shader.uniforms);
    } else {
      uniforms = material.uniforms;
    }

    return uniforms;
  }

  function acquireProgram(parameters, cacheKey) {
    let program;

    // Check if code has been already compiled
    for (let p = 0, pl = programs.length; p < pl; p++) {
      const preexistingProgram = programs[p];

      if (preexistingProgram.cacheKey === cacheKey) {
        program = preexistingProgram;
        ++program.usedTimes;

        break;
      }
    }

    if (program === undefined) {
      program = new WebGLProgram(renderer, cacheKey, parameters, bindingStates);
      programs.push(program);
    }

    return program;
  }

  function releaseProgram(program) {
    if (--program.usedTimes === 0) {
      // Remove from unordered set
      const i = programs.indexOf(program);
      programs[i] = programs[programs.length - 1];
      programs.pop();

      // Free WebGL resources
      program.destroy();
    }
  }

  return {
    getParameters: getParameters,
    getProgramCacheKey: getProgramCacheKey,
    getUniforms: getUniforms,
    acquireProgram: acquireProgram,
    releaseProgram: releaseProgram,
    // Exposed for resource monitoring & error feedback via renderer.info:
    programs: programs,
  };
}

function WebGLProperties() {
  let properties = new WeakMap();

  function get(object) {
    let map = properties.get(object);

    if (map === undefined) {
      map = {};
      properties.set(object, map);
    }

    return map;
  }

  function remove(object) {
    properties.delete(object);
  }

  function update(object, key, value) {
    properties.get(object)[key] = value;
  }

  function dispose() {
    properties = new WeakMap();
  }

  return {
    get: get,
    remove: remove,
    update: update,
    dispose: dispose,
  };
}

function painterSortStable(a, b) {
  if (a.groupOrder !== b.groupOrder) {
    return a.groupOrder - b.groupOrder;
  } else if (a.renderOrder !== b.renderOrder) {
    return a.renderOrder - b.renderOrder;
  } else if (a.program !== b.program) {
    return a.program.id - b.program.id;
  } else if (a.material.id !== b.material.id) {
    return a.material.id - b.material.id;
  } else if (a.z !== b.z) {
    return a.z - b.z;
  } else {
    return a.id - b.id;
  }
}

function reversePainterSortStable(a, b) {
  if (a.groupOrder !== b.groupOrder) {
    return a.groupOrder - b.groupOrder;
  } else if (a.renderOrder !== b.renderOrder) {
    return a.renderOrder - b.renderOrder;
  } else if (a.z !== b.z) {
    return b.z - a.z;
  } else {
    return a.id - b.id;
  }
}

function WebGLRenderList(properties) {
  const renderItems = [];
  let renderItemsIndex = 0;

  const opaque = [];
  const transparent = [];

  const defaultProgram = { id: -1 };

  function init() {
    renderItemsIndex = 0;

    opaque.length = 0;
    transparent.length = 0;
  }

  function getNextRenderItem(object, geometry, material, groupOrder, z, group) {
    let renderItem = renderItems[renderItemsIndex];
    const materialProperties = properties.get(material);

    if (renderItem === undefined) {
      renderItem = {
        id: object.id,
        object: object,
        geometry: geometry,
        material: material,
        program: materialProperties.program || defaultProgram,
        groupOrder: groupOrder,
        renderOrder: object.renderOrder,
        z: z,
        group: group,
      };

      renderItems[renderItemsIndex] = renderItem;
    } else {
      renderItem.id = object.id;
      renderItem.object = object;
      renderItem.geometry = geometry;
      renderItem.material = material;
      renderItem.program = materialProperties.program || defaultProgram;
      renderItem.groupOrder = groupOrder;
      renderItem.renderOrder = object.renderOrder;
      renderItem.z = z;
      renderItem.group = group;
    }

    renderItemsIndex++;

    return renderItem;
  }

  function push(object, geometry, material, groupOrder, z, group) {
    const renderItem = getNextRenderItem(
      object,
      geometry,
      material,
      groupOrder,
      z,
      group
    );

    (material.transparent === true ? transparent : opaque).push(renderItem);
  }

  function unshift(object, geometry, material, groupOrder, z, group) {
    const renderItem = getNextRenderItem(
      object,
      geometry,
      material,
      groupOrder,
      z,
      group
    );

    (material.transparent === true ? transparent : opaque).unshift(renderItem);
  }

  function sort(customOpaqueSort, customTransparentSort) {
    if (opaque.length > 1) opaque.sort(customOpaqueSort || painterSortStable);
    if (transparent.length > 1)
      transparent.sort(customTransparentSort || reversePainterSortStable);
  }

  function finish() {
    // Clear references from inactive renderItems in the list

    for (let i = renderItemsIndex, il = renderItems.length; i < il; i++) {
      const renderItem = renderItems[i];

      if (renderItem.id === null) break;

      renderItem.id = null;
      renderItem.object = null;
      renderItem.geometry = null;
      renderItem.material = null;
      renderItem.program = null;
      renderItem.group = null;
    }
  }

  return {
    opaque: opaque,
    transparent: transparent,

    init: init,
    push: push,
    unshift: unshift,
    finish: finish,

    sort: sort,
  };
}

function WebGLRenderLists(properties) {
  let lists = new WeakMap();

  function get(scene, camera) {
    const cameras = lists.get(scene);
    let list;

    if (cameras === undefined) {
      list = new WebGLRenderList(properties);
      lists.set(scene, new WeakMap());
      lists.get(scene).set(camera, list);
    } else {
      list = cameras.get(camera);
      if (list === undefined) {
        list = new WebGLRenderList(properties);
        cameras.set(camera, list);
      }
    }

    return list;
  }

  function dispose() {
    lists = new WeakMap();
  }

  return {
    get: get,
    dispose: dispose,
  };
}

function UniformsCache() {
  const lights = {};

  return {
    get: function (light) {
      if (lights[light.id] !== undefined) {
        return lights[light.id];
      }

      let uniforms;

      switch (light.type) {
        case "DirectionalLight":
          uniforms = {
            direction: new Vector3(),
            color: new Color(),
          };
          break;

        case "SpotLight":
          uniforms = {
            position: new Vector3(),
            direction: new Vector3(),
            color: new Color(),
            distance: 0,
            coneCos: 0,
            penumbraCos: 0,
            decay: 0,
          };
          break;

        case "PointLight":
          uniforms = {
            position: new Vector3(),
            color: new Color(),
            distance: 0,
            decay: 0,
          };
          break;

        case "HemisphereLight":
          uniforms = {
            direction: new Vector3(),
            skyColor: new Color(),
            groundColor: new Color(),
          };
          break;

        case "RectAreaLight":
          uniforms = {
            color: new Color(),
            position: new Vector3(),
            halfWidth: new Vector3(),
            halfHeight: new Vector3(),
          };
          break;
      }

      lights[light.id] = uniforms;

      return uniforms;
    },
  };
}

let nextVersion = 0;

function WebGLLights() {
  const cache = new UniformsCache();

  const state = {
    version: 0,

    hash: {
      directionalLength: -1,
      pointLength: -1,
      spotLength: -1,
      rectAreaLength: -1,
      hemiLength: -1,

      numDirectionalShadows: -1,
      numPointShadows: -1,
      numSpotShadows: -1,
    },

    ambient: [0, 0, 0],
    probe: [],
    directional: [],
    directionalShadow: [],
    directionalShadowMap: [],
    directionalShadowMatrix: [],
    spot: [],
    spotShadow: [],
    spotShadowMap: [],
    spotShadowMatrix: [],
    rectArea: [],
    rectAreaLTC1: null,
    rectAreaLTC2: null,
    point: [],
    pointShadow: [],
    pointShadowMap: [],
    pointShadowMatrix: [],
    hemi: [],
  };

  for (let i = 0; i < 9; i++) state.probe.push(new Vector3());

  const vector3 = new Vector3();

  function setup(lights) {
    let r = 0,
      g = 0,
      b = 0;

    for (let i = 0; i < 9; i++) state.probe[i].set(0, 0, 0);

    let directionalLength = 0;
    let pointLength = 0;
    let spotLength = 0;
    let rectAreaLength = 0;
    let hemiLength = 0;

    let numDirectionalShadows = 0;
    let numPointShadows = 0;
    let numSpotShadows = 0;

    for (let i = 0, l = lights.length; i < l; i++) {
      const light = lights[i];

      const color = light.color;
      const intensity = light.intensity;
      const distance = light.distance;

      if (light.isDirectionalLight) {
        const uniforms = cache.get(light);

        uniforms.color.copy(light.color).multiplyScalar(light.intensity);

        state.directional[directionalLength] = uniforms;

        directionalLength++;
      } else if (light.isSpotLight) {
        const uniforms = cache.get(light);

        uniforms.position.setFromMatrixPosition(light.matrixWorld);

        uniforms.color.copy(color).multiplyScalar(intensity);
        uniforms.distance = distance;

        uniforms.coneCos = Math.cos(light.angle);
        uniforms.penumbraCos = Math.cos(light.angle * (1 - light.penumbra));
        uniforms.decay = light.decay;

        state.spot[spotLength] = uniforms;

        spotLength++;
      } else if (light.isPointLight) {
        const uniforms = cache.get(light);

        uniforms.color.copy(light.color).multiplyScalar(light.intensity);
        uniforms.distance = light.distance;
        uniforms.decay = light.decay;

        state.point[pointLength] = uniforms;

        pointLength++;
      }
    }

    state.ambient[0] = r;
    state.ambient[1] = g;
    state.ambient[2] = b;

    const hash = state.hash;

    if (
      hash.directionalLength !== directionalLength ||
      hash.pointLength !== pointLength ||
      hash.spotLength !== spotLength ||
      hash.rectAreaLength !== rectAreaLength ||
      hash.hemiLength !== hemiLength ||
      hash.numDirectionalShadows !== numDirectionalShadows ||
      hash.numPointShadows !== numPointShadows ||
      hash.numSpotShadows !== numSpotShadows
    ) {
      state.directional.length = directionalLength;
      state.spot.length = spotLength;
      state.rectArea.length = rectAreaLength;
      state.point.length = pointLength;
      state.hemi.length = hemiLength;

      state.directionalShadow.length = numDirectionalShadows;
      state.directionalShadowMap.length = numDirectionalShadows;
      state.pointShadow.length = numPointShadows;
      state.pointShadowMap.length = numPointShadows;
      state.spotShadow.length = numSpotShadows;
      state.spotShadowMap.length = numSpotShadows;
      state.directionalShadowMatrix.length = numDirectionalShadows;
      state.pointShadowMatrix.length = numPointShadows;
      state.spotShadowMatrix.length = numSpotShadows;

      hash.directionalLength = directionalLength;
      hash.pointLength = pointLength;
      hash.spotLength = spotLength;
      hash.rectAreaLength = rectAreaLength;
      hash.hemiLength = hemiLength;

      hash.numDirectionalShadows = numDirectionalShadows;
      hash.numPointShadows = numPointShadows;
      hash.numSpotShadows = numSpotShadows;

      state.version = nextVersion++;
    }
  }

  function setupView(lights, camera) {
    let directionalLength = 0;
    let pointLength = 0;
    let spotLength = 0;
    const viewMatrix = camera.matrixWorldInverse;

    for (let i = 0, l = lights.length; i < l; i++) {
      const light = lights[i];

      if (light.isDirectionalLight) {
        const uniforms = state.directional[directionalLength];

        uniforms.direction.setFromMatrixPosition(light.matrixWorld);
        vector3.setFromMatrixPosition(light.target.matrixWorld);
        uniforms.direction.sub(vector3);
        uniforms.direction.transformDirection(viewMatrix);

        directionalLength++;
      } else if (light.isSpotLight) {
        const uniforms = state.spot[spotLength];

        uniforms.position.setFromMatrixPosition(light.matrixWorld);
        uniforms.position.applyMatrix4(viewMatrix);

        uniforms.direction.setFromMatrixPosition(light.matrixWorld);
        vector3.setFromMatrixPosition(light.target.matrixWorld);
        uniforms.direction.sub(vector3);
        uniforms.direction.transformDirection(viewMatrix);

        spotLength++;
      } else if (light.isPointLight) {
        const uniforms = state.point[pointLength];

        uniforms.position.setFromMatrixPosition(light.matrixWorld);
        uniforms.position.applyMatrix4(viewMatrix);

        pointLength++;
      }
    }
  }

  return {
    setup: setup,
    setupView: setupView,
    state: state,
  };
}

function WebGLRenderState() {
  const lights = new WebGLLights();

  const lightsArray = [];
  const shadowsArray = [];

  function init() {
    lightsArray.length = 0;
    shadowsArray.length = 0;
  }

  function pushLight(light) {
    lightsArray.push(light);
  }

  function pushShadow(shadowLight) {
    shadowsArray.push(shadowLight);
  }

  function setupLights() {
    lights.setup(lightsArray);
  }

  function setupLightsView(camera) {
    lights.setupView(lightsArray, camera);
  }

  const state = {
    lightsArray: lightsArray,
    shadowsArray: shadowsArray,

    lights: lights,
  };

  return {
    init: init,
    state: state,
    setupLights: setupLights,
    setupLightsView: setupLightsView,

    pushLight: pushLight,
    pushShadow: pushShadow,
  };
}

function WebGLRenderStates() {
  let renderStates = new WeakMap();

  function get(scene, renderCallDepth = 0) {
    let renderState;

    if (renderStates.has(scene) === false) {
      renderState = new WebGLRenderState();
      renderStates.set(scene, [renderState]);
    } else {
      if (renderCallDepth >= renderStates.get(scene).length) {
        renderState = new WebGLRenderState();
        renderStates.get(scene).push(renderState);
      } else {
        renderState = renderStates.get(scene)[renderCallDepth];
      }
    }

    return renderState;
  }

  function dispose() {
    renderStates = new WeakMap();
  }

  return {
    get: get,
    dispose: dispose,
  };
}

const vsm_frag =
  "uniform sampler2D shadow_pass;\nuniform vec2 resolution;\nuniform float radius;\n#include <packing>\nvoid main() {\n\tfloat mean = 0.0;\n\tfloat squared_mean = 0.0;\n\tfloat depth = unpackRGBAToDepth( texture2D( shadow_pass, ( gl_FragCoord.xy ) / resolution ) );\n\tfor ( float i = -1.0; i < 1.0 ; i += SAMPLE_RATE) {\n\t\t#ifdef HORIZONTAL_PASS\n\t\t\tvec2 distribution = unpackRGBATo2Half( texture2D( shadow_pass, ( gl_FragCoord.xy + vec2( i, 0.0 ) * radius ) / resolution ) );\n\t\t\tmean += distribution.x;\n\t\t\tsquared_mean += distribution.y * distribution.y + distribution.x * distribution.x;\n\t\t#else\n\t\t\tfloat depth = unpackRGBAToDepth( texture2D( shadow_pass, ( gl_FragCoord.xy + vec2( 0.0, i ) * radius ) / resolution ) );\n\t\t\tmean += depth;\n\t\t\tsquared_mean += depth * depth;\n\t\t#endif\n\t}\n\tmean = mean * HALF_SAMPLE_RATE;\n\tsquared_mean = squared_mean * HALF_SAMPLE_RATE;\n\tfloat std_dev = sqrt( squared_mean - mean * mean );\n\tgl_FragColor = pack2HalfToRGBA( vec2( mean, std_dev ) );\n}";

const vsm_vert = "void main() {\n\tgl_Position = vec4( position, 1.0 );\n}";

const shadowSide = { 0: BackSide, 1: FrontSide, 2: DoubleSide };

class WebGLShadowMap {
  #frustum = new Frustum();
  #viewPort = new Vector4();
  #renderer;
  #objects;

  #depthMaterials = [];
  #distanceMaterials = [];
  #materialCache = {};

  enabled = false;
  autoUpdate = true;
  needsUpdate = false;
  type = PCFShadowMap;

  constructor(_renderer, _objects, maxTextureSize) {
    this.#renderer = _renderer;
    this.#objects = _objects;
    const _shadowMapSize = new Vector2(),
      _viewportSize = new Vector2();

    const shadowMaterialVertical = new ShaderMaterial({
      defines: {
        SAMPLE_RATE: 2.0 / 8.0,
        HALF_SAMPLE_RATE: 1.0 / 8.0,
      },

      uniforms: {
        shadow_pass: { value: null },
        resolution: { value: new Vector2() },
        radius: { value: 4.0 },
      },

      vertexShader: vsm_vert,

      fragmentShader: vsm_frag,
    });

    const shadowMaterialHorizontal = shadowMaterialVertical.clone();
    shadowMaterialHorizontal.defines.HORIZONTAL_PASS = 1;

    const fullScreenTri = new BufferGeometry();
    fullScreenTri.setAttribute(
      "position",
      new BufferAttribute(
        new Float32Array([-1, -1, 0.5, 3, -1, 0.5, -1, 3, 0.5]),
        3
      )
    );

    const fullScreenMesh = new Mesh(fullScreenTri, shadowMaterialVertical);

    const scope = this;

    this.render = function (lights, scene, camera) {
      if (scope.enabled === false) return;
      if (scope.autoUpdate === false && scope.needsUpdate === false) return;

      if (lights.length === 0) return;

      const currentRenderTarget = _renderer.getRenderTarget();
      const activeCubeFace = _renderer.getActiveCubeFace();
      const activeMipmapLevel = _renderer.getActiveMipmapLevel();

      const _state = _renderer.state;

      // Set GL state for depth map.
      _state.setBlending(NoBlending);
      _state.buffers.color.setClear(1, 1, 1, 1);
      _state.buffers.depth.setTest(true);
      _state.setScissorTest(false);

      // render depth map
      for (let i = 0, il = lights.length; i < il; i++) {
        const light = lights[i];
        const shadow = light.shadow;

        if (shadow === undefined) {
          console.warn("THREE.WebGLShadowMap:", light, "has no shadow.");
          continue;
        }

        if (shadow.autoUpdate === false && shadow.needsUpdate === false)
          continue;

        _shadowMapSize.copy(shadow.mapSize);

        const shadowFrameExtents = shadow.getFrameExtents();

        _shadowMapSize.multiply(shadowFrameExtents);

        _viewportSize.copy(shadow.mapSize);

        if (
          _shadowMapSize.x > maxTextureSize ||
          _shadowMapSize.y > maxTextureSize
        ) {
          if (_shadowMapSize.x > maxTextureSize) {
            _viewportSize.x = Math.floor(maxTextureSize / shadowFrameExtents.x);
            _shadowMapSize.x = _viewportSize.x * shadowFrameExtents.x;
            shadow.mapSize.x = _viewportSize.x;
          }

          if (_shadowMapSize.y > maxTextureSize) {
            _viewportSize.y = Math.floor(maxTextureSize / shadowFrameExtents.y);
            _shadowMapSize.y = _viewportSize.y * shadowFrameExtents.y;
            shadow.mapSize.y = _viewportSize.y;
          }
        }

        if (
          shadow.map === null &&
          !shadow.isPointLightShadow &&
          this.type === VSMShadowMap
        ) {
          const pars = {
            minFilter: LinearFilter,
            magFilter: LinearFilter,
            format: RGBAFormat,
          };

          shadow.map = new WebGLRenderTarget(
            _shadowMapSize.x,
            _shadowMapSize.y,
            pars
          );
          shadow.map.texture.name = light.name + ".shadowMap";

          shadow.mapPass = new WebGLRenderTarget(
            _shadowMapSize.x,
            _shadowMapSize.y,
            pars
          );

          shadow.camera.updateProjectionMatrix();
        }

        if (shadow.map === null) {
          const pars = {
            minFilter: NearestFilter,
            magFilter: NearestFilter,
            format: RGBAFormat,
          };

          shadow.map = new WebGLRenderTarget(
            _shadowMapSize.x,
            _shadowMapSize.y,
            pars
          );
          shadow.map.texture.name = light.name + ".shadowMap";

          shadow.camera.updateProjectionMatrix();
        }

        this.#renderer.setRenderTarget(shadow.map);
        this.#renderer.clear();

        const viewportCount = shadow.getViewportCount();

        for (let vp = 0; vp < viewportCount; vp++) {
          const viewport = shadow.getViewport(vp);

          this.#viewPort.set(
            _viewportSize.x * viewport.x,
            _viewportSize.y * viewport.y,
            _viewportSize.x * viewport.z,
            _viewportSize.y * viewport.w
          );

          _state.viewport(this.#viewPort);

          shadow.updateMatrices(light, vp);

          this.#frustum = shadow.getFrustum();

          this.renderObject(scene, camera, shadow.camera, light, this.type);
        }

        // do blur pass for VSM
        if (!shadow.isPointLightShadow && this.type === VSMShadowMap) {
          VSMPass(shadow, camera);
        }

        shadow.needsUpdate = false;
      }

      scope.needsUpdate = false;

      this.#renderer.setRenderTarget(
        currentRenderTarget,
        activeCubeFace,
        activeMipmapLevel
      );
    };

    function VSMPass(shadow, camera) {
      const geometry = _objects.update(fullScreenMesh);

      // vertical pass
      shadowMaterialVertical.uniforms.shadow_pass.value = shadow.map.texture;
      shadowMaterialVertical.uniforms.resolution.value = shadow.mapSize;
      shadowMaterialVertical.uniforms.radius.value = shadow.radius;
      _renderer.setRenderTarget(shadow.mapPass);
      _renderer.clear();
      _renderer.renderBufferDirect(
        camera,
        null,
        geometry,
        shadowMaterialVertical,
        fullScreenMesh,
        null
      );

      // horizontal pass
      shadowMaterialHorizontal.uniforms.shadow_pass.value =
        shadow.mapPass.texture;
      shadowMaterialHorizontal.uniforms.resolution.value = shadow.mapSize;
      shadowMaterialHorizontal.uniforms.radius.value = shadow.radius;
      _renderer.setRenderTarget(shadow.map);
      _renderer.clear();
      _renderer.renderBufferDirect(
        camera,
        null,
        geometry,
        shadowMaterialHorizontal,
        fullScreenMesh,
        null
      );
    }
  }

  #getDepthMaterialVariant(useInstancing) {
    const index = useInstancing;

    let material = this.#depthMaterials[index];

    if (material === undefined) {
      material = new MeshDepthMaterial({
        depthPacking: RGBADepthPacking,
      });

      this.#depthMaterials[index] = material;
    }

    return material;
  }

  #getDistanceMaterialVariant(useInstancing) {
    const index = useInstancing;

    let material = this.#distanceMaterials[index];

    if (material === undefined) {
      material = new MeshDistanceMaterial();

      this.#distanceMaterials[index] = material;
    }

    return material;
  }

  #getDepthMaterial(
    object,
    material,
    light,
    shadowCameraNear,
    shadowCameraFar,
    type
  ) {
    let result = null;

    let customMaterial = object.customDepthMaterial;

    if (light.isPointLight === true) {
      customMaterial = object.customDistanceMaterial;
    }

    if (customMaterial === undefined) {
      const useInstancing = object.isInstancedMesh === true;

      if (light.isPointLight === true) {
        result = this.#getDistanceMaterialVariant(useInstancing);
      } else {
        result = this.#getDepthMaterialVariant(useInstancing);
      }
    } else {
      result = customMaterial;
    }

    if (
      this.#renderer.localClippingEnabled &&
      material.clipShadows === true &&
      material.clippingPlanes.length !== 0
    ) {
      // in this case we need a unique material instance reflecting the
      // appropriate state
      const keyA = result.uuid;
      const keyB = material.uuid;

      let materialsForVariant = this.#materialCache[keyA];

      if (materialsForVariant === undefined) {
        materialsForVariant = {};
        this.#materialCache[keyA] = materialsForVariant;
      }

      let cachedMaterial = materialsForVariant[keyB];

      if (cachedMaterial === undefined) {
        cachedMaterial = result.clone();
        materialsForVariant[keyB] = cachedMaterial;
      }

      result = cachedMaterial;
    }

    result.visible = material.visible;
    result.wireframe = material.wireframe;

    if (type === VSMShadowMap) {
      result.side =
        material.shadowSide !== null ? material.shadowSide : material.side;
    } else {
      result.side =
        material.shadowSide !== null
          ? material.shadowSide
          : shadowSide[material.side];
    }

    result.clipShadows = material.clipShadows;
    result.clippingPlanes = material.clippingPlanes;
    result.clipIntersection = material.clipIntersection;

    result.wireframeLinewidth = material.wireframeLinewidth;
    result.linewidth = material.linewidth;

    if (light.isPointLight === true && result.isMeshDistanceMaterial === true) {
      result.referencePosition.setFromMatrixPosition(light.matrixWorld);
      result.nearDistance = shadowCameraNear;
      result.farDistance = shadowCameraFar;
    }

    return result;
  }

  renderObject(object, camera, shadowCamera, light, type) {
    if (object.visible === false) return;

    const visible = object.layers.test(camera.layers);

    if (visible && (object.isMesh || object.isLine || object.isPoints)) {
      if (
        (object.castShadow ||
          (object.receiveShadow && type === VSMShadowMap)) &&
        (!object.frustumCulled || this.#frustum.intersectsObject(object))
      ) {
        object.modelViewMatrix.multiplyMatrices(
          shadowCamera.matrixWorldInverse,
          object.matrixWorld
        );

        const geometry = this.#objects.update(object);
        const material = object.material;

        if (Array.isArray(material)) {
          const groups = geometry.groups;

          for (let k = 0, kl = groups.length; k < kl; k++) {
            const group = groups[k];
            const groupMaterial = material[group.materialIndex];

            if (groupMaterial && groupMaterial.visible) {
              const depthMaterial = this.#getDepthMaterial(
                object,
                groupMaterial,
                light,
                shadowCamera.near,
                shadowCamera.far,
                type
              );

              this.#renderer.renderBufferDirect(
                shadowCamera,
                null,
                geometry,
                depthMaterial,
                object,
                group
              );
            }
          }
        } else if (material.visible) {
          const depthMaterial = this.#getDepthMaterial(
            object,
            material,
            light,
            shadowCamera.near,
            shadowCamera.far,
            type
          );

          this.#renderer.renderBufferDirect(
            shadowCamera,
            null,
            geometry,
            depthMaterial,
            object,
            null
          );
        }
      }
    }

    const children = object.children;

    for (let i = 0, l = children.length; i < l; i++) {
      this.renderObject(children[i], camera, shadowCamera, light, type);
    }
  }
}

function WebGLState(gl) {
  function ColorBuffer() {
    let locked = false;

    const color = new Vector4();
    let currentColorMask = null;
    const currentColorClear = new Vector4(0, 0, 0, 0);

    return {
      setMask: function (colorMask) {
        if (currentColorMask !== colorMask && !locked) {
          gl.colorMask(colorMask, colorMask, colorMask, colorMask);
          currentColorMask = colorMask;
        }
      },

      setLocked: function (lock) {
        locked = lock;
      },

      setClear: function (r, g, b, a, premultipliedAlpha) {
        if (premultipliedAlpha === true) {
          r *= a;
          g *= a;
          b *= a;
        }

        color.set(r, g, b, a);

        if (currentColorClear.equals(color) === false) {
          gl.clearColor(r, g, b, a);
          currentColorClear.copy(color);
        }
      },

      reset: function () {
        locked = false;

        currentColorMask = null;
        currentColorClear.set(-1, 0, 0, 0); // set to invalid state
      },
    };
  }

  function DepthBuffer() {
    let locked = false;

    let currentDepthMask = null;
    let currentDepthFunc = null;
    let currentDepthClear = null;

    return {
      setTest: function (depthTest) {
        if (depthTest) {
          enable(2929);
        } else {
          disable(2929);
        }
      },

      setMask: function (depthMask) {
        if (currentDepthMask !== depthMask && !locked) {
          gl.depthMask(depthMask);
          currentDepthMask = depthMask;
        }
      },

      setFunc: function (depthFunc) {
        if (currentDepthFunc !== depthFunc) {
          if (depthFunc) {
            switch (depthFunc) {
              case NeverDepth:
                gl.depthFunc(512);
                break;

              case AlwaysDepth:
                gl.depthFunc(519);
                break;

              case LessDepth:
                gl.depthFunc(513);
                break;

              case LessEqualDepth:
                gl.depthFunc(515);
                break;

              case EqualDepth:
                gl.depthFunc(514);
                break;

              case GreaterEqualDepth:
                gl.depthFunc(518);
                break;

              case GreaterDepth:
                gl.depthFunc(516);
                break;

              case NotEqualDepth:
                gl.depthFunc(517);
                break;

              default:
                gl.depthFunc(515);
            }
          } else {
            gl.depthFunc(515);
          }

          currentDepthFunc = depthFunc;
        }
      },

      setLocked: function (lock) {
        locked = lock;
      },

      setClear: function (depth) {
        if (currentDepthClear !== depth) {
          gl.clearDepth(depth);
          currentDepthClear = depth;
        }
      },

      reset: function () {
        locked = false;

        currentDepthMask = null;
        currentDepthFunc = null;
        currentDepthClear = null;
      },
    };
  }

  function StencilBuffer() {
    let locked = false;

    let currentStencilMask = null;
    let currentStencilFunc = null;
    let currentStencilRef = null;
    let currentStencilFuncMask = null;
    let currentStencilFail = null;
    let currentStencilZFail = null;
    let currentStencilZPass = null;
    let currentStencilClear = null;

    return {
      setTest: function (stencilTest) {
        if (!locked) {
          if (stencilTest) {
            enable(2960);
          } else {
            disable(2960);
          }
        }
      },

      setMask: function (stencilMask) {
        if (currentStencilMask !== stencilMask && !locked) {
          gl.stencilMask(stencilMask);
          currentStencilMask = stencilMask;
        }
      },

      setFunc: function (stencilFunc, stencilRef, stencilMask) {
        if (
          currentStencilFunc !== stencilFunc ||
          currentStencilRef !== stencilRef ||
          currentStencilFuncMask !== stencilMask
        ) {
          gl.stencilFunc(stencilFunc, stencilRef, stencilMask);

          currentStencilFunc = stencilFunc;
          currentStencilRef = stencilRef;
          currentStencilFuncMask = stencilMask;
        }
      },

      setOp: function (stencilFail, stencilZFail, stencilZPass) {
        if (
          currentStencilFail !== stencilFail ||
          currentStencilZFail !== stencilZFail ||
          currentStencilZPass !== stencilZPass
        ) {
          gl.stencilOp(stencilFail, stencilZFail, stencilZPass);

          currentStencilFail = stencilFail;
          currentStencilZFail = stencilZFail;
          currentStencilZPass = stencilZPass;
        }
      },

      setLocked: function (lock) {
        locked = lock;
      },

      setClear: function (stencil) {
        if (currentStencilClear !== stencil) {
          gl.clearStencil(stencil);
          currentStencilClear = stencil;
        }
      },

      reset: function () {
        locked = false;

        currentStencilMask = null;
        currentStencilFunc = null;
        currentStencilRef = null;
        currentStencilFuncMask = null;
        currentStencilFail = null;
        currentStencilZFail = null;
        currentStencilZPass = null;
        currentStencilClear = null;
      },
    };
  }

  const colorBuffer = new ColorBuffer();
  const depthBuffer = new DepthBuffer();
  const stencilBuffer = new StencilBuffer();

  let enabledCapabilities = {};

  let currentProgram = null;

  let currentBlendingEnabled = null;
  let currentBlending = null;
  let currentBlendEquation = null;
  let currentBlendSrc = null;
  let currentBlendDst = null;
  let currentBlendEquationAlpha = null;
  let currentBlendSrcAlpha = null;
  let currentBlendDstAlpha = null;
  let currentPremultipledAlpha = false;

  let currentFlipSided = null;
  let currentCullFace = null;

  let currentPolygonOffsetFactor = null;
  let currentPolygonOffsetUnits = null;

  const maxTextures = gl.getParameter(35661);

  const glVersion = gl.getParameter(7938);

  console.info("GL Version: ", glVersion);

  let currentTextureSlot = null;
  let currentBoundTextures = {};

  const currentScissor = new Vector4();
  const currentViewport = new Vector4();

  function createTexture(type, target, count) {
    const data = new Uint8Array(4); // 4 is required to match default unpack alignment of 4.
    const texture = gl.createTexture();

    gl.bindTexture(type, texture);
    gl.texParameteri(type, 10241, 9728);
    gl.texParameteri(type, 10240, 9728);

    for (let i = 0; i < count; i++) {
      gl.texImage2D(target + i, 0, 6408, 1, 1, 0, 6408, 5121, data);
    }

    return texture;
  }

  const emptyTextures = {};
  emptyTextures[3553] = createTexture(3553, 3553, 1);
  emptyTextures[34067] = createTexture(34067, 34069, 6);

  // init

  colorBuffer.setClear(0, 0, 0, 1);
  depthBuffer.setClear(1);
  stencilBuffer.setClear(0);

  enable(2929);
  depthBuffer.setFunc(LessEqualDepth);

  setFlipSided(false);
  setCullFace(CullFaceBack);
  enable(2884);

  setBlending(NoBlending);

  //

  function enable(id) {
    if (enabledCapabilities[id] !== true) {
      gl.enable(id);
      enabledCapabilities[id] = true;
    }
  }

  function disable(id) {
    if (enabledCapabilities[id] !== false) {
      gl.disable(id);
      enabledCapabilities[id] = false;
    }
  }

  function useProgram(program) {
    if (currentProgram !== program) {
      gl.useProgram(program);

      currentProgram = program;

      return true;
    }

    return false;
  }

  const equationToGL = {
    [AddEquation]: 32774,
    [SubtractEquation]: 32778,
    [ReverseSubtractEquation]: 32779,
  };

  equationToGL[MinEquation] = 32775;
  equationToGL[MaxEquation] = 32776;

  const factorToGL = {
    [ZeroFactor]: 0,
    [OneFactor]: 1,
    [SrcColorFactor]: 768,
    [SrcAlphaFactor]: 770,
    [SrcAlphaSaturateFactor]: 776,
    [DstColorFactor]: 774,
    [DstAlphaFactor]: 772,
    [OneMinusSrcColorFactor]: 769,
    [OneMinusSrcAlphaFactor]: 771,
    [OneMinusDstColorFactor]: 775,
    [OneMinusDstAlphaFactor]: 773,
  };

  function setBlending(
    blending,
    blendEquation,
    blendSrc,
    blendDst,
    blendEquationAlpha,
    blendSrcAlpha,
    blendDstAlpha,
    premultipliedAlpha
  ) {
    if (blending === NoBlending) {
      if (currentBlendingEnabled) {
        disable(3042);
        currentBlendingEnabled = false;
      }

      return;
    }

    if (!currentBlendingEnabled) {
      enable(3042);
      currentBlendingEnabled = true;
    }

    if (blending !== CustomBlending) {
      if (
        blending !== currentBlending ||
        premultipliedAlpha !== currentPremultipledAlpha
      ) {
        if (
          currentBlendEquation !== AddEquation ||
          currentBlendEquationAlpha !== AddEquation
        ) {
          gl.blendEquation(32774);

          currentBlendEquation = AddEquation;
          currentBlendEquationAlpha = AddEquation;
        }

        if (premultipliedAlpha) {
          switch (blending) {
            case NormalBlending:
              gl.blendFuncSeparate(1, 771, 1, 771);
              break;

            case AdditiveBlending:
              gl.blendFunc(1, 1);
              break;

            case SubtractiveBlending:
              gl.blendFuncSeparate(0, 0, 769, 771);
              break;

            case MultiplyBlending:
              gl.blendFuncSeparate(0, 768, 0, 770);
              break;

            default:
              console.error("THREE.WebGLState: Invalid blending: ", blending);
              break;
          }
        } else {
          switch (blending) {
            case NormalBlending:
              gl.blendFuncSeparate(770, 771, 1, 771);
              break;

            case AdditiveBlending:
              gl.blendFunc(770, 1);
              break;

            case SubtractiveBlending:
              gl.blendFunc(0, 769);
              break;

            case MultiplyBlending:
              gl.blendFunc(0, 768);
              break;

            default:
              console.error("THREE.WebGLState: Invalid blending: ", blending);
              break;
          }
        }

        currentBlendSrc = null;
        currentBlendDst = null;
        currentBlendSrcAlpha = null;
        currentBlendDstAlpha = null;

        currentBlending = blending;
        currentPremultipledAlpha = premultipliedAlpha;
      }

      return;
    }

    // custom blending

    blendEquationAlpha = blendEquationAlpha || blendEquation;
    blendSrcAlpha = blendSrcAlpha || blendSrc;
    blendDstAlpha = blendDstAlpha || blendDst;

    if (
      blendEquation !== currentBlendEquation ||
      blendEquationAlpha !== currentBlendEquationAlpha
    ) {
      gl.blendEquationSeparate(
        equationToGL[blendEquation],
        equationToGL[blendEquationAlpha]
      );

      currentBlendEquation = blendEquation;
      currentBlendEquationAlpha = blendEquationAlpha;
    }

    if (
      blendSrc !== currentBlendSrc ||
      blendDst !== currentBlendDst ||
      blendSrcAlpha !== currentBlendSrcAlpha ||
      blendDstAlpha !== currentBlendDstAlpha
    ) {
      gl.blendFuncSeparate(
        factorToGL[blendSrc],
        factorToGL[blendDst],
        factorToGL[blendSrcAlpha],
        factorToGL[blendDstAlpha]
      );

      currentBlendSrc = blendSrc;
      currentBlendDst = blendDst;
      currentBlendSrcAlpha = blendSrcAlpha;
      currentBlendDstAlpha = blendDstAlpha;
    }

    currentBlending = blending;
    currentPremultipledAlpha = null;
  }

  function setMaterial(material, frontFaceCW) {
    material.side === DoubleSide ? disable(2884) : enable(2884);

    let flipSided = material.side === BackSide;
    if (frontFaceCW) flipSided = !flipSided;

    setFlipSided(flipSided);

    material.blending === NormalBlending && material.transparent === false
      ? setBlending(NoBlending)
      : setBlending(
          material.blending,
          material.blendEquation,
          material.blendSrc,
          material.blendDst,
          material.blendEquationAlpha,
          material.blendSrcAlpha,
          material.blendDstAlpha,
          material.premultipliedAlpha
        );

    depthBuffer.setFunc(material.depthFunc);
    depthBuffer.setTest(material.depthTest);
    depthBuffer.setMask(material.depthWrite);
    colorBuffer.setMask(material.colorWrite);

    const stencilWrite = material.stencilWrite;
    stencilBuffer.setTest(stencilWrite);
    if (stencilWrite) {
      stencilBuffer.setMask(material.stencilWriteMask);
      stencilBuffer.setFunc(
        material.stencilFunc,
        material.stencilRef,
        material.stencilFuncMask
      );
      stencilBuffer.setOp(
        material.stencilFail,
        material.stencilZFail,
        material.stencilZPass
      );
    }

    setPolygonOffset(
      material.polygonOffset,
      material.polygonOffsetFactor,
      material.polygonOffsetUnits
    );
  }

  //

  function setFlipSided(flipSided) {
    if (currentFlipSided !== flipSided) {
      if (flipSided) {
        gl.frontFace(2304);
      } else {
        gl.frontFace(2305);
      }

      currentFlipSided = flipSided;
    }
  }

  function setCullFace(cullFace) {
    if (cullFace !== CullFaceNone) {
      enable(2884);

      if (cullFace !== currentCullFace) {
        if (cullFace === CullFaceBack) {
          gl.cullFace(1029);
        } else if (cullFace === CullFaceFront) {
          gl.cullFace(1028);
        } else {
          gl.cullFace(1032);
        }
      }
    } else {
      disable(2884);
    }

    currentCullFace = cullFace;
  }

  function setPolygonOffset(polygonOffset, factor, units) {
    if (polygonOffset) {
      enable(32823);

      if (
        currentPolygonOffsetFactor !== factor ||
        currentPolygonOffsetUnits !== units
      ) {
        gl.polygonOffset(factor, units);

        currentPolygonOffsetFactor = factor;
        currentPolygonOffsetUnits = units;
      }
    } else {
      disable(32823);
    }
  }

  function setScissorTest(scissorTest) {
    if (scissorTest) {
      enable(3089);
    } else {
      disable(3089);
    }
  }

  // texture

  function activeTexture(webglSlot) {
    if (webglSlot === undefined) webglSlot = 33984 + maxTextures - 1;

    if (currentTextureSlot !== webglSlot) {
      gl.activeTexture(webglSlot);
      currentTextureSlot = webglSlot;
    }
  }

  function bindTexture(webglType, webglTexture) {
    if (currentTextureSlot === null) {
      activeTexture();
    }

    let boundTexture = currentBoundTextures[currentTextureSlot];

    if (boundTexture === undefined) {
      boundTexture = { type: undefined, texture: undefined };
      currentBoundTextures[currentTextureSlot] = boundTexture;
    }

    if (
      boundTexture.type !== webglType ||
      boundTexture.texture !== webglTexture
    ) {
      gl.bindTexture(webglType, webglTexture || emptyTextures[webglType]);

      boundTexture.type = webglType;
      boundTexture.texture = webglTexture;
    }
  }

  function unbindTexture() {
    const boundTexture = currentBoundTextures[currentTextureSlot];

    if (boundTexture !== undefined && boundTexture.type !== undefined) {
      gl.bindTexture(boundTexture.type, null);

      boundTexture.type = undefined;
      boundTexture.texture = undefined;
    }
  }

  function compressedTexImage2D() {
    try {
      gl.compressedTexImage2D.apply(gl, arguments);
    } catch (error) {
      console.error("THREE.WebGLState:", error);
    }
  }

  function texImage2D() {
    try {
      gl.texImage2D.apply(gl, arguments);
    } catch (error) {
      console.error("THREE.WebGLState:", error);
    }
  }

  function texImage3D() {
    try {
      gl.texImage3D.apply(gl, arguments);
    } catch (error) {
      console.error("THREE.WebGLState:", error);
    }
  }

  //

  function scissor(scissor) {
    if (currentScissor.equals(scissor) === false) {
      gl.scissor(scissor.x, scissor.y, scissor.z, scissor.w);
      currentScissor.copy(scissor);
    }
  }

  function viewport(viewport) {
    if (currentViewport.equals(viewport) === false) {
      gl.viewport(viewport.x, viewport.y, viewport.z, viewport.w);
      currentViewport.copy(viewport);
    }
  }

  //

  function reset() {
    enabledCapabilities = {};

    currentTextureSlot = null;
    currentBoundTextures = {};

    currentProgram = null;

    currentBlendingEnabled = null;
    currentBlending = null;
    currentBlendEquation = null;
    currentBlendSrc = null;
    currentBlendDst = null;
    currentBlendEquationAlpha = null;
    currentBlendSrcAlpha = null;
    currentBlendDstAlpha = null;
    currentPremultipledAlpha = false;

    currentFlipSided = null;
    currentCullFace = null;

    currentPolygonOffsetFactor = null;
    currentPolygonOffsetUnits = null;

    colorBuffer.reset();
    depthBuffer.reset();
    stencilBuffer.reset();
  }

  return {
    buffers: {
      color: colorBuffer,
      depth: depthBuffer,
      stencil: stencilBuffer,
    },

    enable: enable,
    disable: disable,

    useProgram: useProgram,

    setBlending: setBlending,
    setMaterial: setMaterial,

    setFlipSided: setFlipSided,
    setCullFace: setCullFace,

    setPolygonOffset: setPolygonOffset,

    setScissorTest: setScissorTest,

    activeTexture: activeTexture,
    bindTexture: bindTexture,
    unbindTexture: unbindTexture,
    compressedTexImage2D: compressedTexImage2D,
    texImage2D: texImage2D,
    texImage3D: texImage3D,

    scissor: scissor,
    viewport: viewport,

    reset: reset,
  };
}

function WebGLTextures(
  _gl,
  extensions,
  state,
  properties,
  capabilities,
  utils,
  info
) {
  const maxTextures = capabilities.maxTextures;
  const maxCubemapSize = capabilities.maxCubemapSize;
  const maxTextureSize = capabilities.maxTextureSize;
  const maxSamples = capabilities.maxSamples;

  let _canvas;

  let useOffscreenCanvas = false;

  try {
    useOffscreenCanvas =
      typeof OffscreenCanvas !== "undefined" &&
      new OffscreenCanvas(1, 1).getContext("2d") !== null;
  } catch {
    // Ignore any errors
  }

  function createCanvas(width, height) {
    // Use OffscreenCanvas when available. Specially needed in web workers

    return useOffscreenCanvas
      ? new OffscreenCanvas(width, height)
      : document.createElementNS("http://www.w3.org/1999/xhtml", "canvas");
  }

  function resizeImage(image, needsNewCanvas, maxSize) {
    let scale = 1;

    // handle case if texture exceeds max size

    if (image.width > maxSize || image.height > maxSize) {
      scale = maxSize / Math.max(image.width, image.height);
    }

    // only perform resize if necessary

    if (scale < 1) {
      // only perform resize for certain image types

      if (
        (typeof HTMLImageElement !== "undefined" &&
          image instanceof HTMLImageElement) ||
        (typeof HTMLCanvasElement !== "undefined" &&
          image instanceof HTMLCanvasElement) ||
        (typeof ImageBitmap !== "undefined" && image instanceof ImageBitmap)
      ) {
        const floor = Math.floor;

        const width = floor(scale * image.width);
        const height = floor(scale * image.height);

        if (_canvas === undefined) _canvas = createCanvas(width, height);

        // cube textures can't reuse the same canvas

        const canvas = needsNewCanvas ? createCanvas(width, height) : _canvas;

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, width, height);

        console.warn(
          "THREE.WebGLRenderer: Texture has been resized from (" +
            image.width +
            "x" +
            image.height +
            ") to (" +
            width +
            "x" +
            height +
            ")."
        );

        return canvas;
      } else {
        if ("data" in image) {
          console.warn(
            "THREE.WebGLRenderer: Image in DataTexture is too big (" +
              image.width +
              "x" +
              image.height +
              ")."
          );
        }

        return image;
      }
    }

    return image;
  }

  function textureNeedsGenerateMipmaps(texture, supportsMips) {
    return (
      texture.generateMipmaps &&
      supportsMips &&
      texture.minFilter !== NearestFilter &&
      texture.minFilter !== LinearFilter
    );
  }

  function generateMipmap(target, texture, width, height) {
    _gl.generateMipmap(target);

    const textureProperties = properties.get(texture);

    textureProperties.__maxMipLevel = Math.log2(Math.max(width, height));
  }

  function getInternalFormat(internalFormatName, glFormat, glType) {
    if (internalFormatName !== null) {
      if (_gl[internalFormatName] !== undefined) return _gl[internalFormatName];

      console.warn(
        "THREE.WebGLRenderer: Attempt to use non-existing WebGL internal format '" +
          internalFormatName +
          "'"
      );
    }

    let internalFormat = glFormat;

    if (glFormat === 6403) {
      if (glType === 5126) internalFormat = 33326;
      if (glType === 5131) internalFormat = 33325;
      if (glType === 5121) internalFormat = 33321;
    }

    if (glFormat === 6407) {
      if (glType === 5126) internalFormat = 34837;
      if (glType === 5131) internalFormat = 34843;
      if (glType === 5121) internalFormat = 32849;
    }

    if (glFormat === 6408) {
      if (glType === 5126) internalFormat = 34836;
      if (glType === 5131) internalFormat = 34842;
      if (glType === 5121) internalFormat = 32856;
    }

    if (
      internalFormat === 33325 ||
      internalFormat === 33326 ||
      internalFormat === 34842 ||
      internalFormat === 34836
    ) {
      extensions.get("EXT_color_buffer_float");
    }

    return internalFormat;
  }

  // Fallback filters for non-power-of-2 textures

  function filterFallback(f) {
    if (
      f === NearestFilter ||
      f === NearestMipMapNearestFilter ||
      f === NearestMipMapLinearFilter
    ) {
      return 9728;
    }

    return 9729;
  }

  //

  function onTextureDispose(event) {
    const texture = event.target;

    texture.removeEventListener("dispose", onTextureDispose);

    deallocateTexture(texture);

    info.memory.textures--;
  }

  function onRenderTargetDispose(event) {
    const renderTarget = event.target;

    renderTarget.removeEventListener("dispose", onRenderTargetDispose);

    deallocateRenderTarget(renderTarget);

    info.memory.textures--;
  }

  //

  function deallocateTexture(texture) {
    const textureProperties = properties.get(texture);

    if (textureProperties.__webglInit === undefined) return;

    _gl.deleteTexture(textureProperties.__webglTexture);

    properties.remove(texture);
  }

  function deallocateRenderTarget(renderTarget) {
    const renderTargetProperties = properties.get(renderTarget);
    const textureProperties = properties.get(renderTarget.texture);

    if (!renderTarget) return;

    if (textureProperties.__webglTexture !== undefined) {
      _gl.deleteTexture(textureProperties.__webglTexture);
    }

    if (renderTarget.depthTexture) {
      renderTarget.depthTexture.dispose();
    }

    {
      _gl.deleteFramebuffer(renderTargetProperties.__webglFramebuffer);
      if (renderTargetProperties.__webglDepthbuffer)
        _gl.deleteRenderbuffer(renderTargetProperties.__webglDepthbuffer);
      if (renderTargetProperties.__webglMultisampledFramebuffer)
        _gl.deleteFramebuffer(
          renderTargetProperties.__webglMultisampledFramebuffer
        );
      if (renderTargetProperties.__webglColorRenderbuffer)
        _gl.deleteRenderbuffer(renderTargetProperties.__webglColorRenderbuffer);
      if (renderTargetProperties.__webglDepthRenderbuffer)
        _gl.deleteRenderbuffer(renderTargetProperties.__webglDepthRenderbuffer);
    }

    properties.remove(renderTarget.texture);
    properties.remove(renderTarget);
  }

  //

  let textureUnits = 0;

  function resetTextureUnits() {
    textureUnits = 0;
  }

  function allocateTextureUnit() {
    const textureUnit = textureUnits;

    if (textureUnit >= maxTextures) {
      console.warn(
        "THREE.WebGLTextures: Trying to use " +
          textureUnit +
          " texture units while this GPU supports only " +
          maxTextures
      );
    }

    textureUnits += 1;

    return textureUnit;
  }

  //

  function setTexture2D(texture, slot) {
    const textureProperties = properties.get(texture);

    if (
      texture.version > 0 &&
      textureProperties.__version !== texture.version
    ) {
      const image = texture.image;

      if (image === undefined) {
        console.warn(
          "THREE.WebGLRenderer: Texture marked for update but image is undefined"
        );
      } else if (image.complete === false) {
        console.warn(
          "THREE.WebGLRenderer: Texture marked for update but image is incomplete"
        );
      } else {
        uploadTexture(textureProperties, texture, slot);
        return;
      }
    }

    state.activeTexture(33984 + slot);
    state.bindTexture(3553, textureProperties.__webglTexture);
  }

  function setTexture2DArray(texture, slot) {
    const textureProperties = properties.get(texture);

    if (
      texture.version > 0 &&
      textureProperties.__version !== texture.version
    ) {
      uploadTexture(textureProperties, texture, slot);
      return;
    }

    state.activeTexture(33984 + slot);
    state.bindTexture(35866, textureProperties.__webglTexture);
  }

  function setTexture3D(texture, slot) {
    const textureProperties = properties.get(texture);

    if (
      texture.version > 0 &&
      textureProperties.__version !== texture.version
    ) {
      uploadTexture(textureProperties, texture, slot);
      return;
    }

    state.activeTexture(33984 + slot);
    state.bindTexture(32879, textureProperties.__webglTexture);
  }

  function setTextureCube(texture, slot) {
    const textureProperties = properties.get(texture);

    if (
      texture.version > 0 &&
      textureProperties.__version !== texture.version
    ) {
      uploadCubeTexture(textureProperties, texture, slot);
      return;
    }

    state.activeTexture(33984 + slot);
    state.bindTexture(34067, textureProperties.__webglTexture);
  }

  const wrappingToGL = {
    [RepeatWrapping]: 10497,
    [ClampToEdgeWrapping]: 33071,
    [MirroredRepeatWrapping]: 33648,
  };

  const filterToGL = {
    [NearestFilter]: 9728,
    [NearestMipMapNearestFilter]: 9984,
    [NearestMipMapLinearFilter]: 9986,

    [LinearFilter]: 9729,
    [LinearMipMapNearestFilter]: 9985,
    [LinearMipMapLinearFilter]: 9987,
  };

  function setTextureParameters(textureType, texture, supportsMips) {
    if (supportsMips) {
      _gl.texParameteri(textureType, 10242, wrappingToGL[texture.wrapS]);
      _gl.texParameteri(textureType, 10243, wrappingToGL[texture.wrapT]);

      if (textureType === 32879 || textureType === 35866) {
        _gl.texParameteri(textureType, 32882, wrappingToGL[texture.wrapR]);
      }

      _gl.texParameteri(textureType, 10240, filterToGL[texture.magFilter]);
      _gl.texParameteri(textureType, 10241, filterToGL[texture.minFilter]);
    } else {
      _gl.texParameteri(textureType, 10242, 33071);
      _gl.texParameteri(textureType, 10243, 33071);

      if (textureType === 32879 || textureType === 35866) {
        _gl.texParameteri(textureType, 32882, 33071);
      }

      if (
        texture.wrapS !== ClampToEdgeWrapping ||
        texture.wrapT !== ClampToEdgeWrapping
      ) {
        console.warn(
          "THREE.WebGLRenderer: Texture is not power of two. Texture.wrapS and Texture.wrapT should be set to THREE.ClampToEdgeWrapping."
        );
      }

      _gl.texParameteri(textureType, 10240, filterFallback(texture.magFilter));
      _gl.texParameteri(textureType, 10241, filterFallback(texture.minFilter));

      if (
        texture.minFilter !== NearestFilter &&
        texture.minFilter !== LinearFilter
      ) {
        console.warn(
          "THREE.WebGLRenderer: Texture is not power of two. Texture.minFilter should be set to THREE.NearestFilter or THREE.LinearFilter."
        );
      }
    }

    const extension = extensions.get("EXT_texture_filter_anisotropic");

    if (extension) {
      if (
        texture.type === FloatType &&
        extensions.get("OES_texture_float_linear") === null
      )
        return;

      if (
        texture.anisotropy > 1 ||
        properties.get(texture).__currentAnisotropy
      ) {
        _gl.texParameterf(
          textureType,
          extension.TEXTURE_MAX_ANISOTROPY_EXT,
          Math.min(texture.anisotropy, capabilities.getMaxAnisotropy())
        );
        properties.get(texture).__currentAnisotropy = texture.anisotropy;
      }
    }
  }

  function initTexture(textureProperties, texture) {
    if (textureProperties.__webglInit === undefined) {
      textureProperties.__webglInit = true;

      texture.addEventListener("dispose", onTextureDispose);

      textureProperties.__webglTexture = _gl.createTexture();

      info.memory.textures++;
    }
  }

  function uploadTexture(textureProperties, texture, slot) {
    let textureType = 3553;

    if (texture.isDataTexture2DArray) textureType = 35866;
    if (texture.isDataTexture3D) textureType = 32879;

    initTexture(textureProperties, texture);

    state.activeTexture(33984 + slot);
    state.bindTexture(textureType, textureProperties.__webglTexture);

    _gl.pixelStorei(37440, texture.flipY);
    _gl.pixelStorei(37441, texture.premultiplyAlpha);
    _gl.pixelStorei(3317, texture.unpackAlignment);

    const image = resizeImage(texture.image, false, maxTextureSize);

    const supportsMips = true,
      glFormat = utils.convert(texture.format);

    let glType = utils.convert(texture.type),
      glInternalFormat = getInternalFormat(
        texture.internalFormat,
        glFormat,
        glType
      );

    setTextureParameters(textureType, texture, supportsMips);

    let mipmap;
    const mipmaps = texture.mipmaps;

    if (texture.isDepthTexture) {
      // populate depth texture with dummy data

      glInternalFormat = 6402;

      if (texture.type === FloatType) {
        glInternalFormat = 36012;
      } else if (texture.type === UnsignedIntType) {
        glInternalFormat = 33190;
      } else if (texture.type === UnsignedInt248Type) {
        glInternalFormat = 35056;
      } else {
        glInternalFormat = 33189; // WebGL2 requires sized internalformat for glTexImage2D
      }

      // validation checks for WebGL 1

      if (texture.format === DepthFormat && glInternalFormat === 6402) {
        // The error INVALID_OPERATION is generated by texImage2D if format and internalformat are
        // DEPTH_COMPONENT and type is not UNSIGNED_SHORT or UNSIGNED_INT
        // (https://www.khronos.org/registry/webgl/extensions/WEBGL_depth_texture/)
        if (
          texture.type !== UnsignedShortType &&
          texture.type !== UnsignedIntType
        ) {
          console.warn(
            "THREE.WebGLRenderer: Use UnsignedShortType or UnsignedIntType for DepthFormat DepthTexture."
          );

          texture.type = UnsignedShortType;
          glType = utils.convert(texture.type);
        }
      }

      if (texture.format === DepthStencilFormat && glInternalFormat === 6402) {
        // Depth stencil textures need the DEPTH_STENCIL internal format
        // (https://www.khronos.org/registry/webgl/extensions/WEBGL_depth_texture/)
        glInternalFormat = 34041;

        // The error INVALID_OPERATION is generated by texImage2D if format and internalformat are
        // DEPTH_STENCIL and type is not UNSIGNED_INT_24_8_WEBGL.
        // (https://www.khronos.org/registry/webgl/extensions/WEBGL_depth_texture/)
        if (texture.type !== UnsignedInt248Type) {
          console.warn(
            "THREE.WebGLRenderer: Use UnsignedInt248Type for DepthStencilFormat DepthTexture."
          );

          texture.type = UnsignedInt248Type;
          glType = utils.convert(texture.type);
        }
      }

      //

      state.texImage2D(
        3553,
        0,
        glInternalFormat,
        image.width,
        image.height,
        0,
        glFormat,
        glType,
        null
      );
    } else if (texture.isDataTexture) {
      // use manually created mipmaps if available
      // if there are no manual mipmaps
      // set 0 level mipmap and then use GL to generate other mipmap levels

      if (mipmaps.length > 0 && supportsMips) {
        for (let i = 0, il = mipmaps.length; i < il; i++) {
          mipmap = mipmaps[i];
          state.texImage2D(
            3553,
            i,
            glInternalFormat,
            mipmap.width,
            mipmap.height,
            0,
            glFormat,
            glType,
            mipmap.data
          );
        }

        texture.generateMipmaps = false;
        textureProperties.__maxMipLevel = mipmaps.length - 1;
      } else {
        state.texImage2D(
          3553,
          0,
          glInternalFormat,
          image.width,
          image.height,
          0,
          glFormat,
          glType,
          image.data
        );
        textureProperties.__maxMipLevel = 0;
      }
    } else if (texture.isCompressedTexture) {
      for (let i = 0, il = mipmaps.length; i < il; i++) {
        mipmap = mipmaps[i];

        if (texture.format !== RGBAFormat && texture.format !== RGBFormat) {
          if (glFormat !== null) {
            state.compressedTexImage2D(
              3553,
              i,
              glInternalFormat,
              mipmap.width,
              mipmap.height,
              0,
              mipmap.data
            );
          } else {
            console.warn(
              "THREE.WebGLRenderer: Attempt to load unsupported compressed texture format in .uploadTexture()"
            );
          }
        } else {
          state.texImage2D(
            3553,
            i,
            glInternalFormat,
            mipmap.width,
            mipmap.height,
            0,
            glFormat,
            glType,
            mipmap.data
          );
        }
      }

      textureProperties.__maxMipLevel = mipmaps.length - 1;
    } else if (texture.isDataTexture2DArray) {
      state.texImage3D(
        35866,
        0,
        glInternalFormat,
        image.width,
        image.height,
        image.depth,
        0,
        glFormat,
        glType,
        image.data
      );
      textureProperties.__maxMipLevel = 0;
    } else if (texture.isDataTexture3D) {
      state.texImage3D(
        32879,
        0,
        glInternalFormat,
        image.width,
        image.height,
        image.depth,
        0,
        glFormat,
        glType,
        image.data
      );
      textureProperties.__maxMipLevel = 0;
    } else {
      // regular Texture (image, video, canvas)

      // use manually created mipmaps if available
      // if there are no manual mipmaps
      // set 0 level mipmap and then use GL to generate other mipmap levels

      if (mipmaps.length > 0 && supportsMips) {
        for (let i = 0, il = mipmaps.length; i < il; i++) {
          mipmap = mipmaps[i];
          state.texImage2D(3553, i, glInternalFormat, glFormat, glType, mipmap);
        }

        texture.generateMipmaps = false;
        textureProperties.__maxMipLevel = mipmaps.length - 1;
      } else {
        state.texImage2D(3553, 0, glInternalFormat, glFormat, glType, image);
        textureProperties.__maxMipLevel = 0;
      }
    }

    if (textureNeedsGenerateMipmaps(texture, supportsMips)) {
      generateMipmap(textureType, texture, image.width, image.height);
    }

    textureProperties.__version = texture.version;

    if (texture.onUpdate) texture.onUpdate(texture);
  }

  function uploadCubeTexture(textureProperties, texture, slot) {
    if (texture.image.length !== 6) return;

    initTexture(textureProperties, texture);

    state.activeTexture(33984 + slot);
    state.bindTexture(34067, textureProperties.__webglTexture);

    _gl.pixelStorei(37440, texture.flipY);

    const isCompressed =
      texture &&
      (texture.isCompressedTexture || texture.image[0].isCompressedTexture);
    const isDataTexture = texture.image[0] && texture.image[0].isDataTexture;

    const cubeImage = [];

    for (let i = 0; i < 6; i++) {
      if (!isCompressed && !isDataTexture) {
        cubeImage[i] = resizeImage(texture.image[i], true, maxCubemapSize);
      } else {
        cubeImage[i] = isDataTexture
          ? texture.image[i].image
          : texture.image[i];
      }
    }

    const image = cubeImage[0],
      supportsMips = true,
      glFormat = utils.convert(texture.format),
      glType = utils.convert(texture.type),
      glInternalFormat = getInternalFormat(
        texture.internalFormat,
        glFormat,
        glType
      );

    setTextureParameters(34067, texture, supportsMips);

    let mipmaps;

    if (isCompressed) {
      for (let i = 0; i < 6; i++) {
        mipmaps = cubeImage[i].mipmaps;

        for (let j = 0; j < mipmaps.length; j++) {
          const mipmap = mipmaps[j];

          if (texture.format !== RGBAFormat && texture.format !== RGBFormat) {
            if (glFormat !== null) {
              state.compressedTexImage2D(
                34069 + i,
                j,
                glInternalFormat,
                mipmap.width,
                mipmap.height,
                0,
                mipmap.data
              );
            } else {
              console.warn(
                "THREE.WebGLRenderer: Attempt to load unsupported compressed texture format in .setTextureCube()"
              );
            }
          } else {
            state.texImage2D(
              34069 + i,
              j,
              glInternalFormat,
              mipmap.width,
              mipmap.height,
              0,
              glFormat,
              glType,
              mipmap.data
            );
          }
        }
      }

      textureProperties.__maxMipLevel = mipmaps.length - 1;
    } else {
      mipmaps = texture.mipmaps;

      for (let i = 0; i < 6; i++) {
        if (isDataTexture) {
          state.texImage2D(
            34069 + i,
            0,
            glInternalFormat,
            cubeImage[i].width,
            cubeImage[i].height,
            0,
            glFormat,
            glType,
            cubeImage[i].data
          );

          for (let j = 0; j < mipmaps.length; j++) {
            const mipmap = mipmaps[j];
            const mipmapImage = mipmap.image[i].image;

            state.texImage2D(
              34069 + i,
              j + 1,
              glInternalFormat,
              mipmapImage.width,
              mipmapImage.height,
              0,
              glFormat,
              glType,
              mipmapImage.data
            );
          }
        } else {
          state.texImage2D(
            34069 + i,
            0,
            glInternalFormat,
            glFormat,
            glType,
            cubeImage[i]
          );

          for (let j = 0; j < mipmaps.length; j++) {
            const mipmap = mipmaps[j];

            state.texImage2D(
              34069 + i,
              j + 1,
              glInternalFormat,
              glFormat,
              glType,
              mipmap.image[i]
            );
          }
        }
      }

      textureProperties.__maxMipLevel = mipmaps.length;
    }

    if (textureNeedsGenerateMipmaps(texture, supportsMips)) {
      // We assume images for cube map have the same size.
      generateMipmap(34067, texture, image.width, image.height);
    }

    textureProperties.__version = texture.version;

    if (texture.onUpdate) texture.onUpdate(texture);
  }

  // Render targets

  // Setup storage for target texture and bind it to correct framebuffer
  function setupFrameBufferTexture(
    framebuffer,
    renderTarget,
    attachment,
    textureTarget
  ) {
    const glFormat = utils.convert(renderTarget.texture.format);
    const glType = utils.convert(renderTarget.texture.type);
    const glInternalFormat = getInternalFormat(
      renderTarget.texture.internalFormat,
      glFormat,
      glType
    );
    state.texImage2D(
      textureTarget,
      0,
      glInternalFormat,
      renderTarget.width,
      renderTarget.height,
      0,
      glFormat,
      glType,
      null
    );
    _gl.bindFramebuffer(36160, framebuffer);
    _gl.framebufferTexture2D(
      36160,
      attachment,
      textureTarget,
      properties.get(renderTarget.texture).__webglTexture,
      0
    );
    _gl.bindFramebuffer(36160, null);
  }

  // Setup storage for internal depth/stencil buffers and bind to correct framebuffer
  function setupRenderBufferStorage(renderbuffer, renderTarget, isMultisample) {
    _gl.bindRenderbuffer(36161, renderbuffer);

    if (renderTarget.depthBuffer && !renderTarget.stencilBuffer) {
      let glInternalFormat = 33189;

      if (isMultisample) {
        const depthTexture = renderTarget.depthTexture;

        if (depthTexture && depthTexture.isDepthTexture) {
          if (depthTexture.type === FloatType) {
            glInternalFormat = 36012;
          } else if (depthTexture.type === UnsignedIntType) {
            glInternalFormat = 33190;
          }
        }

        const samples = getRenderTargetSamples(renderTarget);

        _gl.renderbufferStorageMultisample(
          36161,
          samples,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      } else {
        _gl.renderbufferStorage(
          36161,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      }

      _gl.framebufferRenderbuffer(36160, 36096, 36161, renderbuffer);
    } else if (renderTarget.depthBuffer && renderTarget.stencilBuffer) {
      if (isMultisample) {
        const samples = getRenderTargetSamples(renderTarget);

        _gl.renderbufferStorageMultisample(
          36161,
          samples,
          35056,
          renderTarget.width,
          renderTarget.height
        );
      } else {
        _gl.renderbufferStorage(
          36161,
          34041,
          renderTarget.width,
          renderTarget.height
        );
      }

      _gl.framebufferRenderbuffer(36160, 33306, 36161, renderbuffer);
    } else {
      const glFormat = utils.convert(renderTarget.texture.format);
      const glType = utils.convert(renderTarget.texture.type);
      const glInternalFormat = getInternalFormat(
        renderTarget.texture.internalFormat,
        glFormat,
        glType
      );

      if (isMultisample) {
        const samples = getRenderTargetSamples(renderTarget);

        _gl.renderbufferStorageMultisample(
          36161,
          samples,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      } else {
        _gl.renderbufferStorage(
          36161,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      }
    }

    _gl.bindRenderbuffer(36161, null);
  }

  // Setup resources for a Depth Texture for a FBO (needs an extension)
  function setupDepthTexture(framebuffer, renderTarget) {
    _gl.bindFramebuffer(36160, framebuffer);

    if (
      !(renderTarget.depthTexture && renderTarget.depthTexture.isDepthTexture)
    ) {
      throw new Error(
        "renderTarget.depthTexture must be an instance of THREE.DepthTexture"
      );
    }

    // upload an empty depth texture with framebuffer size
    if (
      !properties.get(renderTarget.depthTexture).__webglTexture ||
      renderTarget.depthTexture.image.width !== renderTarget.width ||
      renderTarget.depthTexture.image.height !== renderTarget.height
    ) {
      renderTarget.depthTexture.image.width = renderTarget.width;
      renderTarget.depthTexture.image.height = renderTarget.height;
      renderTarget.depthTexture.needsUpdate = true;
    }

    setTexture2D(renderTarget.depthTexture, 0);

    const webglDepthTexture = properties.get(
      renderTarget.depthTexture
    ).__webglTexture;

    if (renderTarget.depthTexture.format === DepthFormat) {
      _gl.framebufferTexture2D(36160, 36096, 3553, webglDepthTexture, 0);
    } else if (renderTarget.depthTexture.format === DepthStencilFormat) {
      _gl.framebufferTexture2D(36160, 33306, 3553, webglDepthTexture, 0);
    } else {
      throw new Error("Unknown depthTexture format");
    }
  }

  // Setup GL resources for a non-texture depth buffer
  function setupDepthRenderbuffer(renderTarget) {
    const renderTargetProperties = properties.get(renderTarget);

    if (renderTarget.depthTexture) {
      setupDepthTexture(
        renderTargetProperties.__webglFramebuffer,
        renderTarget
      );
    } else {
      _gl.bindFramebuffer(36160, renderTargetProperties.__webglFramebuffer);
      renderTargetProperties.__webglDepthbuffer = _gl.createRenderbuffer();
      setupRenderBufferStorage(
        renderTargetProperties.__webglDepthbuffer,
        renderTarget,
        false
      );
    }

    _gl.bindFramebuffer(36160, null);
  }

  // Set up GL resources for the render target
  function setupRenderTarget(renderTarget) {
    const renderTargetProperties = properties.get(renderTarget);
    const textureProperties = properties.get(renderTarget.texture);

    renderTarget.addEventListener("dispose", onRenderTargetDispose);

    textureProperties.__webglTexture = _gl.createTexture();

    info.memory.textures++;

    const isMultisample = renderTarget.isWebGLMultisampleRenderTarget === true;
    const supportsMips = true;

    // Handles WebGL2 RGBFormat fallback - #18858

    if (
      renderTarget.texture.format === RGBFormat &&
      (renderTarget.texture.type === FloatType ||
        renderTarget.texture.type === HalfFloatType)
    ) {
      renderTarget.texture.format = RGBAFormat;

      console.warn(
        "THREE.WebGLRenderer: Rendering to textures with RGB format is not supported. Using RGBA format instead."
      );
    }

    // Setup framebuffer

    {
      renderTargetProperties.__webglFramebuffer = _gl.createFramebuffer();

      if (isMultisample) {
        renderTargetProperties.__webglMultisampledFramebuffer =
          _gl.createFramebuffer();
        renderTargetProperties.__webglColorRenderbuffer =
          _gl.createRenderbuffer();

        _gl.bindRenderbuffer(
          36161,
          renderTargetProperties.__webglColorRenderbuffer
        );

        const glFormat = utils.convert(renderTarget.texture.format);
        const glType = utils.convert(renderTarget.texture.type);
        const glInternalFormat = getInternalFormat(
          renderTarget.texture.internalFormat,
          glFormat,
          glType
        );
        const samples = getRenderTargetSamples(renderTarget);
        _gl.renderbufferStorageMultisample(
          36161,
          samples,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );

        _gl.bindFramebuffer(
          36160,
          renderTargetProperties.__webglMultisampledFramebuffer
        );
        _gl.framebufferRenderbuffer(
          36160,
          36064,
          36161,
          renderTargetProperties.__webglColorRenderbuffer
        );
        _gl.bindRenderbuffer(36161, null);

        if (renderTarget.depthBuffer) {
          renderTargetProperties.__webglDepthRenderbuffer =
            _gl.createRenderbuffer();
          setupRenderBufferStorage(
            renderTargetProperties.__webglDepthRenderbuffer,
            renderTarget,
            true
          );
        }

        _gl.bindFramebuffer(36160, null);
      }
    }

    // Setup color buffer

    {
      state.bindTexture(3553, textureProperties.__webglTexture);
      setTextureParameters(3553, renderTarget.texture, supportsMips);
      setupFrameBufferTexture(
        renderTargetProperties.__webglFramebuffer,
        renderTarget,
        36064,
        3553
      );

      if (textureNeedsGenerateMipmaps(renderTarget.texture, supportsMips)) {
        generateMipmap(
          3553,
          renderTarget.texture,
          renderTarget.width,
          renderTarget.height
        );
      }

      state.bindTexture(3553, null);
    }

    // Setup depth and stencil buffers

    if (renderTarget.depthBuffer) {
      setupDepthRenderbuffer(renderTarget);
    }
  }

  function updateRenderTargetMipmap(renderTarget) {
    const texture = renderTarget.texture;
    const supportsMips = true;

    if (textureNeedsGenerateMipmaps(texture, supportsMips)) {
      const target = 3553;
      const webglTexture = properties.get(texture).__webglTexture;

      state.bindTexture(target, webglTexture);
      generateMipmap(target, texture, renderTarget.width, renderTarget.height);
      state.bindTexture(target, null);
    }
  }

  function updateMultisampleRenderTarget(renderTarget) {
    if (renderTarget.isWebGLMultisampleRenderTarget) {
      const renderTargetProperties = properties.get(renderTarget);

      _gl.bindFramebuffer(
        36008,
        renderTargetProperties.__webglMultisampledFramebuffer
      );
      _gl.bindFramebuffer(36009, renderTargetProperties.__webglFramebuffer);

      const width = renderTarget.width;
      const height = renderTarget.height;
      let mask = 16384;

      if (renderTarget.depthBuffer) mask |= 256;
      if (renderTarget.stencilBuffer) mask |= 1024;

      _gl.blitFramebuffer(0, 0, width, height, 0, 0, width, height, mask, 9728);

      _gl.bindFramebuffer(
        36160,
        renderTargetProperties.__webglMultisampledFramebuffer
      ); // see #18905
    }
  }

  function getRenderTargetSamples(renderTarget) {
    return renderTarget.isWebGLMultisampleRenderTarget
      ? Math.min(maxSamples, renderTarget.samples)
      : 0;
  }

  // backwards compatibility

  let warnedTexture2D = false;

  function safeSetTexture2D(texture, slot) {
    if (texture && texture.isWebGLRenderTarget) {
      if (warnedTexture2D === false) {
        console.warn(
          "THREE.WebGLTextures.safeSetTexture2D: don't use render targets as textures. Use their .texture property instead."
        );
        warnedTexture2D = true;
      }

      texture = texture.texture;
    }

    setTexture2D(texture, slot);
  }

  function safeSetTextureCube(texture, slot) {
    setTextureCube(texture, slot);
  }

  //

  this.allocateTextureUnit = allocateTextureUnit;
  this.resetTextureUnits = resetTextureUnits;

  this.setTexture2D = setTexture2D;
  this.setTexture2DArray = setTexture2DArray;
  this.setTexture3D = setTexture3D;
  this.setTextureCube = setTextureCube;
  this.setupRenderTarget = setupRenderTarget;
  this.updateRenderTargetMipmap = updateRenderTargetMipmap;
  this.updateMultisampleRenderTarget = updateMultisampleRenderTarget;

  this.safeSetTexture2D = safeSetTexture2D;
  this.safeSetTextureCube = safeSetTextureCube;
}

function WebGLUtils(extensions) {
  function convert(p) {
    // ARKION OLIVER: WHY IS THERE ANOTHER ONE? paramThreeToGL WASN'T ENOUGH????
    let extension;

    if (p === UnsignedByteType) return 5121;
    if (p === UnsignedShort4444Type) return 32819;
    if (p === UnsignedShort5551Type) return 32820;
    if (p === UnsignedShort565Type) return 33635;

    if (p === ByteType) return 5120;
    if (p === ShortType) return 5122;
    if (p === UnsignedShortType) return 5123;
    if (p === IntType) return 5124;
    if (p === UnsignedIntType) return 5125;
    if (p === FloatType) return 5126;

    if (p === HalfFloatType) {
      return 5131;
    }

    if (p === AlphaFormat) return 6406;
    if (p === RGBFormat) return 6407;
    if (p === RGBAFormat) return 6408;
    if (p === LuminanceFormat) return 6409;
    if (p === LuminanceAlphaFormat) return 6410;
    if (p === DepthFormat) return 6402;
    if (p === DepthStencilFormat) return 34041;
    if (p === RedFormat) return 6403;

    // WebGL2 formats.

    if (p === RedIntegerFormat) return 36244;
    if (p === RGFormat) return 33319;
    if (p === RGIntegerFormat) return 33320;
    if (p === RGBIntegerFormat) return 36248;
    if (p === RGBAIntegerFormat) return 36249;

    if (
      p === RGB_S3TC_DXT1_Format ||
      p === RGBA_S3TC_DXT1_Format ||
      p === RGBA_S3TC_DXT3_Format ||
      p === RGBA_S3TC_DXT5_Format
    ) {
      extension = extensions.get("WEBGL_compressed_texture_s3tc");

      if (extension !== null) {
        if (p === RGB_S3TC_DXT1_Format)
          return extension.COMPRESSED_RGB_S3TC_DXT1_EXT;
        if (p === RGBA_S3TC_DXT1_Format)
          return extension.COMPRESSED_RGBA_S3TC_DXT1_EXT;
        if (p === RGBA_S3TC_DXT3_Format)
          return extension.COMPRESSED_RGBA_S3TC_DXT3_EXT;
        if (p === RGBA_S3TC_DXT5_Format)
          return extension.COMPRESSED_RGBA_S3TC_DXT5_EXT;
      } else {
        return null;
      }
    }

    if (
      p === RGB_PVRTC_4BPPV1_Format ||
      p === RGB_PVRTC_2BPPV1_Format ||
      p === RGBA_PVRTC_4BPPV1_Format ||
      p === RGBA_PVRTC_2BPPV1_Format
    ) {
      extension = extensions.get("WEBGL_compressed_texture_pvrtc");

      if (extension !== null) {
        if (p === RGB_PVRTC_4BPPV1_Format)
          return extension.COMPRESSED_RGB_PVRTC_4BPPV1_IMG;
        if (p === RGB_PVRTC_2BPPV1_Format)
          return extension.COMPRESSED_RGB_PVRTC_2BPPV1_IMG;
        if (p === RGBA_PVRTC_4BPPV1_Format)
          return extension.COMPRESSED_RGBA_PVRTC_4BPPV1_IMG;
        if (p === RGBA_PVRTC_2BPPV1_Format)
          return extension.COMPRESSED_RGBA_PVRTC_2BPPV1_IMG;
      } else {
        return null;
      }
    }

    if (p === RGB_ETC1_Format) {
      extension = extensions.get("WEBGL_compressed_texture_etc1");

      if (extension !== null) {
        return extension.COMPRESSED_RGB_ETC1_WEBGL;
      } else {
        return null;
      }
    }

    if (p === RGB_ETC2_Format || p === RGBA_ETC2_EAC_Format) {
      extension = extensions.get("WEBGL_compressed_texture_etc");

      if (extension !== null) {
        if (p === RGB_ETC2_Format) return extension.COMPRESSED_RGB8_ETC2;
        if (p === RGBA_ETC2_EAC_Format)
          return extension.COMPRESSED_RGBA8_ETC2_EAC;
      }
    }

    if (
      p === RGBA_ASTC_4x4_Format ||
      p === RGBA_ASTC_5x4_Format ||
      p === RGBA_ASTC_5x5_Format ||
      p === RGBA_ASTC_6x5_Format ||
      p === RGBA_ASTC_6x6_Format ||
      p === RGBA_ASTC_8x5_Format ||
      p === RGBA_ASTC_8x6_Format ||
      p === RGBA_ASTC_8x8_Format ||
      p === RGBA_ASTC_10x5_Format ||
      p === RGBA_ASTC_10x6_Format ||
      p === RGBA_ASTC_10x8_Format ||
      p === RGBA_ASTC_10x10_Format ||
      p === RGBA_ASTC_12x10_Format ||
      p === RGBA_ASTC_12x12_Format ||
      p === SRGB8_ALPHA8_ASTC_4x4_Format ||
      p === SRGB8_ALPHA8_ASTC_5x4_Format ||
      p === SRGB8_ALPHA8_ASTC_5x5_Format ||
      p === SRGB8_ALPHA8_ASTC_6x5_Format ||
      p === SRGB8_ALPHA8_ASTC_6x6_Format ||
      p === SRGB8_ALPHA8_ASTC_8x5_Format ||
      p === SRGB8_ALPHA8_ASTC_8x6_Format ||
      p === SRGB8_ALPHA8_ASTC_8x8_Format ||
      p === SRGB8_ALPHA8_ASTC_10x5_Format ||
      p === SRGB8_ALPHA8_ASTC_10x6_Format ||
      p === SRGB8_ALPHA8_ASTC_10x8_Format ||
      p === SRGB8_ALPHA8_ASTC_10x10_Format ||
      p === SRGB8_ALPHA8_ASTC_12x10_Format ||
      p === SRGB8_ALPHA8_ASTC_12x12_Format
    ) {
      extension = extensions.get("WEBGL_compressed_texture_astc");

      if (extension !== null) {
        return p;
      } else {
        return null;
      }
    }

    if (p === RGBA_BPTC_Format) {
      extension = extensions.get("EXT_texture_compression_bptc");

      if (extension !== null) {
        return p;
      } else {
        return null;
      }
    }

    if (p === UnsignedInt248Type) {
      return 34042;
    }
  }

  return { convert: convert };
}

function WebGLMaterials(properties) {
  function refreshMaterialUniforms(uniforms, material, pixelRatio, height) {
    if (material.isMeshBasicMaterial) {
      refreshUniformsCommon(uniforms, material);
    } else if (material.isMeshLambertMaterial) {
      refreshUniformsCommon(uniforms, material);
      refreshUniformsLambert(uniforms, material);
    } else if (material.isMeshStandardMaterial) {
      refreshUniformsCommon(uniforms, material);

      refreshUniformsStandard(uniforms, material);
    } else if (material.isMeshMatcapMaterial) {
      refreshUniformsCommon(uniforms, material);
      refreshUniformsMatcap(uniforms, material);
    } else if (material.isMeshDepthMaterial) {
      refreshUniformsCommon(uniforms, material);
      refreshUniformsDepth(uniforms, material);
    } else if (material.isMeshDistanceMaterial) {
      refreshUniformsCommon(uniforms, material);
      refreshUniformsDistance(uniforms, material);
    } else if (material.isMeshNormalMaterial) {
      refreshUniformsCommon(uniforms, material);
      refreshUniformsNormal(uniforms, material);
    } else if (material.isLineBasicMaterial) {
      refreshUniformsLine(uniforms, material);
    } else if (material.isPointsMaterial) {
      refreshUniformsPoints(uniforms, material, pixelRatio, height);
    } else if (material.isSpriteMaterial) {
      refreshUniformsSprites(uniforms, material);
    } else if (material.isShaderMaterial) {
      material.uniformsNeedUpdate = false; // #15581
    }
  }

  function refreshUniformsCommon(uniforms, material) {
    uniforms.opacity.value = material.opacity;

    if (material.color) {
      uniforms.diffuse.value.copy(material.color);
    }

    if (material.emissive) {
      uniforms.emissive.value
        .copy(material.emissive)
        .multiplyScalar(material.emissiveIntensity);
    }

    if (material.map) {
      uniforms.map.value = material.map;
    }

    if (material.alphaMap) {
      uniforms.alphaMap.value = material.alphaMap;
    }

    if (material.specularMap) {
      uniforms.specularMap.value = material.specularMap;
    }

    const envMap = properties.get(material).envMap;

    if (envMap) {
      uniforms.envMap.value = envMap;

      uniforms.flipEnvMap.value =
        envMap.isCubeTexture && envMap._needsFlipEnvMap ? -1 : 1;

      uniforms.reflectivity.value = material.reflectivity;
      uniforms.refractionRatio.value = material.refractionRatio;

      const maxMipLevel = properties.get(envMap).__maxMipLevel;

      if (maxMipLevel !== undefined) {
        uniforms.maxMipLevel.value = maxMipLevel;
      }
    }

    if (material.lightMap) {
      uniforms.lightMap.value = material.lightMap;
      uniforms.lightMapIntensity.value = material.lightMapIntensity;
    }

    if (material.aoMap) {
      uniforms.aoMap.value = material.aoMap;
      uniforms.aoMapIntensity.value = material.aoMapIntensity;
    }

    // uv repeat and offset setting priorities
    // 1. color map
    // 2. specular map
    // 3. displacementMap map
    // 4. normal map
    // 5. bump map
    // 6. roughnessMap map
    // 7. metalnessMap map
    // 8. alphaMap map
    // 9. emissiveMap map
    // 10. clearcoat map
    // 11. clearcoat normal map
    // 12. clearcoat roughnessMap map

    let uvScaleMap;

    if (material.map) {
      uvScaleMap = material.map;
    } else if (material.specularMap) {
      uvScaleMap = material.specularMap;
    } else if (material.displacementMap) {
      uvScaleMap = material.displacementMap;
    } else if (material.normalMap) {
      uvScaleMap = material.normalMap;
    } else if (material.bumpMap) {
      uvScaleMap = material.bumpMap;
    } else if (material.roughnessMap) {
      uvScaleMap = material.roughnessMap;
    } else if (material.metalnessMap) {
      uvScaleMap = material.metalnessMap;
    } else if (material.alphaMap) {
      uvScaleMap = material.alphaMap;
    } else if (material.emissiveMap) {
      uvScaleMap = material.emissiveMap;
    } else if (material.clearcoatMap) {
      uvScaleMap = material.clearcoatMap;
    } else if (material.clearcoatNormalMap) {
      uvScaleMap = material.clearcoatNormalMap;
    } else if (material.clearcoatRoughnessMap) {
      uvScaleMap = material.clearcoatRoughnessMap;
    }

    if (uvScaleMap !== undefined) {
      // backwards compatibility
      if (uvScaleMap.isWebGLRenderTarget) {
        uvScaleMap = uvScaleMap.texture;
      }

      if (uvScaleMap.matrixAutoUpdate === true) {
        uvScaleMap.updateMatrix();
      }

      uniforms.uvTransform.value.copy(uvScaleMap.matrix);
    }

    // uv repeat and offset setting priorities for uv2
    // 1. ao map
    // 2. light map

    let uv2ScaleMap;

    if (material.aoMap) {
      uv2ScaleMap = material.aoMap;
    } else if (material.lightMap) {
      uv2ScaleMap = material.lightMap;
    }

    if (uv2ScaleMap !== undefined) {
      // backwards compatibility
      if (uv2ScaleMap.isWebGLRenderTarget) {
        uv2ScaleMap = uv2ScaleMap.texture;
      }

      if (uv2ScaleMap.matrixAutoUpdate === true) {
        uv2ScaleMap.updateMatrix();
      }

      uniforms.uv2Transform.value.copy(uv2ScaleMap.matrix);
    }
  }

  function refreshUniformsLine(uniforms, material) {
    uniforms.diffuse.value.copy(material.color);
    uniforms.opacity.value = material.opacity;
  }

  function refreshUniformsPoints(uniforms, material, pixelRatio, height) {
    uniforms.diffuse.value.copy(material.color);
    uniforms.opacity.value = material.opacity;
    uniforms.size.value = material.size * pixelRatio;
    uniforms.scale.value = height * 0.5;

    if (material.map) {
      uniforms.map.value = material.map;
    }

    if (material.alphaMap) {
      uniforms.alphaMap.value = material.alphaMap;
    }

    // uv repeat and offset setting priorities
    // 1. color map
    // 2. alpha map

    let uvScaleMap;

    if (material.map) {
      uvScaleMap = material.map;
    } else if (material.alphaMap) {
      uvScaleMap = material.alphaMap;
    }

    if (uvScaleMap !== undefined) {
      if (uvScaleMap.matrixAutoUpdate === true) {
        uvScaleMap.updateMatrix();
      }

      uniforms.uvTransform.value.copy(uvScaleMap.matrix);
    }
  }

  function refreshUniformsSprites(uniforms, material) {
    uniforms.diffuse.value.copy(material.color);
    uniforms.opacity.value = material.opacity;
    uniforms.rotation.value = material.rotation;

    if (material.map) {
      uniforms.map.value = material.map;
    }

    if (material.alphaMap) {
      uniforms.alphaMap.value = material.alphaMap;
    }

    // uv repeat and offset setting priorities
    // 1. color map
    // 2. alpha map

    let uvScaleMap;

    if (material.map) {
      uvScaleMap = material.map;
    } else if (material.alphaMap) {
      uvScaleMap = material.alphaMap;
    }

    if (uvScaleMap !== undefined) {
      if (uvScaleMap.matrixAutoUpdate === true) {
        uvScaleMap.updateMatrix();
      }

      uniforms.uvTransform.value.copy(uvScaleMap.matrix);
    }
  }

  function refreshUniformsLambert(uniforms, material) {
    if (material.emissiveMap) {
      uniforms.emissiveMap.value = material.emissiveMap;
    }
  }

  function refreshUniformsStandard(uniforms, material) {
    uniforms.roughness.value = material.roughness;
    uniforms.metalness.value = material.metalness;

    if (material.roughnessMap) {
      uniforms.roughnessMap.value = material.roughnessMap;
    }

    if (material.metalnessMap) {
      uniforms.metalnessMap.value = material.metalnessMap;
    }

    if (material.emissiveMap) {
      uniforms.emissiveMap.value = material.emissiveMap;
    }

    if (material.bumpMap) {
      uniforms.bumpMap.value = material.bumpMap;
      uniforms.bumpScale.value = material.bumpScale;
      if (material.side === BackSide) uniforms.bumpScale.value *= -1;
    }

    if (material.normalMap) {
      uniforms.normalMap.value = material.normalMap;
      uniforms.normalScale.value.copy(material.normalScale);
      if (material.side === BackSide) uniforms.normalScale.value.negate();
    }

    if (material.displacementMap) {
      uniforms.displacementMap.value = material.displacementMap;
      uniforms.displacementScale.value = material.displacementScale;
      uniforms.displacementBias.value = material.displacementBias;
    }

    const envMap = properties.get(material).envMap;

    if (envMap) {
      uniforms.envMapIntensity.value = material.envMapIntensity;
    }
  }

  function refreshUniformsMatcap(uniforms, material) {
    if (material.matcap) {
      uniforms.matcap.value = material.matcap;
    }

    if (material.bumpMap) {
      uniforms.bumpMap.value = material.bumpMap;
      uniforms.bumpScale.value = material.bumpScale;
      if (material.side === BackSide) uniforms.bumpScale.value *= -1;
    }

    if (material.normalMap) {
      uniforms.normalMap.value = material.normalMap;
      uniforms.normalScale.value.copy(material.normalScale);
      if (material.side === BackSide) uniforms.normalScale.value.negate();
    }

    if (material.displacementMap) {
      uniforms.displacementMap.value = material.displacementMap;
      uniforms.displacementScale.value = material.displacementScale;
      uniforms.displacementBias.value = material.displacementBias;
    }
  }

  function refreshUniformsDepth(uniforms, material) {
    if (material.displacementMap) {
      uniforms.displacementMap.value = material.displacementMap;
      uniforms.displacementScale.value = material.displacementScale;
      uniforms.displacementBias.value = material.displacementBias;
    }
  }

  function refreshUniformsDistance(uniforms, material) {
    if (material.displacementMap) {
      uniforms.displacementMap.value = material.displacementMap;
      uniforms.displacementScale.value = material.displacementScale;
      uniforms.displacementBias.value = material.displacementBias;
    }

    uniforms.referencePosition.value.copy(material.referencePosition);
    uniforms.nearDistance.value = material.nearDistance;
    uniforms.farDistance.value = material.farDistance;
  }

  function refreshUniformsNormal(uniforms, material) {
    if (material.bumpMap) {
      uniforms.bumpMap.value = material.bumpMap;
      uniforms.bumpScale.value = material.bumpScale;
      if (material.side === BackSide) uniforms.bumpScale.value *= -1;
    }

    if (material.normalMap) {
      uniforms.normalMap.value = material.normalMap;
      uniforms.normalScale.value.copy(material.normalScale);
      if (material.side === BackSide) uniforms.normalScale.value.negate();
    }

    if (material.displacementMap) {
      uniforms.displacementMap.value = material.displacementMap;
      uniforms.displacementScale.value = material.displacementScale;
      uniforms.displacementBias.value = material.displacementBias;
    }
  }

  return {
    refreshMaterialUniforms: refreshMaterialUniforms,
  };
}

function createCanvasElement() {
  const canvas = document.createElementNS(
    "http://www.w3.org/1999/xhtml",
    "canvas"
  );
  canvas.style.display = "block";
  return canvas;
}

const _emptyScene = {
  background: null,
  environment: null,
  isScene: true,
};

export class WebGLRenderer {
  #canvas = null;
  #context = null;
  #alpha = false;
  #depth = true;
  #stencil = true;
  #antialias = false;
  #premultipliedAlpha = true;
  #preserveDrawingBuffer = false;
  #powerPreference = "default";
  #failIfMajorPerformanceCaveat = false;

  #pixelRatio = 1;
  #width = 0;
  #height = 0;
  #isContextLost = false;

  #viewport = new Vector4();
  #currentViewport = new Vector4();

  #scissor = new Vector4();
  #currentScissor = new Vector4();
  #scissorTest = false;
  #currentScissorTest;

  #opaqueSort;
  #transparentSort;

  #background;
  #currentRenderState;
  #currentRenderTarget = null;

  #frustum = new Frustum();
  #projScreenMatrix = new Matrix4();
  #currentRenderList = null;
  #renderStateStack = [];

  #textures;
  #clipping;
  #clippingEnabled = false;

  #currentCamera;
  #currentMaterialId = -1;

  constructor(parameters) {
    parameters = parameters || {};

    this.#canvas = parameters.canvas ?? createCanvasElement();
    this.#context = parameters.context ?? null;
    this.#alpha = parameters.alpha ?? false;
    this.#depth = parameters.depth ?? true;
    this.#stencil = parameters.stencil ?? true;
    this.#antialias = parameters.antialias ?? false;
    this.#premultipliedAlpha = parameters.premultipliedAlpha ?? true;
    this.#preserveDrawingBuffer = parameters.preserveDrawingBuffer ?? false;
    this.#powerPreference = parameters.powerPreference ?? "default";
    this.#failIfMajorPerformanceCaveat =
      parameters.failIfMajorPerformanceCaveat ?? false;

    // render() can be called from within a callback triggered by another render.
    // We track this so that the nested render call gets its state isolated from the parent render call.

    // public properties
    this.domElement = this.#canvas;

    // Debug configuration container
    this.debug = {
      /**
       * Enables error checking and reporting when shader programs are being compiled
       * @type {boolean}
       */
      checkShaderErrors: true,
    };

    // clearing
    this.autoClear = true;
    this.autoClearColor = true;
    this.autoClearDepth = true;
    this.autoClearStencil = true;

    // scene graph
    this.sortObjects = true;

    // user-defined clipping
    this.clippingPlanes = [];
    this.localClippingEnabled = false;

    // physically based shading
    this.gammaFactor = 2.0; // for backwards compatibility
    this.outputEncoding = LinearEncoding;

    // physical lights
    this.physicallyCorrectLights = false;

    // internal state cache
    let _framebuffer = null;

    let _currentActiveCubeFace = 0;
    let _currentActiveMipmapLevel = 0;
    let _currentFramebuffer = null;

    //
    this.#width = this.#canvas.width;
    this.#height = this.#canvas.height;

    this.#viewport = new Vector4(0, 0, this.#width, this.#height);
    this.#scissor = new Vector4(0, 0, this.#width, this.#height);

    // initialize
    let _gl = this.#context;

    function getContext(contextNames, contextAttributes) {
      for (let i = 0; i < contextNames.length; i++) {
        const contextName = contextNames[i];
        const context = this.#canvas.getContext(contextName, contextAttributes);
        if (context !== null) return context;
      }

      return null;
    }

    try {
      const contextAttributes = {
        alpha: this.#alpha,
        depth: this.#depth,
        stencil: this.#stencil,
        antialias: this.#antialias,
        premultipliedAlpha: this.#premultipliedAlpha,
        preserveDrawingBuffer: this.#preserveDrawingBuffer,
        powerPreference: this.#powerPreference,
        failIfMajorPerformanceCaveat: this.#failIfMajorPerformanceCaveat,
      };

      // event listeners must be registered before WebGL context is created, see #12753
      this.#canvas.addEventListener(
        "webglcontextlost",
        this.onContextLost.bind(this),
        false
      );
      this.#canvas.addEventListener(
        "webglcontextrestored",
        this.onContextRestore.bind(this),
        false
      );

      if (_gl === null) {
        const contextNames = ["webgl2", "experimental-webgl"];

        _gl = getContext(contextNames, contextAttributes);

        if (_gl === null) {
          if (getContext(contextNames)) {
            throw new Error(
              "Error creating WebGL context with your selected attributes."
            );
          } else {
            throw new Error("Error creating WebGL context.");
          }
        }
      }

      // Some experimental-webgl implementations do not have getShaderPrecisionFormat
      if (_gl.getShaderPrecisionFormat === undefined) {
        _gl.getShaderPrecisionFormat = function () {
          return { rangeMin: 1, rangeMax: 1, precision: 1 };
        };
      }
    } catch (error) {
      console.error("THREE.WebGLRenderer: " + error.message);
      throw error;
    }

    let extensions, capabilities, state, info;
    let properties;
    let programCache, materials, renderLists, renderStates;

    let utils, bindingStates;

    {
      extensions = new WebGLExtensions(_gl);

      capabilities = new WebGLCapabilities(_gl, extensions, parameters);

      extensions.get("OES_texture_float_linear");

      utils = new WebGLUtils(extensions);

      state = new WebGLState(_gl);
      state.scissor(
        this.#currentScissor
          .copy(this.#scissor)
          .multiplyScalar(this.#pixelRatio)
          .floor()
      );
      state.viewport(
        this.#currentViewport
          .copy(this.#viewport)
          .multiplyScalar(this.#pixelRatio)
          .floor()
      );

      info = new WebGLInfo(_gl);
      properties = new WebGLProperties();
      this.#textures = new WebGLTextures(
        _gl,
        extensions,
        state,
        properties,
        capabilities,
        utils,
        info
      );
      this.attributes = new WebGLAttributes(_gl);
      bindingStates = new WebGLBindingStates(_gl, this.attributes);
      this.geometries = new WebGLGeometries(
        _gl,
        this.attributes,
        info,
        bindingStates
      );
      this.objects = new WebGLObjects(
        _gl,
        this.geometries,
        this.attributes,
        info
      );
      this.#clipping = new WebGLClipping(properties);
      programCache = new WebGLPrograms(
        this,
        capabilities,
        bindingStates,
        this.#clipping
      );
      materials = new WebGLMaterials(properties);
      renderLists = new WebGLRenderLists(properties);
      renderStates = new WebGLRenderStates();
      this.#background = new WebGLBackground(
        this,
        state,
        this.#premultipliedAlpha
      );

      this.bufferRenderer = new WebGLBufferRenderer(_gl, info);
      this.indexedBufferRenderer = new WebGLIndexedBufferRenderer(_gl, info);

      info.programs = programCache.programs;

      this.materials = materials;
      this.programCache = programCache;
      this.bindingStates = bindingStates;
      this.capabilities = capabilities;
      this.extensions = extensions;
      this.properties = properties;
      this.renderLists = renderLists;
      this.renderStates = renderStates;
      this.state = state;
      this.info = info;
    }

    // shadow map
    const shadowMap = new WebGLShadowMap(
      this,
      this.objects,
      capabilities.maxTextureSize
    );

    this.shadowMap = shadowMap;

    // Animation Loop
    let onAnimationFrameCallback = null;

    function onAnimationFrame(time) {
      if (onAnimationFrameCallback) onAnimationFrameCallback(time);
    }

    const animation = new WebGLAnimation();
    animation.setAnimationLoop(onAnimationFrame);

    if (typeof window !== "undefined") animation.setContext(window);

    this.setAnimationLoop = function (callback) {
      onAnimationFrameCallback = callback;

      callback === null ? animation.stop() : animation.start();
    };

    this.animation = animation;

    //
    this.setFramebuffer = function (value) {
      if (_framebuffer !== value && this.#currentRenderTarget === null)
        _gl.bindFramebuffer(36160, value);

      _framebuffer = value;
    };

    this.getActiveCubeFace = function () {
      return _currentActiveCubeFace;
    };

    this.getActiveMipmapLevel = function () {
      return _currentActiveMipmapLevel;
    };

    this.getRenderList = function () {
      return this.#currentRenderList;
    };

    this.setRenderList = function (renderList) {
      this.#currentRenderList = renderList;
    };

    this.getRenderTarget = function () {
      return this.#currentRenderTarget;
    };

    this.setRenderTarget = function (
      renderTarget,
      activeCubeFace = 0,
      activeMipmapLevel = 0
    ) {
      this.#currentRenderTarget = renderTarget;
      _currentActiveCubeFace = activeCubeFace;
      _currentActiveMipmapLevel = activeMipmapLevel;

      if (
        renderTarget &&
        properties.get(renderTarget).__webglFramebuffer === undefined
      ) {
        this.#textures.setupRenderTarget(renderTarget);
      }

      let framebuffer = _framebuffer;
      if (renderTarget) {
        const __webglFramebuffer =
          properties.get(renderTarget).__webglFramebuffer;

        if (renderTarget.isWebGLMultisampleRenderTarget) {
          framebuffer =
            properties.get(renderTarget).__webglMultisampledFramebuffer;
        } else {
          framebuffer = __webglFramebuffer;
        }

        this.#currentViewport.copy(renderTarget.viewport);
        this.#currentScissor.copy(renderTarget.scissor);
        this.#currentScissorTest = renderTarget.scissorTest;
      } else {
        this.#currentViewport
          .copy(this.#viewport)
          .multiplyScalar(this.#pixelRatio)
          .floor();
        this.#currentScissor
          .copy(this.#scissor)
          .multiplyScalar(this.#pixelRatio)
          .floor();
        this.#currentScissorTest = this.#scissorTest;
      }

      if (_currentFramebuffer !== framebuffer) {
        _gl.bindFramebuffer(36160, framebuffer);
        _currentFramebuffer = framebuffer;
      }

      state.viewport(this.#currentViewport);
      state.scissor(this.#currentScissor);
      state.setScissorTest(this.#currentScissorTest);
    };

    this.readRenderTargetPixels = function (
      renderTarget,
      x,
      y,
      width,
      height,
      buffer,
      activeCubeFaceIndex
    ) {
      if (!(renderTarget && renderTarget.isWebGLRenderTarget)) {
        console.error(
          "THREE.WebGLRenderer.readRenderTargetPixels: renderTarget is not THREE.WebGLRenderTarget."
        );
        return;
      }

      let framebuffer = properties.get(renderTarget).__webglFramebuffer;

      if (framebuffer) {
        let restore = false;

        if (framebuffer !== _currentFramebuffer) {
          _gl.bindFramebuffer(36160, framebuffer);

          restore = true;
        }

        try {
          const texture = renderTarget.texture;
          const textureFormat = texture.format;
          const textureType = texture.type;

          if (
            textureFormat !== RGBAFormat &&
            utils.convert(textureFormat) !== _gl.getParameter(35739)
          ) {
            console.error(
              "THREE.WebGLRenderer.readRenderTargetPixels: renderTarget is not in RGBA or implementation defined format."
            );
            return;
          }

          if (
            textureType !== UnsignedByteType &&
            textureType !== FloatType && // Chrome Mac >= 52 and Firefox
            !(
              textureType === HalfFloatType &&
              extensions.get("EXT_color_buffer_float")
            )
          ) {
            console.error(
              "THREE.WebGLRenderer.readRenderTargetPixels: renderTarget is not in UnsignedByteType or implementation defined type."
            );
            return;
          }

          if (_gl.checkFramebufferStatus(36160) === 36053) {
            // the following if statement ensures valid read requests (no out-of-bounds pixels, see #8604)
            if (
              x >= 0 &&
              x <= renderTarget.width - width &&
              y >= 0 &&
              y <= renderTarget.height - height
            ) {
              _gl.readPixels(
                x,
                y,
                width,
                height,
                utils.convert(textureFormat),
                utils.convert(textureType),
                buffer
              );
            }
          } else {
            console.error(
              "THREE.WebGLRenderer.readRenderTargetPixels: readPixels from renderTarget failed. Framebuffer not complete."
            );
          }
        } finally {
          if (restore) {
            _gl.bindFramebuffer(36160, _currentFramebuffer);
          }
        }
      }
    };

    this.copyFramebufferToTexture = function (position, texture, level = 0) {
      const levelScale = Math.pow(2, -level);
      const width = Math.floor(texture.image.width * levelScale);
      const height = Math.floor(texture.image.height * levelScale);
      const glFormat = utils.convert(texture.format);

      this.#textures.setTexture2D(texture, 0);

      _gl.copyTexImage2D(
        3553,
        level,
        glFormat,
        position.x,
        position.y,
        width,
        height,
        0
      );

      state.unbindTexture();
    };

    this.copyTextureToTexture = function (
      position,
      srcTexture,
      dstTexture,
      level = 0
    ) {
      const width = srcTexture.image.width;
      const height = srcTexture.image.height;
      const glFormat = utils.convert(dstTexture.format);
      const glType = utils.convert(dstTexture.type);

      this.#textures.setTexture2D(dstTexture, 0);

      // As another texture upload may have changed pixelStorei
      // parameters, make sure they are correct for the dstTexture
      _gl.pixelStorei(37440, dstTexture.flipY);
      _gl.pixelStorei(37441, dstTexture.premultiplyAlpha);
      _gl.pixelStorei(3317, dstTexture.unpackAlignment);

      if (srcTexture.isDataTexture) {
        _gl.texSubImage2D(
          3553,
          level,
          position.x,
          position.y,
          width,
          height,
          glFormat,
          glType,
          srcTexture.image.data
        );
      } else {
        if (srcTexture.isCompressedTexture) {
          _gl.compressedTexSubImage2D(
            3553,
            level,
            position.x,
            position.y,
            srcTexture.mipmaps[0].width,
            srcTexture.mipmaps[0].height,
            glFormat,
            srcTexture.mipmaps[0].data
          );
        } else {
          _gl.texSubImage2D(
            3553,
            level,
            position.x,
            position.y,
            glFormat,
            glType,
            srcTexture.image
          );
        }
      }

      // Generate mipmaps only when copying level 0
      if (level === 0 && dstTexture.generateMipmaps) _gl.generateMipmap(3553);

      state.unbindTexture();
    };

    this.initTexture = function (texture) {
      this.#textures.setTexture2D(texture, 0);

      state.unbindTexture();
    };

    this.resetState = function () {
      state.reset();
      bindingStates.reset();
    };
  }

  // API
  // Compile
  compile(scene, camera) {
    this.#currentRenderState = this.renderStates.get(scene);
    this.#currentRenderState.init();

    scene.traverseVisible(function (object) {
      if (object.isLight && object.layers.test(camera.layers)) {
        this.#currentRenderState.pushLight(object);
      }
    });

    this.#currentRenderState.setupLights();

    const compiled = new WeakMap();

    scene.traverse(function (object) {
      const material = object.material;

      if (material) {
        if (Array.isArray(material)) {
          for (let i = 0; i < material.length; i++) {
            const childMaterial = material[i];

            if (compiled.has(childMaterial) === false) {
              this.initMaterial(childMaterial, scene, object);
              compiled.set(childMaterial);
            }
          }
        } else if (compiled.has(material) === false) {
          this.initMaterial(material, scene, object);
          compiled.set(material);
        }
      }
    });
  }

  getTargetPixelRatio() {
    return this.#currentRenderTarget === null ? this.#pixelRatio : 1;
  }

  renderBufferDirect(camera, scene, geometry, material, object, group) {
    if (scene === null) scene = _emptyScene;

    const frontFaceCW = object.isMesh && object.matrixWorld.determinant() < 0;

    const program = this.setProgram(camera, scene, material, object);

    this.state.setMaterial(material, frontFaceCW);

    //
    let index = geometry.index;
    const position = geometry.attributes.position;

    //
    if (index === null) {
      if (position === undefined || position.count === 0) return;
    } else if (index.count === 0) {
      return;
    }

    //
    let rangeFactor = 1;

    if (material.wireframe === true) {
      index = this.geometries.getWireframeAttribute(geometry);
      rangeFactor = 2;
    }

    this.bindingStates.setup(object, material, program, geometry, index);

    let attribute;
    let renderer = this.bufferRenderer;

    if (index !== null) {
      attribute = this.attributes.get(index);

      renderer = this.indexedBufferRenderer;
      renderer.setIndex(attribute);
    }

    //
    const dataCount = index !== null ? index.count : position.count;

    const rangeStart = geometry.drawRange.start * rangeFactor;
    const rangeCount = geometry.drawRange.count * rangeFactor;

    const groupStart = group !== null ? group.start * rangeFactor : 0;
    const groupCount = group !== null ? group.count * rangeFactor : Infinity;

    const drawStart = Math.max(rangeStart, groupStart);
    const drawEnd =
      Math.min(dataCount, rangeStart + rangeCount, groupStart + groupCount) - 1;

    const drawCount = Math.max(0, drawEnd - drawStart + 1);

    if (drawCount === 0) return;

    //
    if (object.isMesh) {
      if (material.wireframe === true) {
        renderer.setMode(1);
      } else {
        renderer.setMode(4);
      }
    } else if (object.isLine) {
      if (object.isLineSegments) {
        renderer.setMode(1);
      } else {
        renderer.setMode(3);
      }
    } else if (object.isPoints) {
      renderer.setMode(0);
    } else if (object.isSprite) {
      renderer.setMode(4);
    }

    if (object.isInstancedMesh) {
      renderer.renderInstances(drawStart, drawCount, object.count);
    } else if (geometry.isInstancedBufferGeometry) {
      const instanceCount = Math.min(
        geometry.instanceCount,
        geometry._maxInstanceCount
      );

      renderer.renderInstances(drawStart, drawCount, instanceCount);
    } else {
      renderer.render(drawStart, drawCount);
    }
  }

  initMaterial(material, scene, object) {
    if (scene.isScene !== true) scene = _emptyScene; // scene could be a Mesh, Line, Points, ...

    const materialProperties = this.properties.get(material);

    const lights = this.#currentRenderState.state.lights;
    const shadowsArray = this.#currentRenderState.state.shadowsArray;

    const lightsStateVersion = lights.state.version;

    const parameters = this.programCache.getParameters(
      material,
      lights.state,
      shadowsArray,
      scene,
      object
    );
    const programCacheKey = this.programCache.getProgramCacheKey(parameters);

    let program = materialProperties.program;
    let programChange = true;

    if (program === undefined) {
      // new material
      material.addEventListener("dispose", this.onMaterialDispose.bind(this));
    } else if (program.cacheKey !== programCacheKey) {
      // changed glsl or parameters
      this.releaseMaterialProgramReference(material);
    } else if (materialProperties.lightsStateVersion !== lightsStateVersion) {
      programChange = false;
    } else if (parameters.shaderID !== undefined) {
      return;
    } else {
      // only rebuild uniform list
      programChange = false;
    }

    if (programChange) {
      parameters.uniforms = this.programCache.getUniforms(material);

      material.onBeforeCompile(parameters, this);

      program = this.programCache.acquireProgram(parameters, programCacheKey);

      materialProperties.program = program;
      materialProperties.uniforms = parameters.uniforms;
      materialProperties.outputEncoding = parameters.outputEncoding;
    }

    const uniforms = materialProperties.uniforms;

    if (
      (!material.isShaderMaterial && !material.isRawShaderMaterial) ||
      material.clipping === true
    ) {
      materialProperties.numClippingPlanes = this.#clipping.numPlanes;
      materialProperties.numIntersection = this.#clipping.numIntersection;
      uniforms.clippingPlanes = this.#clipping.uniform;
    }

    materialProperties.environment = material.isMeshStandardMaterial
      ? scene.environment
      : null;

    // store the light setup it was created for
    materialProperties.needsLights = this.materialNeedsLights(material);
    materialProperties.lightsStateVersion = lightsStateVersion;

    if (materialProperties.needsLights) {
      // wire up the material to this renderer's lighting state
      uniforms.ambientLightColor.value = lights.state.ambient;
      uniforms.lightProbe.value = lights.state.probe;
      uniforms.directionalLights.value = lights.state.directional;
      uniforms.directionalLightShadows.value = lights.state.directionalShadow;
      uniforms.spotLights.value = lights.state.spot;
      uniforms.spotLightShadows.value = lights.state.spotShadow;
      uniforms.rectAreaLights.value = lights.state.rectArea;
      uniforms.ltc_1.value = lights.state.rectAreaLTC1;
      uniforms.ltc_2.value = lights.state.rectAreaLTC2;
      uniforms.pointLights.value = lights.state.point;
      uniforms.pointLightShadows.value = lights.state.pointShadow;
      uniforms.hemisphereLights.value = lights.state.hemi;

      uniforms.directionalShadowMap.value = lights.state.directionalShadowMap;
      uniforms.directionalShadowMatrix.value =
        lights.state.directionalShadowMatrix;
      uniforms.spotShadowMap.value = lights.state.spotShadowMap;
      uniforms.spotShadowMatrix.value = lights.state.spotShadowMatrix;
      uniforms.pointShadowMap.value = lights.state.pointShadowMap;
      uniforms.pointShadowMatrix.value = lights.state.pointShadowMatrix;
    }

    const progUniforms = materialProperties.program.getUniforms();
    const uniformsList = WebGLUniforms.seqWithValue(progUniforms.seq, uniforms);

    materialProperties.uniformsList = uniformsList;
  }

  // If uniforms are marked as clean, they don't need to be loaded to the GPU.
  markUniformsLightsNeedsUpdate(uniforms, value) {
    uniforms.ambientLightColor.needsUpdate = value;
    uniforms.lightProbe.needsUpdate = value;

    uniforms.pointLights.needsUpdate = value;
    uniforms.pointLightShadows.needsUpdate = value;
  }

  materialNeedsLights(material) {
    return (
      material.isMeshLambertMaterial ||
      material.isMeshStandardMaterial ||
      (material.isShaderMaterial && material.lights === true)
    );
  }

  setProgram(camera, scene, material, object) {
    if (scene.isScene !== true) scene = _emptyScene; // scene could be a Mesh, Line, Points, ...

    this.#textures.resetTextureUnits();
    const _vector3 = new Vector3();

    const environment = material.isMeshStandardMaterial
      ? scene.environment
      : null;
    const encoding =
      this.#currentRenderTarget === null
        ? this.outputEncoding
        : this.#currentRenderTarget.texture.encoding;

    const materialProperties = this.properties.get(material);
    const lights = this.#currentRenderState.state.lights;

    if (this.#clippingEnabled === true) {
      if (
        this.localClippingEnabled === true ||
        camera !== this.#currentCamera
      ) {
        const useCache =
          camera === this.#currentCamera &&
          material.id === this.#currentMaterialId;

        // we might want to call this function with some ClippingGroup
        // object instead of the material, once it becomes feasible
        // (#8465, #8379)
        this.#clipping.setState(material, camera, useCache);
      }
    }

    if (material.version === materialProperties.__version) {
      if (materialProperties.environment !== environment) {
        this.initMaterial(material, scene, object);
      } else if (
        materialProperties.needsLights &&
        materialProperties.lightsStateVersion !== lights.state.version
      ) {
        this.initMaterial(material, scene, object);
      } else if (
        materialProperties.numClippingPlanes !== undefined &&
        (materialProperties.numClippingPlanes !== this.#clipping.numPlanes ||
          materialProperties.numIntersection !== this.#clipping.numIntersection)
      ) {
        this.initMaterial(material, scene, object);
      } else if (materialProperties.outputEncoding !== encoding) {
        this.initMaterial(material, scene, object);
      }
    } else {
      this.initMaterial(material, scene, object);
      materialProperties.__version = material.version;
    }

    let refreshProgram = false;
    let refreshMaterial = false;
    let refreshLights = false;

    const program = materialProperties.program,
      p_uniforms = program.getUniforms(),
      m_uniforms = materialProperties.uniforms;

    if (this.state.useProgram(program.program)) {
      refreshProgram = true;
      refreshMaterial = true;
      refreshLights = true;
    }

    if (material.id !== this.#currentMaterialId) {
      this.#currentMaterialId = material.id;

      refreshMaterial = true;
    }

    if (refreshProgram || this.#currentCamera !== camera) {
      p_uniforms.setValue(
        this.#context,
        "projectionMatrix",
        camera.projectionMatrix
      );

      if (this.capabilities.logarithmicDepthBuffer) {
        p_uniforms.setValue(
          this.#context,
          "logDepthBufFC",
          2.0 / (Math.log(camera.far + 1.0) / Math.LN2)
        );
      }

      if (this.#currentCamera !== camera) {
        this.#currentCamera = camera;

        // lighting uniforms depend on the camera so enforce an update
        // now, in case this material supports lights - or later, when
        // the next material that does gets activated:
        refreshMaterial = true; // set to true on material change
        refreshLights = true; // remains set until update done
      }

      // load material specific uniforms
      // (shader material also gets them for the sake of genericity)
      if (
        material.isShaderMaterial ||
        material.isMeshStandardMaterial ||
        material.envMap
      ) {
        const uCamPos = p_uniforms.map.cameraPosition;

        if (uCamPos !== undefined) {
          uCamPos.setValue(
            this.#context,
            _vector3.setFromMatrixPosition(camera.matrixWorld)
          );
        }
      }

      if (
        material.isMeshLambertMaterial ||
        material.isMeshBasicMaterial ||
        material.isMeshStandardMaterial ||
        material.isShaderMaterial
      ) {
        p_uniforms.setValue(
          this.#context,
          "isOrthographic",
          camera.isOrthographicCamera === true
        );

        p_uniforms.setValue(
          this.#context,
          "viewMatrix",
          camera.matrixWorldInverse
        );
      }
    }

    if (
      refreshMaterial ||
      materialProperties.receiveShadow !== object.receiveShadow
    ) {
      materialProperties.receiveShadow = object.receiveShadow;
      p_uniforms.setValue(this.#context, "receiveShadow", object.receiveShadow);
    }

    if (refreshMaterial) {
      if (materialProperties.needsLights) {
        // the current material requires lighting info
        // note: all lighting uniforms are always set correctly
        // they simply reference the renderer's state for their
        // values
        //
        // use the current material's .needsUpdate flags to set
        // the GL state when required
        this.markUniformsLightsNeedsUpdate(m_uniforms, refreshLights);
      }

      this.materials.refreshMaterialUniforms(
        m_uniforms,
        material,
        this.#pixelRatio,
        this.#height
      );

      WebGLUniforms.upload(
        this.#context,
        materialProperties.uniformsList,
        m_uniforms,
        this.#textures
      );
    }

    if (material.isShaderMaterial && material.uniformsNeedUpdate === true) {
      WebGLUniforms.upload(
        this.#context,
        materialProperties.uniformsList,
        m_uniforms,
        this.#textures
      );
      material.uniformsNeedUpdate = false;
    }

    if (material.isSpriteMaterial) {
      p_uniforms.setValue(this.#context, "center", object.center);
    }

    // common matrices
    p_uniforms.setValue(
      this.#context,
      "modelViewMatrix",
      object.modelViewMatrix
    );
    p_uniforms.setValue(this.#context, "normalMatrix", object.normalMatrix);
    p_uniforms.setValue(this.#context, "modelMatrix", object.matrixWorld);

    return program;
  }

  projectObject(object, camera, groupOrder, sortObjects) {
    if (object.visible === false) return;

    const visible = object.layers.test(camera.layers);
    const _vector3 = new Vector3();

    if (visible) {
      if (object.isLight) {
        this.#currentRenderState.pushLight(object);
      } else if (object.isSprite) {
        if (!object.frustumCulled || this.#frustum.intersectsSprite(object)) {
          if (sortObjects) {
            _vector3
              .setFromMatrixPosition(object.matrixWorld)
              .applyMatrix4(this.#projScreenMatrix);
          }

          const geometry = this.objects.update(object);
          const material = object.material;

          if (material.visible) {
            this.#currentRenderList.push(
              object,
              geometry,
              material,
              groupOrder,
              _vector3.z,
              null
            );
          }
        }
      } else if (object.isMesh || object.isLine || object.isPoints) {
        if (!object.frustumCulled || this.#frustum.intersectsObject(object)) {
          if (sortObjects) {
            _vector3
              .setFromMatrixPosition(object.matrixWorld)
              .applyMatrix4(this.#projScreenMatrix);
          }

          const geometry = this.objects.update(object);
          const material = object.material;

          if (Array.isArray(material)) {
            const groups = geometry.groups;

            for (let i = 0, l = groups.length; i < l; i++) {
              const group = groups[i];
              const groupMaterial = material[group.materialIndex];

              if (groupMaterial && groupMaterial.visible) {
                this.#currentRenderList.push(
                  object,
                  geometry,
                  groupMaterial,
                  groupOrder,
                  _vector3.z,
                  group
                );
              }
            }
          } else if (material.visible) {
            this.#currentRenderList.push(
              object,
              geometry,
              material,
              groupOrder,
              _vector3.z,
              null
            );
          }
        }
      }
    }

    const children = object.children;

    for (let i = 0, l = children.length; i < l; i++) {
      this.projectObject(children[i], camera, groupOrder, sortObjects);
    }
  }

  render(scene, camera) {
    let renderTarget, forceClear;

    if (camera !== undefined && camera.isCamera !== true) {
      console.error(
        "THREE.WebGLRenderer.render: camera is not an instance of THREE.Camera."
      );
      return;
    }

    if (this.#isContextLost === true) return;

    // reset caching for this frame
    this.bindingStates.resetDefaultState();
    this.#currentMaterialId = -1;
    this.#currentCamera = null;

    // update scene graph
    if (scene.autoUpdate === true) scene.updateMatrixWorld();

    // update camera matrices and frustum
    if (camera.parent === null) camera.updateMatrixWorld();

    //
    if (scene.isScene === true)
      scene.onBeforeRender(
        this,
        scene,
        camera,
        renderTarget || this.#currentRenderTarget
      );

    this.#currentRenderState = this.renderStates.get(
      scene,
      this.#renderStateStack.length
    );
    this.#currentRenderState.init();

    this.#renderStateStack.push(this.#currentRenderState);

    this.#projScreenMatrix.multiplyMatrices(
      camera.projectionMatrix,
      camera.matrixWorldInverse
    );
    this.#frustum.setFromProjectionMatrix(this.#projScreenMatrix);

    this.#clippingEnabled = this.#clipping.init(
      this.clippingPlanes,
      this.localClippingEnabled,
      camera
    );

    this.#currentRenderList = this.renderLists.get(scene, camera);
    this.#currentRenderList.init();

    this.projectObject(scene, camera, 0, this.sortObjects);

    this.#currentRenderList.finish();

    if (this.sortObjects === true) {
      this.#currentRenderList.sort(this.#opaqueSort, this.#transparentSort);
    }

    if (this.#clippingEnabled === true) this.#clipping.beginShadows();

    const shadowsArray = this.#currentRenderState.state.shadowsArray;

    this.shadowMap.render(shadowsArray, scene, camera);

    this.#currentRenderState.setupLights();
    this.#currentRenderState.setupLightsView(camera);

    if (this.#clippingEnabled === true) this.#clipping.endShadows();

    if (this.info.autoReset === true) this.info.reset();

    if (renderTarget !== undefined) {
      this.setRenderTarget(renderTarget);
    }

    this.#background.render(forceClear);

    // render scene
    const opaqueObjects = this.#currentRenderList.opaque;
    const transparentObjects = this.#currentRenderList.transparent;

    if (opaqueObjects.length > 0)
      this.renderObjects(opaqueObjects, scene, camera);
    if (transparentObjects.length > 0)
      this.renderObjects(transparentObjects, scene, camera);

    //
    if (scene.isScene === true) scene.onAfterRender(this, scene, camera);

    //
    if (this.#currentRenderTarget !== null) {
      // Generate mipmap if we're using any kind of mipmap filtering
      this.#textures.updateRenderTargetMipmap(this.#currentRenderTarget);

      // resolve multisample renderbuffers to a single-sample texture if necessary
      this.#textures.updateMultisampleRenderTarget(this.#currentRenderTarget);
    }

    // Ensure depth buffer writing is enabled so it can be cleared on next render
    this.state.buffers.depth.setTest(true);
    this.state.buffers.depth.setMask(true);
    this.state.buffers.color.setMask(true);

    this.state.setPolygonOffset(false);

    this.#renderStateStack.pop();
    if (this.#renderStateStack.length > 0) {
      this.#currentRenderState =
        this.#renderStateStack[this.#renderStateStack.length - 1];
    } else {
      this.#currentRenderState = null;
    }

    this.#currentRenderList = null;
  }

  renderObjects(renderList, scene, camera) {
    for (let i = 0, l = renderList.length; i < l; i++) {
      const renderItem = renderList[i];

      const object = renderItem.object;
      const geometry = renderItem.geometry;
      const material = renderItem.material;
      const group = renderItem.group;

      this.renderObject(object, scene, camera, geometry, material, group);
    }
  }

  renderObject(object, scene, camera, geometry, material, group) {
    object.onBeforeRender(this, scene, camera, geometry, material, group);

    object.modelViewMatrix.multiplyMatrices(
      camera.matrixWorldInverse,
      object.matrixWorld
    );
    object.normalMatrix.getNormalMatrix(object.modelViewMatrix);

    this.renderBufferDirect(camera, scene, geometry, material, object, group);

    object.onAfterRender(this, scene, camera, geometry, material, group);
  }

  getContext() {
    return this.#context;
  }

  getContextAttributes() {
    return this.#context.getContextAttributes();
  }

  forceContextLoss() {
    const extension = this.extensions.get("WEBGL_lose_context");
    if (extension) extension.loseContext();
  }
  forceContextRestore() {
    const extension = this.extensions.get("WEBGL_lose_context");
    if (extension) extension.restoreContext();
  }

  getPixelRatio() {
    return this.#pixelRatio;
  }
  setPixelRatio(value) {
    if (value === undefined) return;

    this.#pixelRatio = value;

    this.setSize(this.#width, this.#height, false);
  }

  getSize(target) {
    return target.set(this.#width, this.#height);
  }
  setSize(width, height, updateStyle) {
    this.#width = width;
    this.#height = height;

    this.#canvas.width = Math.floor(width * this.#pixelRatio);
    this.#canvas.height = Math.floor(height * this.#pixelRatio);

    if (updateStyle !== false) {
      this.#canvas.style.width = width + "px";
      this.#canvas.style.height = height + "px";
    }

    this.setViewport(0, 0, width, height);
  }

  getDrawingBufferSize(target) {
    return target
      .set(this.#width * this.#pixelRatio, this.#height * this.#pixelRatio)
      .floor();
  }

  setDrawingBufferSize(width, height, pixelRatio) {
    this.#width = width;
    this.#height = height;

    this.#pixelRatio = pixelRatio;

    this.#canvas.width = Math.floor(width * pixelRatio);
    this.#canvas.height = Math.floor(height * pixelRatio);

    this.setViewport(0, 0, width, height);
  }

  getCurrentViewport(target) {
    return target.copy(this.#currentViewport);
  }

  getViewport(target) {
    return target.copy(this.#viewport);
  }

  setViewport(x, y, width, height) {
    if (x.isVector4) {
      this.#viewport.set(x.x, x.y, x.z, x.w);
    } else {
      this.#viewport.set(x, y, width, height);
    }

    this.state.viewport(
      this.#currentViewport
        .copy(this.#viewport)
        .multiplyScalar(this.#pixelRatio)
        .floor()
    );
  }

  getScissor(target) {
    return target.copy(this.#scissor);
  }

  setScissor(x, y, width, height) {
    if (x.isVector4) {
      this.#scissor.set(x.x, x.y, x.z, x.w);
    } else {
      this.#scissor.set(x, y, width, height);
    }

    this.state.scissor(
      this.#currentScissor
        .copy(this.#scissor)
        .multiplyScalar(this.#pixelRatio)
        .floor()
    );
  }

  getScissorTest() {
    return this.#scissorTest;
  }

  setScissorTest(boolean) {
    this.state.setScissorTest(this.#scissorTest === boolean);
  }

  setOpaqueSort(method) {
    this.#opaqueSort = method;
  }

  setTransparentSort(method) {
    this.#transparentSort = method;
  }

  // Clearing
  getClearColor(target) {
    return target.copy(this.#background.getClearColor());
  }

  setClearColor() {
    this.#background.setClearColor.apply(this.#background, arguments);
  }

  getClearAlpha() {
    return this.#background.getClearAlpha();
  }

  setClearAlpha() {
    this.#background.setClearAlpha.apply(this.#background, arguments);
  }

  clear(color, depth, stencil) {
    let bits = 0;

    if (color === undefined || color) bits |= 16384;
    if (depth === undefined || depth) bits |= 256;
    if (stencil === undefined || stencil) bits |= 1024;

    this.#context.clear(bits);
  }

  clearColor() {
    this.clear(true, false, false);
  }

  clearDepth() {
    this.clear(false, true, false);
  }

  clearStencil() {
    this.clear(false, false, true);
  }

  //
  dispose() {
    this.#canvas.removeEventListener(
      "webglcontextlost",
      this.onContextLost,
      false
    );
    this.#canvas.removeEventListener(
      "webglcontextrestored",
      this.onContextRestore,
      false
    );

    this.renderLists.dispose();
    this.renderStates.dispose();
    this.properties.dispose();
    this.objects.dispose();
    this.bindingStates.dispose();

    this.animation.stop();
  }

  // Events
  onContextLost(event) {
    event.preventDefault();

    console.log("THREE.WebGLRenderer: Context Lost.");

    this.#isContextLost = true;
  }

  onContextRestore(/* event */) {
    console.log("THREE.WebGLRenderer: Context Restored.");

    this.#isContextLost = false;

    //initGLContext();
  }

  onMaterialDispose(event) {
    const material = event.target;

    material.removeEventListener("dispose", this.onMaterialDispose);

    this.deallocateMaterial(material);
  }

  // Buffer deallocation
  deallocateMaterial(material) {
    this.releaseMaterialProgramReference(material);

    this.properties.remove(material);
  }

  releaseMaterialProgramReference(material) {
    const programInfo = this.properties.get(material).program;

    if (programInfo !== undefined) {
      this.programCache.releaseProgram(programInfo);
    }
  }
}

export function InterleavedBuffer(array, stride) {
  this.array = array;
  this.stride = stride;
  this.count = array !== undefined ? array.length / stride : 0;

  this.usage = StaticDrawUsage;
  this.updateRange = { offset: 0, count: -1 };

  this.version = 0;

  this.uuid = generateUUID();
}

Object.defineProperty(InterleavedBuffer.prototype, "needsUpdate", {
  set: function (value) {
    if (value === true) this.version++;
  },
});

Object.assign(InterleavedBuffer.prototype, {
  isInterleavedBuffer: true,

  onUploadCallback: function () {},

  setUsage: function (value) {
    this.usage = value;

    return this;
  },

  copy: function (source) {
    this.array = new source.array.constructor(source.array);
    this.count = source.count;
    this.stride = source.stride;
    this.usage = source.usage;

    return this;
  },

  copyAt: function (index1, attribute, index2) {
    index1 *= this.stride;
    index2 *= attribute.stride;

    for (let i = 0, l = this.stride; i < l; i++) {
      this.array[index1 + i] = attribute.array[index2 + i];
    }

    return this;
  },

  set: function (value, offset = 0) {
    this.array.set(value, offset);

    return this;
  },

  clone: function (data) {
    if (data.arrayBuffers === undefined) {
      data.arrayBuffers = {};
    }

    if (this.array.buffer._uuid === undefined) {
      this.array.buffer._uuid = generateUUID();
    }

    if (data.arrayBuffers[this.array.buffer._uuid] === undefined) {
      data.arrayBuffers[this.array.buffer._uuid] = this.array.slice(0).buffer;
    }

    const array = new this.array.constructor(
      data.arrayBuffers[this.array.buffer._uuid]
    );

    const ib = new InterleavedBuffer(array, this.stride);
    ib.setUsage(this.usage);

    return ib;
  },

  onUpload: function (callback) {
    this.onUploadCallback = callback;

    return this;
  },
});

const _vector$6 = new Vector3();

export class InterleavedBufferAttribute {
  constructor(interleavedBuffer, itemSize, offset, normalized) {
    this.name = "";
    this.isInterleavedBufferAttribute = true;

    this.data = interleavedBuffer;
    this.itemSize = itemSize;
    this.offset = offset;

    this.normalized = normalized === true;
  }

  get count() {
    return this.data.count;
  }

  get array() {
    return this.data.array;
  }

  set needsUpdate(value) {
    this.data.needsUpdate = value;
  }

  applyMatrix4(m) {
    for (let i = 0, l = this.data.count; i < l; i++) {
      _vector$6.x = this.getX(i);
      _vector$6.y = this.getY(i);
      _vector$6.z = this.getZ(i);

      _vector$6.applyMatrix4(m);

      this.setXYZ(i, _vector$6.x, _vector$6.y, _vector$6.z);
    }

    return this;
  }

  setX(index, x) {
    this.data.array[index * this.data.stride + this.offset] = x;

    return this;
  }

  setY(index, y) {
    this.data.array[index * this.data.stride + this.offset + 1] = y;

    return this;
  }

  setW(index, w) {
    this.data.array[index * this.data.stride + this.offset + 3] = w;

    return this;
  }

  getX(index) {
    return this.data.array[index * this.data.stride + this.offset];
  }

  getY(index) {
    return this.data.array[index * this.data.stride + this.offset + 1];
  }

  getZ(index) {
    return this.data.array[index * this.data.stride + this.offset + 2];
  }

  getW(index) {
    return this.data.array[index * this.data.stride + this.offset + 3];
  }

  setXY(index, x, y) {
    index = index * this.data.stride + this.offset;

    this.data.array[index + 0] = x;
    this.data.array[index + 1] = y;

    return this;
  }

  setXYZ(index, x, y, z) {
    index = index * this.data.stride + this.offset;

    this.data.array[index + 0] = x;
    this.data.array[index + 1] = y;
    this.data.array[index + 2] = z;

    return this;
  }

  setXYZW(index, x, y, z, w) {
    index = index * this.data.stride + this.offset;

    this.data.array[index + 0] = x;
    this.data.array[index + 1] = y;
    this.data.array[index + 2] = z;
    this.data.array[index + 3] = w;

    return this;
  }

  clone(data) {
    if (data === undefined) {
      console.log(
        "THREE.InterleavedBufferAttribute.clone(): Cloning an interlaved buffer attribute will deinterleave buffer data."
      );

      const array = [];

      for (let i = 0; i < this.count; i++) {
        const index = i * this.data.stride + this.offset;

        for (let j = 0; j < this.itemSize; j++) {
          array.push(this.data.array[index + j]);
        }
      }

      return new BufferAttribute(
        new this.array.constructor(array),
        this.itemSize,
        this.normalized
      );
    } else {
      if (data.interleavedBuffers === undefined) {
        data.interleavedBuffers = {};
      }

      if (data.interleavedBuffers[this.data.uuid] === undefined) {
        data.interleavedBuffers[this.data.uuid] = this.data.clone(data);
      }

      return new InterleavedBufferAttribute(
        data.interleavedBuffers[this.data.uuid],
        this.itemSize,
        this.offset,
        this.normalized
      );
    }
  }
}

function InstancedBufferAttribute(
  array,
  itemSize,
  normalized,
  meshPerAttribute
) {
  BufferAttribute.call(this, array, itemSize, normalized);

  this.meshPerAttribute = meshPerAttribute || 1;
}

InstancedBufferAttribute.prototype = Object.assign(
  Object.create(BufferAttribute.prototype),
  {
    constructor: InstancedBufferAttribute,

    isInstancedBufferAttribute: true,

    copy: function (source) {
      BufferAttribute.prototype.copy.call(this, source);

      this.meshPerAttribute = source.meshPerAttribute;

      return this;
    },
  }
);

export function InstancedInterleavedBuffer(array, stride, meshPerAttribute) {
  InterleavedBuffer.call(this, array, stride);

  this.meshPerAttribute = meshPerAttribute || 1;
}

InstancedInterleavedBuffer.prototype = Object.assign(
  Object.create(InterleavedBuffer.prototype),
  {
    constructor: InstancedInterleavedBuffer,

    isInstancedInterleavedBuffer: true,

    copy: function (source) {
      InterleavedBuffer.prototype.copy.call(this, source);

      this.meshPerAttribute = source.meshPerAttribute;

      return this;
    },

    clone: function (data) {
      const ib = InterleavedBuffer.prototype.clone.call(this, data);

      ib.meshPerAttribute = this.meshPerAttribute;

      return ib;
    },
  }
);

export const ShaderFeatures = (function () {
  let ftCanvas = document.createElement("canvas");
  let gl = ftCanvas.getContext("webgl2");
  if (gl === null) {
    return null;
  }

  // -- code taken from THREE.WebGLRenderer --
  let _vertexShaderPrecisionHighpFloat = gl.getShaderPrecisionFormat(
    gl.VERTEX_SHADER,
    gl.HIGH_FLOAT
  );
  let _vertexShaderPrecisionMediumpFloat = gl.getShaderPrecisionFormat(
    gl.VERTEX_SHADER,
    gl.MEDIUM_FLOAT
  );

  let _fragmentShaderPrecisionHighpFloat = gl.getShaderPrecisionFormat(
    gl.FRAGMENT_SHADER,
    gl.HIGH_FLOAT
  );
  let _fragmentShaderPrecisionMediumpFloat = gl.getShaderPrecisionFormat(
    gl.FRAGMENT_SHADER,
    gl.MEDIUM_FLOAT
  );

  let highpAvailable =
    _vertexShaderPrecisionHighpFloat.precision > 0 &&
    _fragmentShaderPrecisionHighpFloat.precision > 0;
  let mediumpAvailable =
    _vertexShaderPrecisionMediumpFloat.precision > 0 &&
    _fragmentShaderPrecisionMediumpFloat.precision > 0;
  // -----------------------------------------

  let precision;
  if (highpAvailable) {
    precision = "highp";
  } else if (mediumpAvailable) {
    precision = "mediump";
  } else {
    precision = "lowp";
  }

  const edl_supported = gl.getParameter(gl.MAX_VARYING_VECTORS) >= 8;

  return {
    SHADER_EDL: edl_supported,
    precision: precision,
  };
})();

export class WebGLTexture {
  constructor(gl, texture) {
    this.gl = gl;

    this.texture = texture;
    this.id = gl.createTexture();

    this.target = gl.TEXTURE_2D;
    this.version = -1;

    this.update();
  }

  update() {
    if (!this.texture.image) {
      this.version = this.texture.version;

      return;
    }

    let gl = this.gl;
    let texture = this.texture;

    if (this.version === texture.version) {
      return;
    }

    this.target = gl.TEXTURE_2D;

    gl.bindTexture(this.target, this.id);

    let level = 0;
    let internalFormat = paramThreeToGL(gl, texture.format);
    let width = texture.image.width;
    let height = texture.image.height;
    let border = 0;
    let srcFormat = internalFormat;
    let srcType = paramThreeToGL(gl, texture.type);
    let data;

    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, texture.flipY);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, texture.premultiplyAlpha);
    gl.pixelStorei(gl.UNPACK_ALIGNMENT, texture.unpackAlignment);

    if (texture instanceof DataTexture) {
      data = texture.image.data;

      gl.texParameteri(this.target, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(this.target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

      gl.texParameteri(
        this.target,
        gl.TEXTURE_MAG_FILTER,
        paramThreeToGL(gl, texture.magFilter)
      );
      gl.texParameteri(
        this.target,
        gl.TEXTURE_MIN_FILTER,
        paramThreeToGL(gl, texture.minFilter)
      );

      gl.texImage2D(
        this.target,
        level,
        internalFormat,
        width,
        height,
        border,
        srcFormat,
        srcType,
        data
      );
    } else if (texture instanceof CanvasTexture || texture instanceof Texture) {
      data = texture.image;

      gl.texParameteri(
        this.target,
        gl.TEXTURE_WRAP_S,
        paramThreeToGL(gl, texture.wrapS)
      );
      gl.texParameteri(
        this.target,
        gl.TEXTURE_WRAP_T,
        paramThreeToGL(gl, texture.wrapT)
      );

      gl.texParameteri(
        this.target,
        gl.TEXTURE_MAG_FILTER,
        paramThreeToGL(gl, texture.magFilter)
      );
      gl.texParameteri(
        this.target,
        gl.TEXTURE_MIN_FILTER,
        paramThreeToGL(gl, texture.minFilter)
      );

      gl.texImage2D(
        this.target,
        level,
        internalFormat,
        internalFormat,
        srcType,
        data
      );

      if (texture instanceof Texture) {
        gl.generateMipmap(gl.TEXTURE_2D);
      }
    }

    gl.bindTexture(this.target, null);

    this.version = texture.version;
  }
}

export class WebGLBuffer {
  constructor() {
    this.numElements = 0;
    this.vao = null;
    this.vbos = new Map();
  }
}

// Copied from three.js: WebGLRenderer.js
function paramThreeToGL(_gl, p) {
  switch (p) {
    // Wrapping
    case RepeatWrapping:
      return _gl.REPEAT;
    case ClampToEdgeWrapping:
      return _gl.CLAMP_TO_EDGE;
    case MirroredRepeatWrapping:
      return _gl.MIRRORED_REPEAT;

    // Nearest Filters
    case NearestFilter:
      return _gl.NEAREST;
    case NearestMipMapNearestFilter:
      return _gl.NEAREST_MIPMAP_NEAREST;
    case NearestMipMapLinearFilter:
      return _gl.NEAREST_MIPMAP_LINEAR;
    // Linear Filters
    case LinearFilter:
      return _gl.LINEAR;
    case LinearMipMapNearestFilter:
      return _gl.LINEAR_MIPMAP_NEAREST;
    case LinearMipMapLinearFilter:
      return _gl.LINEAR_MIPMAP_LINEAR;

    // Unsinged Types
    case UnsignedByteType:
      return _gl.UNSIGNED_BYTE;
    case UnsignedShort4444Type:
      return _gl.UNSIGNED_SHORT_4_4_4_4;
    case UnsignedShort5551Type:
      return _gl.UNSIGNED_SHORT_5_5_5_1;
    case UnsignedShort565Type:
      return _gl.UNSIGNED_SHORT_5_6_5;

    // Types
    case ByteType:
      return _gl.BYTE;
    case ShortType:
      return _gl.SHORT;
    case UnsignedShortType:
      return _gl.UNSIGNED_SHORT;
    case IntType:
      return _gl.INT;
    case UnsignedIntType:
      return _gl.UNSIGNED_INT;
    case FloatType:
      return _gl.FLOAT;

    // Formats
    case AlphaFormat:
      return _gl.ALPHA;
    case RGBFormat:
      return _gl.RGB;
    case RGBAFormat:
      return _gl.RGBA;
    case LuminanceFormat:
      return _gl.LUMINANCE;
    case LuminanceAlphaFormat:
      return _gl.LUMINANCE_ALPHA;
    case DepthFormat:
      return _gl.DEPTH_COMPONENT;
    case DepthStencilFormat:
      return _gl.DEPTH_STENCIL;

    // Equations
    case AddEquation:
      return _gl.FUNC_ADD;
    case SubtractEquation:
      return _gl.FUNC_SUBTRACT;
    case ReverseSubtractEquation:
      return _gl.FUNC_REVERSE_SUBTRACT;

    // Factors
    case ZeroFactor:
      return _gl.ZERO;
    case OneFactor:
      return _gl.ONE;
    case SrcColorFactor:
      return _gl.SRC_COLOR;
    case OneMinusSrcColorFactor:
      return _gl.ONE_MINUS_SRC_COLOR;
    case SrcAlphaFactor:
      return _gl.SRC_ALPHA;
    case OneMinusSrcAlphaFactor:
      return _gl.ONE_MINUS_SRC_ALPHA;
    case DstAlphaFactor:
      return _gl.DST_ALPHA;
    case OneMinusDstAlphaFactor:
      return _gl.ONE_MINUS_DST_ALPHA;

    case DstColorFactor:
      return _gl.DST_COLOR;
    case OneMinusDstColorFactor:
      return _gl.ONE_MINUS_DST_COLOR;
    case SrcAlphaSaturateFactor:
      return _gl.SRC_ALPHA_SATURATE;

    default:
      return 0;
  }
}
