import { Frustum } from "./geometry";
import { Vector2, Matrix4, Vector4, Vector3 } from "./mathtypes";
import { Object3D } from "./object3d";
import { PerspectiveCamera } from "./rendering/camera";
import { Color } from "./rendering/types";


//END ARKION CUSTOM
export class Light extends Object3D {
  isLight = true;

  constructor(color, intensity = 1) {
    super();

    this.type = "Light";

    this.color = new Color(color);
    this.intensity = intensity;
  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    this.color.copy(source.color);
    this.intensity = source.intensity;

    return this;
  }
}
export function LightShadow(camera) {
  this.camera = camera;

  this.bias = 0;
  this.normalBias = 0;
  this.radius = 1;

  this.mapSize = new Vector2(512, 512);

  this.map = null;
  this.mapPass = null;
  this.matrix = new Matrix4();

  this.autoUpdate = true;
  this.needsUpdate = false;

  this._frustum = new Frustum();
  this._frameExtents = new Vector2(1, 1);

  this._viewportCount = 1;

  this._viewports = [new Vector4(0, 0, 1, 1)];
}


Object.assign(LightShadow.prototype, {
  _projScreenMatrix: new Matrix4(),

  _lightPositionWorld: new Vector3(),

  _lookTarget: new Vector3(),

  getViewportCount: function () {
    return this._viewportCount;
  },

  getFrustum: function () {
    return this._frustum;
  },

  updateMatrices: function (light) {
    const shadowCamera = this.camera,
      shadowMatrix = this.matrix,
      projScreenMatrix = this._projScreenMatrix,
      lookTarget = this._lookTarget,
      lightPositionWorld = this._lightPositionWorld;

    lightPositionWorld.setFromMatrixPosition(light.matrixWorld);
    shadowCamera.position.copy(lightPositionWorld);

    lookTarget.setFromMatrixPosition(light.target.matrixWorld);
    shadowCamera.lookAt(lookTarget);
    shadowCamera.updateMatrixWorld();

    projScreenMatrix.multiplyMatrices(
      shadowCamera.projectionMatrix,
      shadowCamera.matrixWorldInverse
    );
    this._frustum.setFromProjectionMatrix(projScreenMatrix);

    shadowMatrix.set(
      0.5,
      0.0,
      0.0,
      0.5,
      0.0,
      0.5,
      0.0,
      0.5,
      0.0,
      0.0,
      0.5,
      0.5,
      0.0,
      0.0,
      0.0,
      1.0
    );

    shadowMatrix.multiply(shadowCamera.projectionMatrix);
    shadowMatrix.multiply(shadowCamera.matrixWorldInverse);
  },

  getViewport: function (viewportIndex) {
    return this._viewports[viewportIndex];
  },

  getFrameExtents: function () {
    return this._frameExtents;
  },

  copy: function (source) {
    this.camera = source.camera.clone();

    this.bias = source.bias;
    this.radius = source.radius;

    this.mapSize.copy(source.mapSize);

    return this;
  },

  clone: function () {
    return new this.constructor().copy(this);
  },
});export function PointLightShadow() {
  LightShadow.call(this, new PerspectiveCamera(90, 1, 0.5, 500));

  this._frameExtents = new Vector2(4, 2);

  this._viewportCount = 6;

  this._viewports = [
    // These viewports map a cube-map onto a 2D texture with the
    // following orientation:
    //
    //  xzXZ
    //   y Y
    //
    // X - Positive x direction
    // x - Negative x direction
    // Y - Positive y direction
    // y - Negative y direction
    // Z - Positive z direction
    // z - Negative z direction
    // positive X
    new Vector4(2, 1, 1, 1),
    // negative X
    new Vector4(0, 1, 1, 1),
    // positive Z
    new Vector4(3, 1, 1, 1),
    // negative Z
    new Vector4(1, 1, 1, 1),
    // positive Y
    new Vector4(3, 0, 1, 1),
    // negative Y
    new Vector4(1, 0, 1, 1),
  ];

  this._cubeDirections = [
    new Vector3(1, 0, 0),
    new Vector3(-1, 0, 0),
    new Vector3(0, 0, 1),
    new Vector3(0, 0, -1),
    new Vector3(0, 1, 0),
    new Vector3(0, -1, 0),
  ];

  this._cubeUps = [
    new Vector3(0, 1, 0),
    new Vector3(0, 1, 0),
    new Vector3(0, 1, 0),
    new Vector3(0, 1, 0),
    new Vector3(0, 0, 1),
    new Vector3(0, 0, -1),
  ];
}
PointLightShadow.prototype = Object.assign(
  Object.create(LightShadow.prototype),
  {
    constructor: PointLightShadow,

    isPointLightShadow: true,

    updateMatrices: function (light, viewportIndex = 0) {
      const camera = this.camera, shadowMatrix = this.matrix, lightPositionWorld = this._lightPositionWorld, lookTarget = this._lookTarget, projScreenMatrix = this._projScreenMatrix;

      lightPositionWorld.setFromMatrixPosition(light.matrixWorld);
      camera.position.copy(lightPositionWorld);

      lookTarget.copy(camera.position);
      lookTarget.add(this._cubeDirections[viewportIndex]);
      camera.up.copy(this._cubeUps[viewportIndex]);
      camera.lookAt(lookTarget);
      camera.updateMatrixWorld();

      shadowMatrix.makeTranslation(
        -lightPositionWorld.x,
        -lightPositionWorld.y,
        -lightPositionWorld.z
      );

      projScreenMatrix.multiplyMatrices(
        camera.projectionMatrix,
        camera.matrixWorldInverse
      );
      this._frustum.setFromProjectionMatrix(projScreenMatrix);
    },
  }
);

export class PointLight extends Light {
  isPointLight = true;
  constructor(color, intensity, distance, decay) {
    super(color, intensity);

    this.type = "PointLight";

    Object.defineProperty(this, "power", {
      get: function () {
        // intensity = power per solid angle.
        // ref: equation (15) from https://seblagarde.files.wordpress.com/2015/07/course_notes_moving_frostbite_to_pbr_v32.pdf
        return this.intensity * 4 * Math.PI;
      },
      set: function (power) {
        // intensity = power per solid angle.
        // ref: equation (15) from https://seblagarde.files.wordpress.com/2015/07/course_notes_moving_frostbite_to_pbr_v32.pdf
        this.intensity = power / (4 * Math.PI);
      },
    });

    this.distance = distance ?? 0;
    this.decay = decay ?? 1; // for physically correct lights, should be 2.

    this.shadow = new PointLightShadow();
  }

  copy(source) {
    Light.prototype.copy.call(this, source);

    this.distance = source.distance;
    this.decay = source.decay;

    this.shadow = source.shadow.clone();

    return this;
  }
}

