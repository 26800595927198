import { EventDispatcher } from "./events";
import { Sprite } from "./geometry";
import { Euler, Matrix3, Matrix4, Quaternion, Ray, Sphere, Vector3, Layers } from "./mathtypes";
import { generateUUID } from "./utils/math";
import { Float32BufferAttribute } from "./rendering/bufferattribute";
import { LineBasicMaterial, SpriteMaterial } from "./rendering/material";
import { LinearFilter } from "./rendering/constants";
import { Texture } from "./rendering/types";
import { BufferGeometry } from "./rendering/buffers";

let _object3DId = 0;

const _v1$2 = new Vector3();
const _q1 = new Quaternion();
const _m1$1 = new Matrix4();
const _target = new Vector3();

const _position = new Vector3();
const _scale = new Vector3();
const _quaternion$2 = new Quaternion();

const _xAxis = new Vector3(1, 0, 0);
const _yAxis = new Vector3(0, 1, 0);
const _zAxis = new Vector3(0, 0, 1);

export class Object3D extends EventDispatcher {
    isObject3D = true;

    constructor() {
      super();
      Object.defineProperty(this, "id", { value: _object3DId++ });

      this.uuid = generateUUID();

      this.name = "";
      this.type = "Object3D";

      this.parent = null;
      this.children = [];

      this.up = Object3D.DefaultUp.clone();

      const position = new Vector3();
      const rotation = new Euler();
      const quaternion = new Quaternion();
      const scale = new Vector3(1, 1, 1);

      function onRotationChange() {
        quaternion.setFromEuler(rotation, false);
      }

      function onQuaternionChange() {
        rotation.setFromQuaternion(quaternion, undefined, false);
      }

      rotation._onChange(onRotationChange);
      quaternion._onChange(onQuaternionChange);

      Object.defineProperties(this, {
        position: {
          configurable: true,
          enumerable: true,
          value: position,
        },
        rotation: {
          configurable: true,
          enumerable: true,
          value: rotation,
        },
        quaternion: {
          configurable: true,
          enumerable: true,
          value: quaternion,
        },
        scale: {
          configurable: true,
          enumerable: true,
          value: scale,
        },
        modelViewMatrix: {
          value: new Matrix4(),
        },
        normalMatrix: {
          value: new Matrix3(),
        },
      });

      this.matrix = new Matrix4();
      this.matrixWorld = new Matrix4();

      this.matrixAutoUpdate = Object3D.DefaultMatrixAutoUpdate;
      this.matrixWorldNeedsUpdate = false;

      this.layers = new Layers();
      this.visible = true;

      this.castShadow = false;
      this.receiveShadow = false;

      this.frustumCulled = true;
      this.renderOrder = 0;

      this.animations = [];

      this.userData = {};
    }

    onBeforeRender() {}
    onAfterRender() {}

    applyMatrix4(matrix) {
      if (this.matrixAutoUpdate) this.updateMatrix();

      this.matrix.premultiply(matrix);

      this.matrix.decompose(this.position, this.quaternion, this.scale);
    }

    applyQuaternion(q) {
      this.quaternion.premultiply(q);

      return this;
    }

    setRotationFromAxisAngle(axis, angle) {
      // assumes axis is normalized

      this.quaternion.setFromAxisAngle(axis, angle);
    }

    setRotationFromEuler(euler) {
      this.quaternion.setFromEuler(euler, true);
    }

    setRotationFromMatrix(m) {
      // assumes the upper 3x3 of m is a pure rotation matrix (i.e, unscaled)

      this.quaternion.setFromRotationMatrix(m);
    }

    setRotationFromQuaternion(q) {
      // assumes q is normalized

      this.quaternion.copy(q);
    }

    rotateOnAxis(axis, angle) {
      // rotate object on axis in object space
      // axis is assumed to be normalized

      _q1.setFromAxisAngle(axis, angle);

      this.quaternion.multiply(_q1);

      return this;
    }

    rotateOnWorldAxis(axis, angle) {
      // rotate object on axis in world space
      // axis is assumed to be normalized
      // method assumes no rotated parent

      _q1.setFromAxisAngle(axis, angle);

      this.quaternion.premultiply(_q1);

      return this;
    }

    rotateX(angle) {
      return this.rotateOnAxis(_xAxis, angle);
    }

    rotateY(angle) {
      return this.rotateOnAxis(_yAxis, angle);
    }

    rotateZ(angle) {
      return this.rotateOnAxis(_zAxis, angle);
    }

    translateOnAxis(axis, distance) {
      // translate object by distance along axis in object space
      // axis is assumed to be normalized

      _v1$2.copy(axis).applyQuaternion(this.quaternion);

      this.position.add(_v1$2.multiplyScalar(distance));

      return this;
    }

    translateX(distance) {
      return this.translateOnAxis(_xAxis, distance);
    }

    translateY(distance) {
      return this.translateOnAxis(_yAxis, distance);
    }

    translateZ(distance) {
      return this.translateOnAxis(_zAxis, distance);
    }

    localToWorld(vector) {
      return vector.applyMatrix4(this.matrixWorld);
    }

    worldToLocal(vector) {
      return vector.applyMatrix4(_m1$1.copy(this.matrixWorld).invert());
    }

    lookAt(x, y, z) {
      // This method does not support objects having non-uniformly-scaled parent(s)

      if (x.isVector3) {
        _target.copy(x);
      } else {
        _target.set(x, y, z);
      }

      const parent = this.parent;

      this.updateWorldMatrix(true, false);

      _position.setFromMatrixPosition(this.matrixWorld);

      if (this.isCamera || this.isLight) {
        _m1$1.lookAt(_position, _target, this.up);
      } else {
        _m1$1.lookAt(_target, _position, this.up);
      }

      this.quaternion.setFromRotationMatrix(_m1$1);

      if (parent) {
        _m1$1.extractRotation(parent.matrixWorld);
        _q1.setFromRotationMatrix(_m1$1);
        this.quaternion.premultiply(_q1.invert());
      }
    }

    add(object) {
      if (arguments.length > 1) {
        for (let i = 0; i < arguments.length; i++) {
          this.add(arguments[i]);
        }

        return this;
      }

      if (object === this) {
        console.error(
          "THREE.Object3D.add: object can't be added as a child of itself.",
          object
        );
        return this;
      }

      if (object && object.isObject3D) {
        if (object.parent !== null) {
          object.parent.remove(object);
        }

        object.parent = this;
        this.children.push(object);
      } else {
        console.error(
          "THREE.Object3D.add: object not an instance of THREE.Object3D.",
          object
        );
      }

      return this;
    }

    remove(object) {
      if (arguments.length > 1) {
        for (let i = 0; i < arguments.length; i++) {
          this.remove(arguments[i]);
        }

        return this;
      }

      const index = this.children.indexOf(object);

      if (index !== -1) {
        object.parent = null;
        this.children.splice(index, 1);
      }

      return this;
    }

    clear() {
      for (let i = 0; i < this.children.length; i++) {
        const object = this.children[i];

        object.parent = null;
      }

      this.children.length = 0;

      return this;
    }

    attach(object) {
      // adds object as a child of this, while maintaining the object's world transform

      this.updateWorldMatrix(true, false);

      _m1$1.copy(this.matrixWorld).invert();

      if (object.parent !== null) {
        object.parent.updateWorldMatrix(true, false);

        _m1$1.multiply(object.parent.matrixWorld);
      }

      object.applyMatrix4(_m1$1);

      object.updateWorldMatrix(false, false);

      this.add(object);

      return this;
    }

    getObjectById(id) {
      return this.getObjectByProperty("id", id);
    }

    getObjectByName(name) {
      return this.getObjectByProperty("name", name);
    }

    getObjectByProperty(name, value) {
      if (this[name] === value) return this;

      for (let i = 0, l = this.children.length; i < l; i++) {
        const child = this.children[i];
        const object = child.getObjectByProperty(name, value);

        if (object !== undefined) {
          return object;
        }
      }

      return undefined;
    }

    getWorldPosition(target) {
      this.updateWorldMatrix(true, false);

      return target.setFromMatrixPosition(this.matrixWorld);
    }

    getWorldQuaternion(target) {
      this.updateWorldMatrix(true, false);

      this.matrixWorld.decompose(_position, target, _scale);

      return target;
    }

    getWorldScale(target) {
      this.updateWorldMatrix(true, false);

      this.matrixWorld.decompose(_position, _quaternion$2, target);

      return target;
    }

    getWorldDirection(target) {
      this.updateWorldMatrix(true, false);

      const e = this.matrixWorld.elements;

      return target.set(e[8], e[9], e[10]).normalize();
    }

    raycast() {}

    traverse(callback) {
      callback(this);

      const children = this.children;

      for (let i = 0, l = children.length; i < l; i++) {
        children[i].traverse(callback);
      }
    }

    traverseVisible(callback) {
      if (this.visible === false) return;

      callback(this);

      const children = this.children;

      for (let i = 0, l = children.length; i < l; i++) {
        children[i].traverseVisible(callback);
      }
    }

    traverseAncestors(callback) {
      const parent = this.parent;

      if (parent !== null) {
        callback(parent);

        parent.traverseAncestors(callback);
      }
    }

    updateMatrix() {
      this.matrix.compose(this.position, this.quaternion, this.scale);

      this.matrixWorldNeedsUpdate = true;
    }

    updateMatrixWorld(force) {
      if (this.matrixAutoUpdate) this.updateMatrix();

      if (this.matrixWorldNeedsUpdate || force) {
        if (this.parent === null) {
          this.matrixWorld.copy(this.matrix);
        } else {
          this.matrixWorld.multiplyMatrices(
            this.parent.matrixWorld,
            this.matrix
          );
        }

        this.matrixWorldNeedsUpdate = false;

        force = true;
      }

      // update children

      const children = this.children;

      for (let i = 0, l = children.length; i < l; i++) {
        children[i].updateMatrixWorld(force);
      }
    }

    updateWorldMatrix(updateParents, updateChildren) {
      const parent = this.parent;

      if (updateParents === true && parent !== null) {
        parent.updateWorldMatrix(true, false);
      }

      if (this.matrixAutoUpdate) this.updateMatrix();

      if (this.parent === null) {
        this.matrixWorld.copy(this.matrix);
      } else {
        this.matrixWorld.multiplyMatrices(
          this.parent.matrixWorld,
          this.matrix
        );
      }

      // update children

      if (updateChildren === true) {
        const children = this.children;

        for (let i = 0, l = children.length; i < l; i++) {
          children[i].updateWorldMatrix(false, true);
        }
      }
    }

    clone(recursive) {
      return new this.constructor().copy(this, recursive);
    }

    copy(source, recursive = true) {
      this.name = source.name;

      this.up.copy(source.up);

      this.position.copy(source.position);
      this.rotation.order = source.rotation.order;
      this.quaternion.copy(source.quaternion);
      this.scale.copy(source.scale);

      this.matrix.copy(source.matrix);
      this.matrixWorld.copy(source.matrixWorld);

      this.matrixAutoUpdate = source.matrixAutoUpdate;
      this.matrixWorldNeedsUpdate = source.matrixWorldNeedsUpdate;

      this.layers.mask = source.layers.mask;
      this.visible = source.visible;

      this.castShadow = source.castShadow;
      this.receiveShadow = source.receiveShadow;

      this.frustumCulled = source.frustumCulled;
      this.renderOrder = source.renderOrder;

      this.userData = source.userData;

      if (recursive === true) {
        for (let i = 0; i < source.children.length; i++) {
          const child = source.children[i];
          this.add(child.clone());
        }
      }

      return this;
    }
  }

  Object3D.DefaultUp = new Vector3(0, 1, 0);
  Object3D.DefaultMatrixAutoUpdate = true;


export class Scene extends Object3D {
    constructor() {
      super();

      Object.defineProperty(this, "isScene", { value: true });

      this.type = "Scene";

      this.background = null;
      this.environment = null;

      this.autoUpdate = true; // checked by the renderer
    }

    copy(source, recursive) {
      super.copy(source, recursive);

      if (source.background !== null)
        this.background = source.background.clone();
      if (source.environment !== null)
        this.environment = source.environment.clone();

      this.autoUpdate = source.autoUpdate;
      this.matrixAutoUpdate = source.matrixAutoUpdate;

      return this;
    }
}


const _start = new Vector3();
const _end = new Vector3();
const _inverseMatrix$1 = new Matrix4();
const _ray$1 = new Ray();
const _sphere$2 = new Sphere();

export class Line extends Object3D {
  constructor(geometry = new BufferGeometry(), material = new LineBasicMaterial()) {
    super();

    this.type = "Line";
    this.isLine = true;

    this.geometry = geometry;
    this.material = material;

  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    this.material = source.material;
    this.geometry = source.geometry;

    return this;
  }

  computeLineDistances() {
    const geometry = this.geometry;

    if (geometry.isBufferGeometry) {
      // we assume non-indexed geometry

      if (geometry.index === null) {
        const positionAttribute = geometry.attributes.position;
        const lineDistances = [0];

        for (let i = 1, l = positionAttribute.count; i < l; i++) {
          _start.fromBufferAttribute(positionAttribute, i - 1);
          _end.fromBufferAttribute(positionAttribute, i);

          lineDistances[i] = lineDistances[i - 1];
          lineDistances[i] += _start.distanceTo(_end);
        }

        geometry.setAttribute(
          "lineDistance",
          new Float32BufferAttribute(lineDistances, 1)
        );
      } else {
        console.warn("THREE.Line.computeLineDistances(): Computation only possible with non-indexed BufferGeometry.");
      }
    } else if (geometry.isGeometry) {
      const vertices = geometry.vertices;
      const lineDistances = geometry.lineDistances;

      lineDistances[0] = 0;

      for (let i = 1, l = vertices.length; i < l; i++) {
        lineDistances[i] = lineDistances[i - 1];
        lineDistances[i] += vertices[i - 1].distanceTo(vertices[i]);
      }
    }

    return this;
  }

  raycast(raycaster, intersects) {
    const geometry = this.geometry;
    const matrixWorld = this.matrixWorld;
    const threshold = raycaster.params.Line.threshold;

    // Checking boundingSphere distance to ray
    if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

    _sphere$2.copy(geometry.boundingSphere);
    _sphere$2.applyMatrix4(matrixWorld);
    _sphere$2.radius += threshold;

    if (raycaster.ray.intersectsSphere(_sphere$2) === false) return;

    _inverseMatrix$1.copy(matrixWorld).invert();
    _ray$1.copy(raycaster.ray).applyMatrix4(_inverseMatrix$1);

    const localThreshold = threshold / ((this.scale.x + this.scale.y + this.scale.z) / 3);
    const localThresholdSq = localThreshold * localThreshold;

    const vStart = new Vector3();
    const vEnd = new Vector3();
    const interSegment = new Vector3();
    const interRay = new Vector3();
    const step = this.isLineSegments ? 2 : 1;

    if (geometry.isBufferGeometry) {
      const index = geometry.index;
      const attributes = geometry.attributes;
      const positionAttribute = attributes.position;

      if (index !== null) {
        const indices = index.array;

        for (let i = 0, l = indices.length - 1; i < l; i += step) {
          const a = indices[i];
          const b = indices[i + 1];

          vStart.fromBufferAttribute(positionAttribute, a);
          vEnd.fromBufferAttribute(positionAttribute, b);

          const distSq = _ray$1.distanceSqToSegment(
            vStart,
            vEnd,
            interRay,
            interSegment
          );

          if (distSq > localThresholdSq) continue;

          interRay.applyMatrix4(this.matrixWorld); //Move back to world space for distance calculation

          const distance = raycaster.ray.origin.distanceTo(interRay);

          if (distance < raycaster.near || distance > raycaster.far) continue;

          intersects.push({
            distance: distance,
            // What do we want? intersection point on the ray or on the segment??
            // point: raycaster.ray.at( distance ),
            point: interSegment.clone().applyMatrix4(this.matrixWorld),
            index: i,
            face: null,
            faceIndex: null,
            object: this,
          });
        }
      } else {
        for (let i = 0, l = positionAttribute.count - 1; i < l; i += step) {
          vStart.fromBufferAttribute(positionAttribute, i);
          vEnd.fromBufferAttribute(positionAttribute, i + 1);

          const distSq = _ray$1.distanceSqToSegment(
            vStart,
            vEnd,
            interRay,
            interSegment
          );

          if (distSq > localThresholdSq) continue;

          interRay.applyMatrix4(this.matrixWorld); //Move back to world space for distance calculation

          const distance = raycaster.ray.origin.distanceTo(interRay);

          if (distance < raycaster.near || distance > raycaster.far) continue;

          intersects.push({
            distance: distance,
            // What do we want? intersection point on the ray or on the segment??
            // point: raycaster.ray.at( distance ),
            point: interSegment.clone().applyMatrix4(this.matrixWorld),
            index: i,
            face: null,
            faceIndex: null,
            object: this,
          });
        }
      }
    } else if (geometry.isGeometry) {
      const vertices = geometry.vertices;
      const nbVertices = vertices.length;

      for (let i = 0; i < nbVertices - 1; i += step) {
        const distSq = _ray$1.distanceSqToSegment(
          vertices[i],
          vertices[i + 1],
          interRay,
          interSegment
        );

        if (distSq > localThresholdSq) continue;

        interRay.applyMatrix4(this.matrixWorld); //Move back to world space for distance calculation

        const distance = raycaster.ray.origin.distanceTo(interRay);

        if (distance < raycaster.near || distance > raycaster.far) continue;

        intersects.push({
          distance: distance,
          // What do we want? intersection point on the ray or on the segment??
          // point: raycaster.ray.at( distance ),
          point: interSegment.clone().applyMatrix4(this.matrixWorld),
          index: i,
          face: null,
          faceIndex: null,
          object: this,
        });
      }
    }
  }
}

const _start$1 = new Vector3();
const _end$1 = new Vector3();

export class LineSegments extends Line {
  constructor(geometry, material) {
    super(geometry, material);

    this.type = "LineSegments";
    this.isLineSegments = true;
  }
  computeLineDistances() {
    const geometry = this.geometry;

    if (geometry.isBufferGeometry) {
      // we assume non-indexed geometry

      if (geometry.index === null) {
        const positionAttribute = geometry.attributes.position;
        const lineDistances = [];

        for (let i = 0, l = positionAttribute.count; i < l; i += 2) {
          _start$1.fromBufferAttribute(positionAttribute, i);
          _end$1.fromBufferAttribute(positionAttribute, i + 1);

          lineDistances[i] = i === 0 ? 0 : lineDistances[i - 1];
          lineDistances[i + 1] =
            lineDistances[i] + _start$1.distanceTo(_end$1);
        }

        geometry.setAttribute(
          "lineDistance",
          new Float32BufferAttribute(lineDistances, 1)
        );
      } else {
        console.warn("THREE.LineSegments.computeLineDistances(): Computation only possible with non-indexed BufferGeometry.");
      }
    } else if (geometry.isGeometry) {
      const vertices = geometry.vertices;
      const lineDistances = geometry.lineDistances;

      for (let i = 0, l = vertices.length; i < l; i += 2) {
        _start$1.copy(vertices[i]);
        _end$1.copy(vertices[i + 1]);

        lineDistances[i] = i === 0 ? 0 : lineDistances[i - 1];
        lineDistances[i + 1] = lineDistances[i] + _start$1.distanceTo(_end$1);
      }
    }

    return this;
  }
}

export class TextSprite extends Object3D {
  constructor(text) {
    super();

    let texture = new Texture();
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    let spriteMaterial = new SpriteMaterial({
      map: texture,
      depthTest: false,
      depthWrite: false,
    });

    this.texture = texture;

    this.material = spriteMaterial;
    this.sprite = new Sprite(this.material);
    this.add(this.sprite);

    this.borderThickness = 4;
    this.fontface = "Arial";
    this.fontsize = 28;
    this.borderColor = { r: 0, g: 0, b: 0, a: 1.0 };
    this.backgroundColor = { r: 255, g: 255, b: 255, a: 1.0 };
    this.textColor = { r: 255, g: 255, b: 255, a: 1.0 };
    this.text = "";

    this.setText(text);
  }

  setText(text) {
    if (this.text !== text) {
      this.text = text;

      this.update();
    }
  }

  setTextColor(color) {
    this.textColor = color;

    this.update();
  }

  setBorderColor(color) {
    this.borderColor = color;

    this.update();
  }

  setBackgroundColor(color) {
    this.backgroundColor = color;

    this.update();
  }

  update() {
    // TODO: OMMT 3: Text Sprites create a new canvas each frame update. It should just be cached.
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "Bold " + this.fontsize + "px " + this.fontface;

    // get size data (height depends only on font size)
    const metrics = context.measureText(this.text);
    const textWidth = metrics.width;
    const margin = 5;
    const spriteWidth = 2 * margin + textWidth + 2 * this.borderThickness;
    const spriteHeight = this.fontsize * 1.4 + 2 * this.borderThickness;

    context.canvas.width = spriteWidth;
    context.canvas.height = spriteHeight;
    context.font = "Bold " + this.fontsize + "px " + this.fontface;

    // background color
    context.fillStyle =
      "rgba(" +
      this.backgroundColor.r +
      "," +
      this.backgroundColor.g +
      "," +
      this.backgroundColor.b +
      "," +
      this.backgroundColor.a +
      ")";
    // border color
    context.strokeStyle =
      "rgba(" +
      this.borderColor.r +
      "," +
      this.borderColor.g +
      "," +
      this.borderColor.b +
      "," +
      this.borderColor.a +
      ")";

    context.lineWidth = this.borderThickness;
    this.roundRect(
      context,
      this.borderThickness / 2,
      this.borderThickness / 2,
      textWidth + this.borderThickness + 2 * margin,
      this.fontsize * 1.4 + this.borderThickness,
      6
    );

    // text color
    context.strokeStyle = "rgba(0, 0, 0, 1.0)";
    context.strokeText(
      this.text,
      this.borderThickness + margin,
      this.fontsize + this.borderThickness
    );

    context.fillStyle =
      "rgba(" +
      this.textColor.r +
      "," +
      this.textColor.g +
      "," +
      this.textColor.b +
      "," +
      this.textColor.a +
      ")";
    context.fillText(
      this.text,
      this.borderThickness + margin,
      this.fontsize + this.borderThickness
    );

    let texture = new Texture(canvas);
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.needsUpdate = true;

    this.sprite.material.map = texture;
    this.texture = texture;

    this.sprite.scale.set(spriteWidth * 0.01, spriteHeight * 0.01, 1.0);
  }

  roundRect(ctx, x, y, w, h, r) {
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.lineTo(x + w - r, y);
    ctx.quadraticCurveTo(x + w, y, x + w, y + r);
    ctx.lineTo(x + w, y + h - r);
    ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
    ctx.lineTo(x + r, y + h);
    ctx.quadraticCurveTo(x, y + h, x, y + h - r);
    ctx.lineTo(x, y + r);
    ctx.quadraticCurveTo(x, y, x + r, y);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }
}
